import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../config/axios";
import axios from "axios";
import { calculateTotal, formatCurrencyIndian, formatRoundoff, getMonthName, handleErrorToast, sortProjects } from "../../CustomFunctions";
import { Modal } from "react-bootstrap";
import { AddwithBlueCircle, AddwithWhiteCircle } from "../../AllSvg";
import { DeleteTransportationInvoice, UpdateTransportationInvoice } from "./ConsultancyInvoiceComponents";
import usePermission from "../../../config/permissions";

// const AddPaymentReceipt = ({ p_id, invoice_id, getTransportationInvoice, month, year }) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => {
//         getTransportation();
//         setShow(true);
//     };

//     const [formData, setFormData] = useState([]);

//     const [transportation, setTransportation] = useState([]);


//     const initialFields = [
//         // { name: "tds_it_percentage", tds_it_percentage: "" },
//         // { name: "tds_it_amount", tds_it_amount: "" },
//         // { name: "tds_gst_percentage", tds_gst_percentage: "" },
//         // { name: "tds_gst_amount", amotds_gst_amountunt: "" },
//         // { name: "withheld_gst_amount", withheld_gst_amount: "" },
//         // { name: "net_payable_amount", net_payable_amount: "" },
//         { name: "Remuneration for Key Professional Staff", remunerationfor_keyproffesional: "" },
//         { name: "Remuneration for Sub Professional Staff", remunerationfor_keyproffesional: "" },
//         { name: "Support Staff", support_staff: "" },
//         { name: "Office Rent", office_rent: "" },
//         { name: "Office Supplies", office_supplies: "" },
//         { name: "Office Furniture and Equipments", office_furniture: "" },
//         { name: "Sub Total (A)", sub_total_A: "" },
//         { name: "Escalation (5% of Subtotal)", escalation_5_percentage_ofsubtotal: "" },
//         { name: "Net Amount", net_amount: "" },
//         { name: "GST @18%", gst_18_percentage: "" },
//         { name: "Gross Bill Amount (B)", gross_bill_amount_B: "" },
//     ];

//     const [fields, setFields] = useState(initialFields);

//     const getTransportation = async () => {
//         try {
//             const res = await axios.get(
//                 `${BASE_URL}/siteproject/boqtransportbyproject/${p_id}`
//             );

//             if (Array.isArray(res.data)) {
//                 setTransportation(res.data);
//                 const initialData = res.data.map((item) => ({
//                     project: p_id,
//                     invoice: invoice_id,
//                     transport: item.id,
//                     actual_month: "",
//                     month: month,
//                     year: year,
//                     current_invoice_amount: ""
//                 }));
//                 setFormData(initialData);
//             } else {
//                 console.error("Error: Expected a list of transportation items");
//             }
//         } catch (err) {
//             console.error("Error fetching transportation data", err);
//         }
//     };


//     // !  ************** Validation start **************  ! //

//     const [errors, setErrors] = useState({});

//     const validateForm = () => {
//         const newErrors = {};

//         formData.forEach((entry, index) => {
//             const requiredFields = ["transport", "actual_month", "current_invoice_amount"];
//             requiredFields.forEach((field) => {
//                 if (!entry[field]) {
//                     newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
//                 } else {
//                     delete newErrors[`${index}_${field}`]; // Remove error if input is present
//                 }
//             });
//         });

//         setErrors(newErrors);

//         return Object.keys(newErrors).length === 0;
//     };

//     // ?  ************** Validation End **************  ! //

//     //! **************************** post API ***********************!//
//     const [loading, setLoading] = useState(false); //loading logic

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (validateForm()) {
//             setLoading(true); //loading logic
//             const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

//             try {
//                 // Submit the formData array as bulk data
//                 const res = await axios.post(
//                     `${BASE_URL}/siteproject/invoicetransportationbulk/`,
//                     formData
//                 );

//                 if (res.status === 201) {
//                     await getTransportationInvoice();
//                     setShow(false);
//                     window.location.reload();
//                 } else {
//                     alert(res);
//                 }
//             } catch (err) {
//                 handleErrorToast(err, loadingToastId);
//             } finally {
//                 setLoading(false); //loading logic
//                 toast.dismiss(loadingToastId);
//             }
//         }
//     };
//     console.log(formData)

//     //! **************************** post API End ***********************!//

//     const handleInputChange = (e, index) => {
//         const { name, value } = e.target;
//         const updatedFormData = formData.map((entry, i) =>
//             i === index ? { ...entry, [name]: value } : entry
//         );
//         setFormData(updatedFormData);
//     };

//     return (
//         <>
//             <button title="Add Transportation" className="model-add models-button" onClick={handleShow}>
//                 <AddwithBlueCircle />
//             </button>

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 dialogClassName="request-leave width-40vw"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Transportation for Invoice</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {/* <div className="parent-div">
//                         <div className="bdy-div">
//                             <form onSubmit={handleSubmit}>
//                                 <div className="flex-row justify-center">
//                                     <table className="table-css width-10vw">
//                                         <thead>
//                                             <tr className="custom-table-head-tr width-10vw">
//                                                 <th className="align-left width-10vw">S no.</th>
//                                                 <th className="align-center width-10vw">Transport</th>
//                                                 <th className="align-center width-10vw">Actual Month</th>
//                                                 <th className="align-center width-10vw">Current Invoice Amount</th>
//                                             </tr>
//                                         </thead>

//                                         <tbody>

//                                             {formData.map((entry, index) => (
//                                                 <tr className="custom-table-head-td ">

//                                                     <td className="align-left width-5vw" >
//                                                         {index + 1}
//                                                     </td>
//                                                     <td>
//                                                         <div title="Transportation" className="flex-column form-group-selectt">
//                                                             <input
//                                                                 id={`transport_${index}`}
//                                                                 name="transport"
//                                                                 value={transportation.find(t => t.id === entry.transport)?.vehicle_description || "Loading..."}
//                                                                 className={`form-input-date width-10vw   ${errors[`${index}_transport`] ? "error" : ""}`}
//                                                                 disabled
//                                                             />
//                                                         </div>
//                                                     </td>
//                                                     <td>
//                                                         <div title="Actual Month" className="flex-column">
//                                                             <input
//                                                                 className={`form-input-date  width-5vw  ${errors[`${index}_actual_month`] ? "error" : ""}`}
//                                                             />
//                                                             <div>
//                                                                 {errors[`${index}_actual_month`] && (
//                                                                     <span className="error-message font-size-text ">{errors[`${index}_actual_month`]}</span>
//                                                                 )}
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                     <td>
//                                                         <div title="Invoice Amount" className="flex-column form-group-selectt">

//                                                             <input
//                                                                 id={`current_invoice_amount_${index}`}
//                                                                 name="current_invoice_amount"
//                                                                 type="number"
//                                                                 value={entry.current_invoice_amount}
//                                                                 placeholder="Current Invoice Amount"
//                                                                 onChange={(e) => handleInputChange(e, index)}
//                                                                 className={`form-input-date width-5vw   ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
//                                                             />
//                                                         </div>
//                                                     </td>

//                                                 </tr>
//                                             ))}

//                                         </tbody>

//                                     </table>
//                                 </div>
//                                 <div className="button-models">
//                                     <button
//                                         className="model-button-cancel model-button font-weight500"
//                                         onClick={handleClose}
//                                     >
//                                         Cancel
//                                     </button>
//                                     <button
//                                         type="submit"
//                                         className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
//                                         disabled={loading}
//                                     >
//                                         Add
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div> */}

//                     <div>

//                         <div className="parent-div">
//                             <div className="bdy-div">
//                                 <div className="flex-row justify-center">

//                                     <table className="table-css width-10vw">
//                                         <thead>
//                                             <tr className="custom-table-head-tr width-10vw">

//                                                 <th className="flex-row width-20vw">
//                                                     <div className="width-10vw">

//                                                         <th className="align-left ">S no.</th>
//                                                     </div>
//                                                     <div className="width-10vw">
//                                                         <th className="align-left ">Particulars</th>
//                                                     </div>
//                                                 </th>
//                                                 <th className="align-center width-10vw">Amount Released</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>

//                                             <tr className="custom-table-head-td ">
//                                                 <div className="flex-row">
//                                                     <div>
//                                                         {fields.map((field, index) => (
//                                                             <td className="flex-row width-200vw" >
//                                                                 <div className="width-10vw">
//                                                                     <td className="align-left " >
//                                                                         {index + 1}
//                                                                     </td>
//                                                                 </div>
//                                                                 <div className="width-10vw">
//                                                                     <td className="align-left">
//                                                                         {field.name}
//                                                                     </td>
//                                                                 </div>
//                                                             </td>

//                                                         ))}
//                                                     </div>
//                                                     <div>
//                                                         <div className="flex-column">

//                                                         <td>
//                                                             <div title="Actual Month" className="flex-column">
//                                                                 hello
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div title="Actual Month" className="flex-column">
//                                                                 hello
//                                                             </div>
//                                                         </td>
//                                                         </div>
//                                                     </div>

//                                                 </div>

//                                             </tr >


//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal.Body>
//             </Modal >

//             <ToastContainer
//                 position="top-center"
//                 autoClose={1000}
//                 hideProgressBar={false}
//                 newestOnTop={true}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//         </>
//     );
// };
const AddPaymentReceipt = ({ p_id, invoice_id, getPayment, month, year, getVehicle, invoice_num }) => {

    const [show, setShow] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const { siteRole } = usePermission();

    const handleShow = () => {
        setShow(true);
        getProjects();
    }

    const initialFormData = {
        remunerationfor_keyproffesional: "",
        remunerationfor_subproffesional: "",
        office_rent: "",
        support_staff: "",
        office_supplies: "",
        office_furniture: "",
        sub_total_A: "",
        escalation_5_percentage_ofsubtotal: "",
        net_ammount: "",
        gst_18_percentage: "18",
        gross_bill_ammount_B: "",
        tds_it_percentage: "",
        tds_it_amount: "",
        tds_gst_percentage: "2",
        amotds_gst_amountunt: "",
        withheld_gst_amount: "",
        net_payable_amount: "",
        tds_deducted_10_percentage: "10",
        gst_tds_2_percentage: "2",
        other_deduction: "",
        total_deduction: "",
        company_name: "",
        date: "",
        consultant_invoice: invoice_id,
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        total_bill_without_escalation: "",
        escalation_percentage: "",
        escalation_amount: "",
        total_bill_amount: "0",
        gst_amount: "",
    };

    // const handleClose = () => {
    //   setShow(false);
    //   setFormData({
    //     project: projectId ? projectId : "",
    //     name: "",
    //     vehicle_num: "",
    //     fixed_km: "",
    //     fixed_bill_amt: "",
    //     extra_amt_above_fix_km: "",

    //     bank_name: "",
    //     account_holder_name: "",
    //     account_num: "",
    //     ifsc_code: "",
    //     pan_card: "",
    //     gst_num: "",
    //     address: "",
    //     mobile: "",
    //   })
    // }

    const handleClose = (event) => {
        if (event) {
            event.preventDefault();
        }
        setShow(false);
        setFormData(initialFormData);
        // setProject(initialProject);

    }


    const getProjects = async () => {
        // setBuffering(true); //buffering logic // Start Buffering
        try {
            // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
            const pro = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/project/`
            );
            const sortedProjects = sortProjects(pro.data, 'site_prcode');
            setProjectList(sortedProjects);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            // setBuffering(false);// End Buffering
        }
    };



    const [formData, setFormData] = useState({
        remunerationfor_keyproffesional: "",
        remunerationfor_subproffesional: "",
        office_rent: "",
        office_supplies: "",
        office_furniture: "",
        support_staff: "",
        sub_total_A: "",
        escalation_5_percentage_ofsubtotal: "",
        net_ammount: "",
        gst_18_percentage: "18",
        gross_bill_ammount_B: "",
        tds_it_percentage: "",
        tds_it_amount: "",
        tds_gst_percentage: "2",
        tds_gst_amount: "",
        withheld_gst_amount: "",
        net_payable_amount: "",
        tds_deducted_10_percentage: "10",
        gst_tds_2_percentage: "2",
        other_deduction: "",
        total_deduction: "",
        company_name: "",
        date: "",
        consultant_invoice: invoice_id,
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        total_bill_without_escalation: "",
        escalation_percentage: "",
        escalation_amount: "",
        total_bill_amount: "0",
        gst_amount: "",

    });


    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [

        ]

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }




    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });



    };
    const calculateTotalBillWithoutEscalation = () => {
        const total =
            (Number(formData.remunerationfor_keyproffesional) || 0) +
            (Number(formData.remunerationfor_subproffesional) || 0) +
            (Number(formData.office_rent) || 0) +
            (Number(formData.support_staff) || 0) +
            (Number(formData.office_supplies) || 0) +
            (Number(formData.office_furniture) || 0);


        const Esclation_Amount = (Number(formData.escalation_percentage) / 100 || 0) * total;
        const Net_Amount = total + Esclation_Amount;
        const GST_Amount = (Number(formData.gst_18_percentage) / 100 || 0) * Net_Amount;
        const Gross_B = GST_Amount + Net_Amount;
        const TDS_Amount = (Number(formData.tds_it_percentage) / 100 || 0) * Net_Amount;
        const TDS_GST_Amount = (Number(formData.gst_tds_2_percentage) / 100 || 0) * TDS_Amount;
        const Total_Deduction = TDS_Amount + TDS_GST_Amount + GST_Amount
        const Net_Payable_Amount = Gross_B - Total_Deduction
        setFormData(prevFormData => ({
            ...prevFormData,
            total_bill_without_escalation: total.toFixed(0),
            escalation_amount: Esclation_Amount.toFixed(0),
            net_ammount: Net_Amount.toFixed(0),
            gst_amount: GST_Amount.toFixed(0),
            withheld_gst_amount: GST_Amount.toFixed(0),
            gross_bill_ammount_B: Gross_B.toFixed(0),
            tds_it_amount: TDS_Amount.toFixed(0),
            tds_gst_amount: TDS_GST_Amount.toFixed(0),
            total_deduction: Total_Deduction.toFixed(0),
            net_payable_amount: Net_Payable_Amount.toFixed(0)

        }));


    };

    // Use useEffect to call calculateTotalBillWithoutEscalation whenever dependencies change
    useEffect(() => {
        calculateTotalBillWithoutEscalation();
    }, [
        formData.remunerationfor_keyproffesional, formData.remunerationfor_subproffesional, formData.office_rent, formData.support_staff, formData.office_supplies, formData.office_furniture, formData.escalation_percentage,
        formData.gst_18_percentage, formData.tds_it_percentage, formData.gst_tds_2_percentage
    ]);

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let ress = await axios.post(
                    `${BASE_URL}/siteproject/paymentrecieved/`,
                    formData
                );

                if (ress.status === 200) {
                    // await getVehicle();
                    handleClose();
                } else {
                    alert(ress);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };


    return (
        <>
            <button title="Add Transportation" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle /> {" "}Payment Receipt
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Add Payment Receipt Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

                    <form className="register-form">
                        <div className="form-flex-wrap">
                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Company Name<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="company_name"
                                    placeholder="Company Name"
                                    value={formData.company_name}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.name ? 'is-invalid' : ''}`}
                                />
                                {errors.company_name && <div className="error-message font-size-text">{errors.company_name}</div>}
                            </div>
                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Address<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.address ? 'is-invalid' : ''}`}
                                />
                                {errors.address && <div className="error-message font-size-text">{errors.address}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Date<span className="required">*</span></label>
                                <input
                                    type="date"
                                    name="date"
                                    placeholder="Date"
                                    // min={0}
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.date ? 'is-invalid' : ''}`}
                                />
                                {errors.date && <div className="error-message font-size-text">{errors.date}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Account Number</label>
                                <input
                                    type="number"
                                    name="account_number"
                                    placeholder="Account Number"
                                    min={0}
                                    value={formData.account_number}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.account_number ? 'is-invalid' : ''}`}
                                />
                                {errors.account_number && <div className="error-message font-size-text">{errors.account_number}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">IFSC Code</label>
                                <input
                                    type="text"
                                    name="ifsc_code"
                                    placeholder="IFSC Code"
                                    min={0}
                                    value={formData.ifsc_code}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.ifsc_code ? 'is-invalid' : ''}`}
                                />
                                {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
                            </div>
                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Bank Name</label>
                                <input
                                    type="text"
                                    name="bank_name"
                                    placeholder="Bank Name"
                                    min={0}
                                    value={formData.bank_name}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.bank_name ? 'is-invalid' : ''}`}
                                />
                                {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
                            </div>

                        </div>
                        <div className="flex-row justify-between">

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">GST Percentage</label>
                                <input
                                    type="number"
                                    name="gst_18_percentage"
                                    placeholder="GST Percentage"
                                    min={0}
                                    value={formData.gst_18_percentage}
                                    onChange={handleInputChange}
                                    className={`form-input width-10vw ${errors.gst_18_percentage ? 'is-invalid' : ''}`}
                                    disabled
                                />
                                {errors.gst_18_percentage && <div className="error-message font-size-text">{errors.gst_18_percentage}</div>}

                            </div>
                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">GST (TDS) Percentage</label>
                                <input
                                    type="text"
                                    name="gst_tds_2_percentage"
                                    placeholder="GST Percentage"
                                    min={0}
                                    value={formData.gst_tds_2_percentage}
                                    onChange={handleInputChange}
                                    className={`form-input width-10vw ${errors.gst_tds_2_percentage ? 'is-invalid' : ''}`}
                                    disabled
                                />
                                {errors.gst_tds_2_percentage && <div className="error-message font-size-text">{errors.gst_tds_2_percentage}</div>}

                            </div><div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">TDS Percentage</label>
                                <input
                                    type="text"
                                    name="tds_it_percentage"
                                    placeholder="TDS Percentage"
                                    min={0}
                                    value={formData.tds_it_percentage}
                                    onChange={handleInputChange}
                                    className={`form-input width-10vw ${errors.tds_it_percentage ? 'is-invalid' : ''}`}
                                // disabled
                                />
                                {errors.tds_it_percentage && <div className="error-message font-size-text">{errors.tds_it_percentage}</div>}

                            </div>
                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Escalation Percentage</label>
                                <input
                                    type="text"
                                    name="escalation_percentage"
                                    placeholder="Escalation Percentage"
                                    min={0}
                                    value={formData.escalation_percentage}
                                    onChange={handleInputChange}
                                    className={`form-input width-10vw ${errors.escalation_percentage ? 'is-invalid' : ''}`}
                                // disabled
                                />
                                {errors.escalation_percentage && <div className="error-message font-size-text">{errors.escalation_percentage}</div>}

                            </div>
                        </div>
                        <div className="flex-row justify-center">
                            <table className="nested-table-lightblue width-50vw">
                                <thead>
                                    <tr>
                                        <th rowSpan="">S.No</th>
                                        <th colSpan="1" className='align-center '>Particulars</th>
                                        <th colSpan="1" className='align-center width-5vw'>Amount</th>
                                        {/* <th colSpan="" className='align-center'>Deductions</th>
                            <th colSpan="" className='align-center'>Total</th> */}

                                    </tr>
                                </thead>
                                <tbody>
                                    <React.Fragment>
                                        <tr>
                                            <td>1</td>
                                            <td colSpan="1" className="text-center">Remuneration for Key Professional Staff</td>
                                            <td style={{
                                                display: "flex", justifyContent: "center"
                                            }}>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="remunerationfor_keyproffesional"
                                                    placeholder="Enter Amount"
                                                    value={formData.remunerationfor_keyproffesional}
                                                    onChange={handleInputChange}
                                                    className={`form-input  width-0vw text-end ${errors.remunerationfor_keyproffesional ? 'is-invalid' : ''}`}

                                                />
                                                {errors.remunerationfor_keyproffesional && <div className="error-message font-size-text">{errors.remunerationfor_keyproffesional}</div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td className="text-center">Remuneration for Sub Professional Staff</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="remunerationfor_subproffesional"
                                                    placeholder="Enter Amount"
                                                    value={formData.remunerationfor_subproffesional}
                                                    onChange={handleInputChange}
                                                    className={`form-input  width-0vw text-end ${errors.remunerationfor_subproffesional ? 'is-invalid' : ''}`}

                                                />
                                                {errors.remunerationfor_subproffesional && <div className="error-message font-size-text">{errors.remunerationfor_subproffesional}</div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td className="text-center">Support Staff</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="support_staff"
                                                    placeholder="Enter Amount"
                                                    value={formData.support_staff}
                                                    onChange={handleInputChange}
                                                    className={`form-input  width-0vw text-end ${errors.support_staff ? 'is-invalid' : ''}`}

                                                />
                                                {errors.support_staff && <div className="error-message font-size-text">{errors.support_staff}</div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td className="text-center">Office Rent</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="office_rent"
                                                    placeholder="Enter Amount"
                                                    value={formData.office_rent}
                                                    onChange={handleInputChange}
                                                    className={`form-input  width-0vw text-end ${errors.office_rent ? 'is-invalid' : ''}`}

                                                />
                                                {errors.office_rent && <div className="error-message font-size-text">{errors.office_rent}</div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td className="text-center">Office Supplies, Utilities and Communications</td>
                                            <td><input
                                                type="number"
                                                min={0}
                                                name="office_supplies"
                                                placeholder="Enter Amount"
                                                value={formData.office_supplies}
                                                onChange={handleInputChange}
                                                className={`form-input  width-0vw text-end ${errors.office_supplies ? 'is-invalid' : ''}`}

                                            />
                                                {errors.office_supplies && <div className="error-message font-size-text">{errors.office_supplies}</div>}</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td className="text-center">Office Furniture and Equipments</td>
                                            <td><input
                                                type="number"
                                                min={0}
                                                name="office_furniture"
                                                placeholder="Enter Amount"
                                                value={formData.office_furniture}
                                                onChange={handleInputChange}
                                                className={`form-input  width-0vw text-end ${errors.office_furniture ? 'is-invalid' : ''}`}

                                            />
                                                {errors.office_furniture && <div className="error-message font-size-text">{errors.office_furniture}</div>}</td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">Sub Total (A)</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="total_bill_without_escalation"
                                                    placeholder="Enter Amount"
                                                    value={formData.total_bill_without_escalation}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw  text-end ${errors.total_bill_without_escalation ? ' is-invalid' : ''}`}
                                                />
                                                {errors.total_bill_without_escalation && (
                                                    <div className="error-message font-size-text">{errors.total_bill_without_escalation}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">Add Esclation {formData.escalation_percentage}% of Subtotal</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="escalation_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.escalation_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.escalation_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.escalation_amount && (
                                                    <div className="error-message font-size-text">{errors.escalation_amount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">Net Amount</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="net_ammount"
                                                    placeholder="Enter Amount"
                                                    value={formData.net_ammount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.net_ammount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.net_ammount && (
                                                    <div className="error-message font-size-text">{errors.net_ammount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">Add: GST @{formData.gst_18_percentage}% </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="gst_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.gst_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.gst_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.gst_amount && (
                                                    <div className="error-message font-size-text">{errors.gst_amount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" text-bold">Gross Bill Amount (B)</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="gross_bill_ammount_B"
                                                    placeholder="Enter Amount"
                                                    value={formData.gross_bill_ammount_B}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end text-bold ${errors.gross_bill_ammount_B ? ' is-invalid' : ''}`}
                                                />
                                                {errors.gross_bill_ammount_B && (
                                                    <div className="error-message font-size-text">{errors.gross_bill_ammount_B}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" text-bold">Deductions:</td>
                                            <td> </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className="">TDS Deducted @ {formData.tds_it_percentage}% of Net Amount</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="tds_it_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.tds_it_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.tds_it_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.tds_it_amount && (
                                                    <div className="error-message font-size-text">{errors.tds_it_amount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">GST (TDS) @ {formData.tds_gst_percentage}% </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="tds_gst_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.tds_gst_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.tds_gst_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.tds_gst_amount && (
                                                    <div className="error-message font-size-text">{errors.tds_gst_amount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">GST Withheld due to Non Submission of Proof </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="withheld_gst_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.withheld_gst_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.withheld_gst_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.withheld_gst_amount && (
                                                    <div className="error-message font-size-text">{errors.withheld_gst_amount}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" ">Total Deduction </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="total_deduction"
                                                    placeholder="Enter Amount"
                                                    value={formData.total_deduction}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end ${errors.total_deduction ? ' is-invalid' : ''}`}
                                                />
                                                {errors.total_deduction && (
                                                    <div className="error-message font-size-text">{errors.total_deduction}</div>
                                                )}
                                            </td>
                                        </tr>
                                        <tr >

                                            <td colspan="2" className=" text-bold">Net Payable Amount</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="net_payable_amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.net_payable_amount}
                                                    readOnly // Make it read-only to prevent manual editing
                                                    className={`form-input width-0vw text-end text-bold ${errors.net_payable_amount ? ' is-invalid' : ''}`}
                                                />
                                                {errors.net_payable_amount && (
                                                    <div className="error-message font-size-text">{errors.net_payable_amount}</div>
                                                )}
                                            </td>
                                        </tr>


                                    </React.Fragment>
                                </tbody>
                            </table>



                            {/* <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Fixed Bill Amount<span className="required">*</span></label>
                                <input
                                    type="number"
                                    min={0}
                                    name="fixed_bill_amt"
                                    placeholder="Fixed Bill Amount"
                                    value={formData.fixed_bill_amt}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.fixed_bill_amt ? 'is-invalid' : ''}`}
                                />
                                {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
                            </div> */}
                            {/* <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Extra Amount Per KM After Fixed KM<span className="required">*</span></label>
                                <input
                                    type="number"
                                    min={0}
                                    name="extra_amt_above_fix_km"
                                    placeholder="Extra Amount Per KM After Fixed KM"
                                    value={formData.extra_amt_above_fix_km}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.extra_amt_above_fix_km ? 'is-invalid' : ''}`}
                                />
                                {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
                            </div>


                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Bank Name<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="bank_name"
                                    placeholder="Bank Name"
                                    value={formData.bank_name}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.bank_name ? 'is-invalid' : ''}`}
                                />
                                {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Account Holder Name <span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="account_holder_name"
                                    placeholder="Account Holder Name"
                                    value={formData.account_holder_name}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.account_holder_name ? 'is-invalid' : ''}`}
                                />
                                {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Account Number<span className="required">*</span></label>
                                <input
                                    type="number"
                                    min={0}
                                    name="account_num"
                                    placeholder="Account Number"
                                    value={formData.account_num}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.account_num ? 'is-invalid' : ''}`}
                                />
                                {errors.account_num && <div className="error-message font-size-text">{errors.account_num}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">Mobile<span className="required">*</span></label>
                                <input
                                    type="number"
                                    name="mobile"
                                    min={0}
                                    placeholder="Mobile"
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.mobile ? 'is-invalid' : ''}`}
                                />
                                {errors.mobile && <div className="error-message font-size-text">{errors.mobile}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">IFSC Code<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="ifsc_code"
                                    placeholder="IFSC Code"
                                    value={formData.ifsc_code}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.ifsc_code ? 'is-invalid' : ''}`}
                                />
                                {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">PAN Card<span className="required">*</span></label>
                                <input
                                    type="text"
                                    name="pan_card"
                                    placeholder="PAN Card"
                                    value={formData.pan_card}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.pan_card ? 'is-invalid' : ''}`}
                                />
                                {errors.pan_card && <div className="error-message font-size-text">{errors.pan_card}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels  font-weight500 font-size-subheading">GST Number</label>
                                <input
                                    type="text"
                                    name="gst_num"
                                    placeholder="GST Number"
                                    value={formData.gst_num}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.gst_num ? 'is-invalid' : ''}`}
                                />
                                {errors.gst_num && <div className="error-message font-size-text">{errors.gst_num}</div>}
                            </div>

                            <div className="flex-column">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Address
                                </label>
                                <textarea
                                    name="address"
                                    placeholder="Address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    rows="50"
                                    cols="50"
                                    className={`form-textarea width-10vw ${errors.address? 'is-invalid' : ''}`}
                                />
                                {errors.address&& <div className="error-message font-size-text">{errors.address}</div>}
                            </div> */}



                        </div>




                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>

                            <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                                {loading ? 'Adding...' : 'Add'}
                            </button>
                        </div>


                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
const PaymentReceipt = ({ p_id, invoice_id, invoice_num, month, year }) => {


    const [paymentData, setPaymentData] = useState();

    const getPayment = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/paymentrecievedfilter/${p_id}/${month}/${year}/${invoice_num}/`);
            setPaymentData(res.data[0]);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h2>Payment Receipt <br /></h2>
                    <div className="hide-on-print">
                        <AddPaymentReceipt p_id={p_id} invoice_num={invoice_num} invoice_id={invoice_id} getPayment={getPayment} month={month} year={year} />
                    </div>
                </div>
                <div className="flex-row justify-center">
                    <table className="nested-table-lightblue width-50vw">
                        <thead>
                            <tr>
                                <th rowSpan="" style={{ width: "10vw" }}>S.No</th>
                                <th colSpan="" className='align-center  width-20vw'>Particulars</th>
                                <th colSpan="" className='align-center  width-10vw'>Amount</th>
                                {/* <th colSpan="" className='align-center'>Deductions</th>
                            <th colSpan="" className='align-center'>Total</th> */}

                            </tr>


                        </thead>
                        <tbody>
                            <React.Fragment>
                                <tr>
                                    <td>1</td>
                                    <td className="text-start">Remuneration for Key Professional Staff</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.remunerationfor_keyproffesional)}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td className="text-start">Remuneration for Sub Professional Staff</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.remunerationfor_subproffesional)}</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td className="text-start">Support Staff</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.support_staff)}</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td className="text-start">Office Furniture and Equipments</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.office_furniture)}</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td className="text-start">Office Rent</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.office_rent)}</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td className="text-start">Office Supplies, Utilities and Communications</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.office_supplies)}</td>
                                </tr>
                                {/* <tr>
                                    <td>7</td>
                                    <td className="text-start">Office Furniture and Equipments</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.remunerationfor_subproffesional)}</td>
                                </tr> */}

                                <tr>
                                    <td colSpan="2" className="text-start">Sub Total(A)</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.total_bill_without_escalation)}</td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="text-start">Add: Escalation ({Number(paymentData?.escalation_percentage)?.toFixed(0)}% of Subtotal)</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.escalation_amount)}</td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="text-start text-bold">Net Amount</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.net_ammount)}</td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="text-start">Add GST @{paymentData?.gst_18_percentage}%</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.gst_amount)}</td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="text-start text-bold">Gross Bill Amount (B)</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.gross_bill_ammount_B)}</td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="text-start text-bold ">Deductions:</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="text-start">TDS deducted @10%</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.tds_it_amount)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="text-start">GST (TDS) @2%</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.tds_gst_amount)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="text-start">GST Withheld due to Non Submission of Proof</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.withheld_gst_amount)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="text-start">Total Deductions</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.total_deduction)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="text-start text-bold">Net Payable Amount</td>
                                    <td className="text-end">{formatCurrencyIndian(paymentData?.net_payable_amount)}</td>
                                </tr>
                            </React.Fragment>
                        </tbody>

                    </table>
                </div>

            </div>

        </div>
    );
};





export { AddPaymentReceipt, PaymentReceipt }