import React, { useEffect, useState, useRef } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { toast, ToastContainer } from "react-toastify";


import { formatDate, formattedDateLong } from "../Date";
import { AddProjectDetails } from "./ProjectDetailsCrudButtons";

import { useNavigate } from "react-router-dom";
import { ProjectProgressBar } from "./SiteProjectManagementComponents";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, formatRoundoff, formatRoundoffCrores, handleErrorToast, sortProjects } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, Eye, Search, SearchSmall, Sort } from "../AllSvg";
import { Modal } from "react-bootstrap";
import { SubCompany } from "../FilterDropdown";



const DeleteProject = ({ i, fetchContractorProjects }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/project/${i.id}/`);

      if (res.status === 200) {
        await fetchContractorProjects();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Project" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Project {i.project_short_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Project {i.project_short_name}
            <div className="button-models">
              <button className="model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button font-weight500  model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};



const SiteProjectTable = () => {
  const { siteRole } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [selectedCC, setSelectedCC] = useState('contractor');
  const [sorting, setSorting] = useState("asc_pr");



  // Event handlers
  const handleSortChange = (e) => setSorting(e.target.value);
  const handleCCChange = (e) => setSelectedCC(e.target.value);
  const handleNameChange = (e) => setSelectedName(e.target.value);


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboardnavbar name="All Projects" url="Projects" />

      <div className="content-tabs">
        <div>
          <div className="attendance-subcont">
            <div className="field-cont">
              <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />

              <div title="Sort Filter" className="field-cont-div">
                <Sort />
                <select
                  className="attendance-input-field width-15vw   date-field"
                  type="text"
                  value={sorting}
                  onChange={handleSortChange}
                >
                  <option value="asc_pr">Asc. Pr Code</option>
                  <option value="desc_pr">Desc. Pr Code</option>
                  <option value="asc_short_name">Asc. Project Name</option>
                  <option value="desc_short_name">Desc. Project Name</option>
                </select>
                <hr className="field-cont-hr" />
              </div>


              <div title="CC Filter" className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-10vw date-field"
                  type="text"
                  value={selectedCC}
                  onChange={handleCCChange}
                >
                  <option value="contractor">Contractor</option>
                  <option value="consultant">Consultant</option>
                </select>
                <hr className="field-cont-hr" />
              </div>

              <div title="Search Filter" className="field-cont-div">
                <SearchSmall />
                <input
                  className="attendance-input-field width-15vw"
                  placeholder=" Code / Project Name"
                  type="text"
                  value={selectedName}
                  onChange={handleNameChange}
                />
              </div>
            </div>
            {siteRole === "Hr" || siteRole === "Account" ? "" :
              <div className="btn-cont">
                <AddProjectDetails />
              </div>
            }
          </div>
        </div>

        {/* Conditionally render the views based on selectedCC */}
        {selectedCC === 'contractor' ? (
          <ProjectContractorView
            subCompany={toggleCompany}
            searchName={selectedName}
            searchSort={sorting}
          />
        ) : (
          <ProjectConsultantView
            subCompany={toggleCompany}
            searchName={selectedName}
            searchSort={sorting}
          />
        )}
      </div>
    </>
  );
};




const ProjectContractorView = ({ subCompany, searchName, searchSort }) => {



  const [projectsList, setProjectsList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const navigate = useNavigate();
  const { siteRole } = usePermission();
  // Function to fetch projects for contractors
  const fetchContractorProjects = async () => {
    setBuffering(true);
    try {
      // const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/contractor/`);
      const response = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' || sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
          `${BASE_URL}/siteproject/projectdashboard/contractor/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : `${BASE_URL}/siteproject/projectdashboard/contractor/`
      );

      let filteredProjects = response.data;

      // Apply sub-company filter
      if (subCompany && subCompany !== 'null') {
        filteredProjects = filteredProjects.filter(
          (project) => project.sub_company === subCompany
        );
      }


      if (searchName) {
        const lowerCaseName = searchName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (searchSort) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      setProjectsList(filteredProjects);
    } catch (error) {
      console.error('Error fetching contractor projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchContractorProjects();
  }, [subCompany, searchName, searchSort]); // Refetch projects when filters change

  const tableRef = useRef(null);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Contractor";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };











  return (
    <>


      <div className=" table-css-white-background height-73vh table-box" style={{ paddingTop: "0px" }}>
        <table ref={tableRef} className="font-size-label table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-th">
              <th className="table-heading-text ">S.no.</th>
              <th className="table-heading-text " style={{ width: "100px" }}>Project</th>
              <th className="table-heading-text" style={{ width: "0px" }}>Progress(%)</th>
              <th className="table-heading-text text-center">Project Details</th>
              {siteRole === "Hr" ? "" :
                <th className="table-heading-text text-center">Overall Billing Summary</th>
              }
              <th className="table-heading-text text-center">Change of Scope</th>
              <th className="table-heading-text text-center">Extension of Time</th>
              <th className="table-heading-text  ">View Details</th>
              {siteRole === "Hr" || siteRole === "BD" || siteRole === "Account" ? "" :
                <th className="table-heading-text ">Delete</th>
              }

              {/* <th className="table-heading-text width-5vw ">BOQ</th> */}

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {projectsList?.sort((a, b) => {
              if (a.year === b.year) { return b.month - a.month; }
              return b.year - a.year;
            }).map((project, index) => {
              const progressData = [

                {
                  month: project?.latest_progress?.month,
                  Physical: project?.latest_progress?.physical_progress,
                  Financial: project?.latest_progress?.financial_progress,
                },

              ];
              return (
                <React.Fragment key={index}>
                  <tr key={project.id} className="custom-table-head-td4">
                    <td className="table-body">{index + 1}</td>
                    <td className="table-body" title={`Project Name: \n${project.project_name}`} >
                      {project.site_prcode} : <br /><div style={{ width: "120px", whiteSpace: "normal" }}>{project.project_short_name}</div>
                    </td>
                    <td className="table-body">
                      {project.latest_progress ?
                        <div className="">
                          <ProjectProgressBar data={progressData} />
                          {/* <ProjectProgressBar data={project.progress[0]} /> */}
                        </div>
                        :
                        <div className="text-center">
                          <p>-</p>
                        </div>
                      }
                    </td>

                    <td className="table-body">
                      {project.loa_award_date || project.agreement_date || project.schedule_construction_completion_date || project?.construction_cost ? (
                        <div className="employee-card-image">
                          <table style={{ borderCollapse: "collapse", width: "100%" }}>

                            <tbody>
                              <tr>

                                <td className="padding-bottom-5px">Contractor Award</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="align-right">
                                  {project?.loa_award_date
                                    ? formatDate(project?.loa_award_date)
                                    : "-"}
                                </td>
                              </tr>
                              <tr>

                                <td className="padding-bottom-5px">Agreement Date</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="align-right">
                                  {project?.agreement_date
                                    ? formatDate(project?.agreement_date)
                                    : "-"}
                                </td>
                              </tr>
                              <tr>

                                <td className="padding-bottom-5px">Sch. Const. Completion Date</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="align-right">
                                  {project?.schedule_construction_completion_date
                                    ? formatDate(
                                      project?.schedule_construction_completion_date
                                    )
                                    : "-"}
                                </td>
                              </tr>

                              <tr>

                                <td className="padding-bottom-5px">Construction Cost (CR)</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="align-right">
                                  {project?.construction_cost
                                    ? `₹ ${formatRoundoffCrores(
                                      project?.construction_cost
                                    )}`
                                    : "-"}
                                </td>
                              </tr>
                              <tr>

                                <td className="padding-bottom-5px">Contract Mode</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="align-right" style={{ textTransform: "uppercase" }}>
                                  {project?.contract_mode
                                    ? project?.contract_mode
                                    : "-"}
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <p>-</p>
                        </div>
                      )}
                    </td>
                    {siteRole === "Hr" ? "" :

                      <td className="table-body">
                        {project?.billing_details.bill_recommended_total || project?.billing_details.bill_submitted_total
                          || project?.billing_details.bill_ld_total
                          || project?.billing_details.bill_withheld_total
                          || project?.billing_details.balancebills_submitted_total
                          || project?.billing_details.balancebills_recommended_total
                          || project?.billing_details.balancebills_ld_total
                          || project?.billing_details.balancebills_withheld_total
                          || project?.billing_details.balancebills_released_withheld_total
                          ? (
                            <div className="employee-card-image">

                              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                <tbody>
                                  <tr>


                                    <td className="padding-bottom-5px">Bill Submitted</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.bill_submitted_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.bill_submitted_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>

                                    <td className="padding-bottom-5px">Bill Recommended</td>
                                    <td
                                      style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.bill_recommended_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.bill_recommended_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Bill LD</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.bill_ld_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.bill_ld_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Bill Withheld</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.bill_withheld_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.bill_withheld_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Balance Bills Submitted</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.balancebills_submitted_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.balancebills_submitted_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Balance Bills Recommended</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.balancebills_recommended_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.balancebills_recommended_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Balance Bills LD</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.balancebills_ld_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.balancebills_ld_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Balance Bills Withheld</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.balancebills_withheld_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.balancebills_withheld_total))}`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>


                                    <td className="padding-bottom-5px">Balance Bills Released</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {project?.billing_details.balancebills_released_withheld_total
                                        ? `₹ ${formatRoundoffCrores(Number(project.billing_details.balancebills_released_withheld_total))}`
                                        : "-"}
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="text-center">
                              <p>-</p>
                            </div>
                          )}
                      </td>
                    }




                    <td className="table-body">
                      {project.coss.contractor_coss?.length > 0 ? (
                        <div className="employee-card-image">
                          <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                              <tr>
                                <th className="padding-bottom-5px">COS</th>
                                <th style={{ padding: "0px 4px" }}>:</th>
                                <th className="padding-bottom-5px">Approved Date</th>
                                <th style={{ padding: "0px 4px" }}>:</th>
                                <th className=" align-right padding-bottom-5px">Amount</th>

                              </tr>
                            </thead>
                            <tbody>
                              {project.coss.contractor_coss.map((cosDetails) => (
                                <tr>
                                  <td className="padding-bottom-5px">{cosDetails.cos_code}</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className=" text-center">
                                    {formatDate(cosDetails.approval_date)}
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">₹  {formatRoundoff(Number(cosDetails.amount))}</td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <p>-</p>
                        </div>
                      )}
                    </td>

                    <td className="table-body">
                      {project.eots.contractor_eots.length > 0 ? (
                        <div className="employee-card-image">
                          <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                              <tr>
                                <th className="padding-bottom-5px">EOT</th>
                                <th style={{ padding: "0px 4px" }}>:</th>
                                <th className="padding-bottom-5px">Approved Date</th>
                                <th style={{ padding: "0px 4px" }}>:</th>
                                <th className=" align-right padding-bottom-5px">Approved Days</th>
                              </tr>
                            </thead>
                            <tbody>
                              {project.eots.contractor_eots.map((eotDetails) => (
                                <tr>
                                  <td className="padding-bottom-5px">{eotDetails.eot_code}</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className=" text-center">
                                    {formatDate(eotDetails.approved_date)}
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{eotDetails.approved_days}</td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <p>-</p>
                        </div>
                      )}
                    </td>


                    <td className="table-body text-center">

                      <button
                        onClick={() => ViewAllProjectDetails(project)}
                        title="View Project Details"
                        className="document-download-button  width-5vw  width-5vw"
                      >
                        View
                      </button>
                    </td>

                    {
                      siteRole === "Hr" || siteRole === "BD" || siteRole === "Account" ? "" :
                        <td className="table-body text-center">

                          <DeleteProject i={project} fetchContractorProjects={fetchContractorProjects} />
                        </td>
                    }






                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ProjectConsultantView = ({ subCompany, searchName, searchSort }) => {


  const [projectsList, setProjectsList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const navigate = useNavigate();

  // Function to fetch projects for consultants
  const fetchConsultantProjects = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/consultant/`);


      let filteredProjects = response.data;

      // Apply sub-company filter
      if (subCompany && subCompany !== 'null') {
        filteredProjects = filteredProjects.filter(
          (project) => project.sub_company === subCompany
        );
      }

      // Apply search name filter
      if (searchName) {
        const lowerCaseName = searchName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (searchSort) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      // const sortingdata = sortProjects(filteredProjects)

      setProjectsList(filteredProjects);

    } catch (error) {
      console.error('Error fetching consultant projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchConsultantProjects();
  }, [subCompany, searchName, searchSort]); // Refetch projects when filters change


  const [viewMode, setViewMode] = useState('inactive'); // employees or inactive
  const tableRef = useRef(null);


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Consultant";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };

  // Helper function to split an array into two equal parts
  const splitArray = (array) => {
    const midIndex = Math.ceil(array.length / 2);
    return [array.slice(0, midIndex), array.slice(midIndex)];
  };

  const handleViewToggle = (mode) => {
    setViewMode(mode);
  };

  return (
    <>
      <div className="filter-showing">
        <div>Consultant details:</div>
        <div>
          Showing {projectsList.length} of {projectsList.length}
        </div>
      </div>

      {/* Toggle between Employees and Inactive Designations */}
      <div className="bloc-tabss2  wfm-margin" >
        <button
          className={viewMode === "inactive" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}

          onClick={() => handleViewToggle('inactive')}
        >
          Vacant Positions
        </button>
        <button
          className={viewMode === "employees" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => handleViewToggle('employees')}
        >
          View Employees
        </button>

      </div>

      <div className="table-css-white-background  table-box" style={{ paddingTop: "0px", height: "63vh" }}>
        <table ref={tableRef} className="font-size-label table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-th">
              <th className="table-heading-text">S.no.</th>
              <th className="table-heading-text">Project</th>
              <th className="table-heading-text text-center">Project Details</th>
              <th className="table-heading-text text-center">Key Professionals</th>
              <th className="table-heading-text text-center">Sub Professionals</th>
              <th className="table-heading-text text-center">Support Staff</th>
              <th className="table-heading-text">View Details</th>
              <th className="table-heading-text">Delete</th>

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {projectsList?.map((project, index) => (
              <React.Fragment key={index}>
                <tr key={project.id} className="custom-table-head-td4">
                  {/* Project Name and Short Description */}
                  <td className="table-body">{index + 1} </td>
                  <td className="table-body" title={`Project Name: \n${project.project_name}`} >
                    {project.site_prcode} : <br /><div style={{ width: "120px", whiteSpace: "normal" }}>{project.project_short_name}</div>
                  </td>

                  {/* Project Details */}
                  <td className="table-body">
                    {project.project_name ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="padding-bottom-5px">Consultant LOA</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.loa_award_date ? formattedDateLong(project?.loa_award_date) : "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Completion Date</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.project_completion_date ? formattedDateLong(project?.project_completion_date) : "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Consultancy Fees</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{formatRoundoff(project?.consultancy_fees) || "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Contract Mode</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.contract_mode || "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Lead</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right"> <div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.lead || "-"}</div></td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">JV</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right "  ><div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.JV || "-"}</div></td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Association</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right"><div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.association || "-"}</div></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="table-body ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Key Professionals */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees?.key_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Name</th>
                              <th style={{ padding: "0px 4px" }}>:</th>
                              <th className="padding-bottom-5px">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.employees?.key_professionals?.map((keyProfessional) => (
                              <tr key={keyProfessional.id}>
                                <td className="padding-bottom-5px">{keyProfessional.name}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{keyProfessional.designation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.key_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <th style={{ padding: "0px 4px" }}>:</th>

                              <th className="padding-bottom-5px text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.key_professionals.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Sub Professionals */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees.sub_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        {splitArray(project.employees.sub_professionals).map((split, index) => (

                          <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                              <tr>
                                <th className="padding-bottom-5px">Name</th>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <th className="padding-bottom-5px">Designation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {split.map((subProfessional) => (
                                <tr key={subProfessional.id}>
                                  <td className="padding-bottom-5px"> {subProfessional.name}</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>

                                  <td className="padding-bottom-5px">{subProfessional.designation}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.sub_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.sub_professionals.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Support Staff */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees.support_staff?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Name</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.employees.support_staff.map((supportStaff) => (
                              <tr key={supportStaff.id}>
                                <td className="padding-bottom-5px">{supportStaff.name}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{supportStaff.designation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.support_staff?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.support_staff.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* View Details Button */}
                  <td className="table-body">
                    <button onClick={() => ViewAllProjectDetails(project)} title="View Project Details" className="document-download-button  width-5vw  width-5vw">
                      View
                    </button>
                  </td>



                  <td className="table-body">

                    <DeleteProject i={project} fetchContractorProjects={fetchConsultantProjects} />
                  </td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};




export {

  SiteProjectTable,
};
