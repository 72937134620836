import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../../config/axios";
// import usePermission from "../../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast } from "../../CustomFunctions";
import { AddwithWhiteCircle } from "../../AllSvg";






const AddEmployeeInvoices = ({ p_id, invoice_id, getEmployeeInvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getTransportation();
        setShow(true);
    };

    const [formData, setFormData] = useState([]);

    const [employee, setEmployee] = useState([]);

    const getTransportation = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqemployeebyproject/${p_id}/`
            );

            if (Array.isArray(res.data)) {
                setEmployee(res.data);
                const initialData = res.data.map((item) => ({
                    project: p_id,
                    invoice: invoice_id,
                    employee: item.id,
                    actual_month: "",
                    month: month,
                    year: year,
                    current_invoice_amount: ""
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of transportation items");
            }
        } catch (err) {
            console.error("Error fetching transportation data", err);
        }
    };


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach((field) => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                // Submit the formData array as bulk data
                const res = await axios.post(
                    `${BASE_URL}/siteproject/invoiceemployeebulk/`,
                    formData
                );

                if (res.status === 201) {
                    await getEmployeeInvoice();
                    handleClose();

                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };
    console.log(formData)

    //! **************************** post API End ***********************!//

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Employee Invoice" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Employee Invocie
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Employee  Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Employee</th>
                                            <th className="align-center width-10vw">Actual Month</th>
                                            <th className="align-center width-10vw">Current Invoice Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td ">

                                                <td className="align-left width-5vw" >
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div title="Employee" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`employee_${index}`}
                                                            name="employee "
                                                            value={employee.find(t => t.id === entry.employee)?.empcode || "Loading..."}
                                                            className={`form-input-date width-10vw   ${errors[`${index}_employee`] ? "error" : ""}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Month" className="flex-column">

                                                        <input
                                                            type="number"
                                                            id={`actual_month_${index}`}
                                                            name="actual_month"
                                                            placeholder="Actual Month"
                                                            value={entry.actual_month}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date  width-5vw  ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Invoice Amount" className="flex-column form-group-selectt">

                                                        <input
                                                            id={`current_invoice_amount_${index}`}
                                                            name="current_invoice_amount"
                                                            type="number"
                                                            value={entry.current_invoice_amount}
                                                            placeholder="Current Invoice Amount"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw   ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>

                                </table>
                            </div>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button font-weight500"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};


const AddEmployeeInvoice = ({ p_id, invoice_id, getEmployeeInvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getTransportation();
        setShow(true);
    };

    const [formData, setFormData] = useState([]);

    const [employee, setEmployee] = useState([]);

    const getTransportation = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqemployeebyproject/${p_id}/`
            );

            if (Array.isArray(res.data)) {
                // Flatten data by spreading each employee's details into individual entries
                const expandedData = res.data.flatMap((item) =>
                    item.employee_name_details.map((empDetail) => ({
                        project: p_id,
                        invoice: invoice_id,
                        employee: item.id,
                        emp_code: empDetail.emp_code,
                        emp_name: empDetail.name,
                        emp_designation: empDetail.designation_name,

                        actual_month: "",
                        month: month,
                        year: year,
                        current_invoice_amount: ""
                    }))

                );

                setEmployee(res.data);
                setFormData(expandedData);
            } else {
                console.error("Error: Expected a list of transportation items");
            }
        } catch (err) {
            console.error("Error fetching transportation data", err);
        }
    };

    // Validation start
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach((field) => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    // Validation End

    // Post API
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(
                    `${BASE_URL}/siteproject/invoiceemployeebulk/`,
                    formData
                );

                if (res.status === 201) {
                    await getEmployeeInvoice();
                    handleClose();

                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };
    console.log(formData);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Employee Invoice" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Employee Invoice
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="model-width80vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Employee Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-5vw">S no.</th>
                                            {/* <th className="align-center width-10vw">Employee Code</th> */}
                                            <th className="align-center width-20vw">Employee Name</th>
                                            <th className="align-center width-20vw">Employee Desigantion</th>

                                            <th className="align-center width-10vw">Actual Month</th>
                                            <th className="align-center width-10vw">Current Invoice Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr key={index} className="custom-table-head-td ">
                                                <td className="align-left width-5vw">
                                                    {index + 1}
                                                </td>
                                                {/* <td>
                                                        <input
                                                            name="emp_code"
                                                            value={entry.emp_code || "N/A"}
                                                            className="form-input-date width-10vw"
                                                            disabled
                                                        />
                                                    </td> */}
                                                <td>
                                                    <input
                                                        name="emp_name"
                                                        value={entry.emp_name || "N/A"}
                                                        className="form-input-date width-20vw"
                                                        disabled
                                                    />
                                                </td>

                                                <td>
                                                    <input
                                                        name="emp_designation"
                                                        value={entry.emp_designation || "N/A"}
                                                        className="form-input-date width-20vw"
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        name="actual_month"
                                                        placeholder="Actual Month"
                                                        value={entry.actual_month}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        className={`form-input-date width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="current_invoice_amount"
                                                        type="number"
                                                        value={entry.current_invoice_amount}
                                                        placeholder="Current Invoice Amount"
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        className={`form-input-date width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button font-weight500"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};






const AddTransportationInvoice = ({ p_id, invoice_id, getTransportationInvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getTransportation();
        setShow(true);
    };


    const [formData, setFormData] = useState([]);

    const [transportation, setTransportation] = useState([]);

    const getTransportation = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqtransportbyproject/${p_id}/`
            );

            if (Array.isArray(res.data)) {
                setTransportation(res.data);
                const initialData = res.data.map((item) => ({
                    project: p_id,
                    invoice: invoice_id,
                    transport: item.id,
                    actual_month: "",
                    month: month,
                    year: year,
                    // current_invoice_amount: ""
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of transportation items");
            }
        } catch (err) {
            console.error("Error fetching transportation data", err);
        }
    };
    console.log(formData.actual_month);


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach((field) => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                } else {
                    delete newErrors[`${index}_${field}`]; // Remove error if input is present
                }
            });
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                // Submit the formData array as bulk data
                const res = await axios.post(
                    `${BASE_URL}/siteproject/invoicetransportationbulk-auto-calculate-amount/`,
                    formData
                );

                if (res.status === 201) {
                    await getTransportationInvoice();
                    // setShow(false);
                    // window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                // handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };
    console.log(formData)

    //! **************************** post API End ***********************!//

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Transportation" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Transportation
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Transportation for Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Transport</th>
                                            <th className="align-center width-10vw">Actual Month</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td ">

                                                <td className="align-left width-5vw" >
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div title="Transportation" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`transport_${index}`}
                                                            name="transport"
                                                            value={transportation.find(t => t.id === entry.transport)?.vehicle_description || "Loading..."}
                                                            className={`form-input-date width-10vw   ${errors[`${index}_transport`] ? "error" : ""}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Month" className="flex-column">
                                                        <input
                                                            className={`form-input-date  width-5vw  ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                            id={`actual_month_${index}`}
                                                            name="actual_month"
                                                            type="number"
                                                            value={entry.actual_month}
                                                            onChange={(e) => handleInputChange(e, index)}

                                                        />
                                                        <div>
                                                            {errors[`${index}_actual_month`] && (
                                                                <span className="error-message font-size-text ">{errors[`${index}_actual_month`]}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}

                                    </tbody>

                                </table>
                            </div>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button font-weight500"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};


const UpdateTransportationInvoice = ({ i, p_id, invoice_id, getTransportationInvoice }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        getTransportation();
        setShow(true);

    }
    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        transport: "",
        month: "",
        current_invoice_amount: "",

    });
    const [transportation, setTransportation] = useState([]);
    const getTransportation = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqtransportbyproject/${p_id}`);
            setTransportation(res.data);
        } catch (err) { }
    };

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "transport",
            "month",
            "current_invoice_amount",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoicetransportation/${i.id}/`,
                    formData
                );

                if (res.status === 201) {
                    await getTransportationInvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Transportation" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Transportation for Invoice</Modal.Title> </h1>

                            <div title="Transportation" className="flex-column form-group-selectt">
                                <label htmlFor="transport" className="form-labels  font-weight500    font-size-subheading">
                                    Transportation<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="transport"
                                    name="transport"
                                    placeholder="transport"
                                    value={formData.transport}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.transport ? "error" : inputState.transport ? "success" : ""}`}
                                >
                                    <option value="">Select Transport</option>
                                    {transportation?.sort((a, b) => a.vehicle_description.localeCompare(b.vehicle_description, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.vehicle_description}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.transport && <span className="error-message font-size-text ">{errors.transport}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>

                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );

}

const DeleteTransportationInvoice = ({ i, getTransportationInvoice }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        getTransportationInvoice();
    }
    const [loading, setLoading] = useState(false); //loading logic


    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoicetransportation/${i.id}/`);
            if (response.status === 200) {
                getTransportationInvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false); //loading logic
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Transportation Invoice" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )
}



const DutyTravelInvoice = ({ p_id, invoice_id, getDutyTravelinvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getDutyTravel();
        setShow(true);
    };

    const [formData, setFormData] = useState([]);

    const [dutyTravel, setDutyTravel] = useState([]);

    const getDutyTravel = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/boqtravelbyproject/${p_id}`);
            if (Array.isArray(res.data)) {
                setDutyTravel(res.data);
                const initialData = res.data.map(item => ({
                    project: p_id,
                    invoice: invoice_id,
                    dutytravel: item.id,
                    actual_trip: "",
                    month: month,
                    year: year,
                    // current_invoice_amount: ""
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of duty travel items");
            }
        } catch (err) {
            console.error("Error fetching duty travel data", err);
        }
    };

    // Validation logic
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach(field => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Loading state
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulkinvoicedutytravelapi1/`, formData);

                if (res.status === 201) {
                    await getDutyTravelinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Duty & Travels" className="upload-svg flex-row" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Duty & Travels
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Add Duty & Travel for Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Duty & Travel</th>
                                            <th className="align-center width-10vw">Actual Trip</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td" key={index}>
                                                <td className="align-left width-5vw">{index + 1}</td>
                                                <td>
                                                    <div title="Duty & Travel" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`dutytravel_${index}`}
                                                            name="dutytravel"
                                                            value={dutyTravel.find(t => t.id === entry.dutytravel)?.trips || "Loading..."}
                                                            className={`form-input-date width-10vw ${errors[`${index}_dutytravel`] ? "error" : ""}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Trip" className="flex-column">
                                                        <input
                                                            type="number"
                                                            id={`actual_trip_${index}`}
                                                            name="actual_trip"
                                                            placeholder="Actual Trip"
                                                            value={entry.actual_trip}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_actual_trip`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div title="Invoice Amount" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`current_invoice_amount_${index}`}
                                                            name="current_invoice_amount"
                                                            type="number"
                                                            value={entry.current_invoice_amount}
                                                            placeholder="Current Invoice Amount"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-models">
                                <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};


const UpdateDutyTravelInvoice = ({ i, p_id, invoice_id, getDutyTravelinvoice }) => {
    const [show, setShow] = useState(false);
    const [dutyTravel, setDutyTravel] = useState([]);

    const handleShow = () => {
        setFormData(i);
        getDutyTravel();
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        dutytravel: "",

    });
    const getDutyTravel = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqtravelbyproject/${p_id}`
            );
            setDutyTravel(res.data);
        } catch (err) { }
    };

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "dutytravel",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoiceduty/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getDutyTravelinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Duty and Travel" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Duty & Travels for Invoice</Modal.Title> </h1>

                            <div title="Duty & Travels" className="flex-column form-group-selectt">
                                <label htmlFor="dutytravel" className="form-labels  font-weight500    font-size-subheading">
                                    Duty & Travels<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="dutytravel"
                                    name="dutytravel"
                                    placeholder="Duty & Travels"
                                    value={formData.dutytravel}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.dutytravel ? "error" : inputState.dutytravel ? "success" : ""}`}
                                >
                                    <option value="">Select Duty & Travel</option>
                                    {dutyTravel?.sort((a, b) => a.trips.localeCompare(b.trips, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.trips}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.dutytravel && <span className="error-message font-size-text ">{errors.dutytravel}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    CurrentCurrent Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

const DeleteDutyTravelInvoice = ({ i, getDutyTravelinvoice }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    const [loading, setLoading] = useState(false);


    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoiceduty/${i.id}/`);
            if (response.status === 200) {
                getDutyTravelinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Duty and Travel Invoice" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )
}

const OfficeRentInvoice = ({ p_id, invoice_id, getOfficeRentinvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getOfficeRent();
        setShow(true);
    };

    const [formData, setFormData] = useState([]);

    const [officeRent, setOfficeRent] = useState([]);

    // Fetch Office Rent data
    const getOfficeRent = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/boqofficerentbyproject/${p_id}/`);
            if (Array.isArray(res.data)) {
                setOfficeRent(res.data);

                const initialData = res.data.map(item => ({
                    project: p_id,
                    invoice: invoice_id,
                    officerent: item.id,
                    actual_month: "",
                    month: month,
                    year: year,
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of office rent items");
            }
        } catch (err) {
            console.error("Error fetching office rent data", err);
        }
    };

    // Validation logic
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach(field => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Loading state
    const [loading, setLoading] = useState(false);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulkinvoiceofficerentapi1/`, formData);

                if (res.status === 201) {
                    await getOfficeRentinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Office Rent" className="upload-svg flex-row" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Office Rent
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Rent for Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Office Rent</th>
                                            <th className="align-center width-10vw">Actual Month</th>
                                            {/* <th className="align-center width-10vw">Current Invoice Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td" key={index}>
                                                <td className="align-left width-5vw">{index + 1}</td>
                                                <td>
                                                    <div title="Office Rent" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`officerent_${index}`}
                                                            name="officerent"
                                                            value={officeRent.find(t => t.id === entry.officerent)?.num_of_months || "Loading..."}
                                                            className={`form-input-date width-10vw ${errors[`${index}_officerent`] ? "error" : ""}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Month" className="flex-column">
                                                        <input
                                                            type="number"
                                                            id={`actual_month_${index}`}
                                                            name="actual_month"
                                                            placeholder="Actual Month"
                                                            value={entry.actual_month}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div title="Invoice Amount" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`current_invoice_amount_${index}`}
                                                            name="current_invoice_amount"
                                                            type="number"
                                                            value={entry.current_invoice_amount}
                                                            placeholder="Current Invoice Amount"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-models">
                                <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    disabled={loading}
                                    onClick={handleSubmit}

                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};



const UpdateOfficeRentInvoice = ({ i, p_id, invoice_id, getOfficeRentinvoice }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
        getOfficeRent();
        setFormData(i);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        officerent: "",
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "officerent",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const [officeRent, setOfficeRent] = useState([]);

    const getOfficeRent = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficerentbyproject/${p_id}/`
            );
            setOfficeRent(res.data);
        } catch (err) { }
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoiceofficerent/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeRentinvoice();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Officerent" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Office Rent for Invoice</Modal.Title> </h1>

                            <div title="Office Rent" className="flex-column form-group-selectt">
                                <label htmlFor="officerent" className="form-labels  font-weight500    font-size-subheading">
                                    Office Rent<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="officerent"
                                    name="officerent"
                                    placeholder="Office Rent"
                                    value={formData.officerent}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.officerent ? "error" : inputState.officerent ? "success" : ""}`}
                                >
                                    <option value="">Select Office Rent</option>
                                    {officeRent?.map((i, index) => (
                                        <option key={index} value={i.id}>Months : {i.num_of_months}, Rent : {i.rate_per_month}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.officerent && <span className="error-message font-size-text ">{errors.officerent}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );

};

const DeleteOfficeRent = ({ i, getOfficeRentinvoice }) => {

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoiceofficerent/${i.id}/`);
            if (response.status === 200) {
                getOfficeRentinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Office Rent" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )


}

const OfficeSuppliesInvoice = ({ p_id, invoice_id, getOfficeSuppliesinvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getOfficeSupplies();
        setShow(true);
    };

    const [formData, setFormData] = useState([]);

    const [officeSupplies, setOfficeSupplies] = useState([]);

    // Fetch Office Supplies data
    const getOfficeSupplies = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/boqofficesuppliesbyproject/${p_id}/`);
            if (Array.isArray(res.data)) {
                setOfficeSupplies(res.data);

                const initialData = res.data.map(item => ({
                    project: p_id,
                    invoice: invoice_id,
                    officesupplies: item.id,
                    actual_month: "",
                    month: month,
                    year: year,
                    // current_invoice_amount: ""
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of office supplies items");
            }
        } catch (err) {
            console.error("Error fetching office supplies data", err);
        }
    };

    // Validation logic
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach(field => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Loading state
    const [loading, setLoading] = useState(false);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulkinvoiceofficesupplies/`, formData);

                if (res.status === 201) {
                    await getOfficeSuppliesinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Office Supplies" className="upload-svg flex-row" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Office Supplies
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Supplies for Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Office Supplies</th>
                                            <th className="align-center width-10vw">Actual Month</th>
                                            {/* <th className="align-center width-10vw">Current Invoice Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td" key={index}>
                                                <td className="align-left width-5vw">{index + 1}</td>
                                                <td>
                                                    <div title="Office Supplies" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`officesupplies_${index}`}
                                                            name="officesupplies"
                                                            value={officeSupplies.find(t => t.id === entry.officesupplies)?.item || "Loading..."}
                                                            className={`form-input-date width-10vw ${errors[`${index}_officesupplies`] ? "error" : ""}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Month" className="flex-column">
                                                        <input
                                                            type="number"
                                                            id={`actual_month_${index}`}
                                                            name="actual_month"
                                                            placeholder="Actual Month"
                                                            value={entry.actual_month}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div title="Invoice Amount" className="flex-column form-group-selectt">
                                                        <input
                                                            id={`current_invoice_amount_${index}`}
                                                            name="current_invoice_amount"
                                                            type="number"
                                                            value={entry.current_invoice_amount}
                                                            placeholder="Current Invoice Amount"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-models">
                                <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                    disabled={loading}
                                    onClick={handleSubmit}

                                >
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};


const UpdateOfficeSuppliesInvoice = ({ i, p_id, invoice_id, getOfficeSuppliesinvoice }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
        getOfficeSupplies();
        setFormData(i);
    }

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        officesupplies: "",
    });

    const [officeSupplies, setOfficeSupplies] = useState([]);

    const getOfficeSupplies = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficesuppliesbyproject/${p_id}/`);
            setOfficeSupplies(res.data);
        } catch (err) { }
    };

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "officesupplies",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoiceofficesupplies/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeSuppliesinvoice();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button className="model-edit-button" title="Update Office Supplies" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Office Supplies for Invoice</Modal.Title> </h1>

                            <div title="Office Supplies" className="flex-column form-group-selectt">
                                <label htmlFor="officesupplies" className="form-labels  font-weight500    font-size-subheading">
                                    Office Supplies<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="officesupplies"
                                    name="officesupplies"
                                    placeholder="Office Supplies"
                                    value={formData.officesupplies}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.officesupplies ? "error" : inputState.officesupplies ? "success" : ""}`}
                                >
                                    <option value="">Select Office Supplies</option>
                                    {officeSupplies?.sort((a, b) => a.item.localeCompare(b.item, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.item}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.officesupplies && <span className="error-message font-size-text ">{errors.officesupplies}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}


const DeleteOfficeSuppliesInvoice = ({ i, getOfficeSuppliesinvoice }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoiceofficesupplies/${i.id}/`);
            if (response.status === 200) {
                getOfficeSuppliesinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Office Supplies" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>
                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )
}


const OfficeFurnitureInvoice = ({ p_id, invoice_id, getOfficeFurnitureinvoice, month, year }) => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState([]);
    const [officeFurniture, setOfficeFurniture] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        await getOfficeFurniture();
        setShow(true);
    };

    const getOfficeFurniture = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/boqofficefurnitureandequipmentbyproject/${p_id}/`);
            if (Array.isArray(res.data)) {
                setOfficeFurniture(res.data);

                // Initialize form data
                const initialData = res.data.map(item => ({
                    project: p_id,
                    invoice: invoice_id,
                    officefurniture: item.id,
                    actual_month: "",
                    month: month,
                    year: year,
                    // current_invoice_amount: ""
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of office furniture items");
            }
        } catch (err) {
            console.error("Error fetching office furniture data", err);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const requiredFields = [];
            requiredFields.forEach(field => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulkinvoiceofficefurnitureandequipment/`, formData);
                if (res.status === 201) {
                    await getOfficeFurnitureinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);

            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Office Furniture" className="upload-svg flex-row" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1415_3177)">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Office Furniture
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Furniture for Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex-row justify-center">
                        <table className="table-css width-10vw">
                            <thead>
                                <tr className="custom-table-head-tr width-10vw">
                                    <th className="align-left width-10vw">S no.</th>
                                    <th className="align-center width-10vw">Office Furniture</th>
                                    <th className="align-center width-10vw">Actual Month</th>
                                    {/* <th className="align-center width-10vw">Current Invoice Amount</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {formData.map((entry, index) => (
                                    <tr className="custom-table-head-td" key={index}>
                                        <td className="align-left width-5vw">{index + 1}</td>
                                        <td>
                                            <div title="Office Furniture" className="flex-column form-group-selectt">
                                                <input
                                                    type="text"
                                                    value={`Months:${officeFurniture.find(t => t.id === entry.officefurniture)?.num_of_months}  Rate: Rs.${officeFurniture.find(t => t.id === entry.officefurniture)?.rate_per_month}`}
                                                    readOnly
                                                    className="form-input-date width-10vw"
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div title="Actual Month" className="flex-column">
                                                <input
                                                    type="number"
                                                    id={`actual_month_${index}`}
                                                    name="actual_month"
                                                    placeholder="Actual Month"
                                                    value={entry.actual_month}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    className={`form-input-date width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                />
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                                <input
                                                    id={`current_invoice_amount_${index}`}
                                                    name="current_invoice_amount"
                                                    type="number"
                                                    value={entry.current_invoice_amount}
                                                    placeholder="Current Invoice Amount"
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    className={`form-input-date width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                />
                                            </div>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>

                        <button onClick={handleSubmit} type="submit" className="model-button font-weight500 model-button-submit font-size-heading" disabled={loading}>
                            Add
                        </button>
                    </div>

                </Modal.Body>
            </Modal >

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};



const UpdateFurnitureInvoice = ({ p_id, i, invoice_id, getOfficeFurnitureinvoice }) => {

    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        officefurniture: "",
    });

    const handleShow = () => {
        setShow(true);
        getOfficeFurniture();
        setFormData(i);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [officeFurniture, setOfficeFurniture] = useState([]);

    const getOfficeFurniture = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficefurnitureandequipmentbyproject/${p_id}/`);
            setOfficeFurniture(res.data);
        } catch (err) { }
    };
    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "officefurniture",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoiceofficefurniture/${i.id}/`,
                    formData
                );
                if (res.status === 200) {
                    await getOfficeFurnitureinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Office Furniture" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Office Furniture for Invoice</Modal.Title> </h1>



                            <div title="Office Furniture" className="flex-column form-group-selectt">
                                <label htmlFor="officefurniture" className="form-labels  font-weight500    font-size-subheading">
                                    Office Furniture<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="officefurniture"
                                    name="officefurniture"
                                    placeholder="Office Furniture"
                                    value={formData.officefurniture}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.officefurniture ? "error" : inputState.officefurniture ? "success" : ""}`}
                                >
                                    <option value="">Select Office Furniture</option>
                                    {officeFurniture?.map((i, index) => (
                                        <option key={index} value={i.id}>Months : {i.num_of_months}, Rent : {i.rate_per_month}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.officefurniture && <span className="error-message font-size-text ">{errors.officefurniture}</span>}
                            </div>


                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}


                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

const DeleteFurnitureInvoice = ({ i, getOfficeFurnitureinvoice }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoiceofficefurniture/${i.id}/`);
            if (response.status === 200) {
                getOfficeFurnitureinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Furniture" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )

}



const ReportsPrinttInvoice = ({ p_id, invoice_id, getReportsPrinttinvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getReportsPrinting();
        setShow(true);
    };

    // Initialize formData as an array of report entries


    const [formData, setFormData] = useState([]);


    const [reportsPrinting, setReportsPrinting] = useState([]);

    const getReportsPrinting = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqreportsanddocprintbyproject/${p_id}/`
            );
            if (Array.isArray(res.data)) {
                setReportsPrinting(res.data);
                const initialData = res.data.map((item) => ({
                    project: p_id,
                    invoice: invoice_id,
                    month: month,
                    actual_month: "",
                    // current_invoice_amount: "",
                    reportanddoc: item.id,
                    year: year,
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of transportation items");
            }
        } catch (err) { }
    };

    // Validation state
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const requiredFields = [

            ];
            requiredFields.forEach((field) => {
                if (!entry[field]) {
                    newErrors[`${index}_${field}`] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
                }
            });
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // Post API
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);
            const loadingToastId = toast.loading("Loading: Please wait...");

            try {
                const res = await axios.post(
                    `${BASE_URL}/siteproject/bulkinvoicereportsanddocumentprinting/`,
                    formData
                );

                if (res.status === 201) {
                    await getReportsPrinttinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Reports & Document" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "}
                Reports & Document
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">
                                <Modal.Title>Add Reports & Document for Invoice</Modal.Title>
                            </h1>
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr width-10vw">
                                            <th className="align-left width-10vw">S no.</th>
                                            <th className="align-center width-10vw">Reports & Document</th>
                                            <th className="align-center width-10vw">Total number of Copies</th>
                                            {/* <th className="align-center width-10vw">Current Invoice Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr key={index} className="custom-table-head-td">
                                                <td className="align-left width-5vw">{index + 1}</td>
                                                <td>
                                                    <div title="Reports & Document" className="flex-column form-group-selectt">
                                                        <input
                                                            name="reportanddoc"
                                                            value={reportsPrinting.find(t => t.id === entry.reportanddoc)?.description || "Loading..."}

                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date  width-10vw ${errors[`${index}_reportanddoc`] ? "error" : ""}`}
                                                            disabled
                                                        />

                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        id={`actual_month_${index}`}
                                                        name="actual_month"
                                                        placeholder="Actual Month"
                                                        value={entry.actual_month}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        className={`form-input-date  width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                    />
                                                </td>
                                                {/* <td>
                                                    <input
                                                        id={`current_invoice_amount_${index}`}
                                                        name="current_invoice_amount"
                                                        type="number"
                                                        min={0}
                                                        placeholder="Current Invoice Amount"
                                                        value={entry.current_invoice_amount}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        className={`form-input-date  width-5vw ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                    />
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-models">

                                <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button onClick={handleSubmit} type="submit" className="model-button font-weight500 model-button-submit" disabled={loading}>
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal >
        </>
    );
};


const UpdateReportsPrinttInvoice = ({ i, p_id, invoice_id, getReportsPrinttinvoice }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getReportsPrinting()
        setShow(true);
        setFormData(i);

    };

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        reportanddoc: "",
    });


    const [reportsPrinting, setReportsPrinting] = useState([]);

    const getReportsPrinting = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqreportsanddocprintbyproject/${p_id}/`);
            setReportsPrinting(res.data);
        } catch (err) { }
    };

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "reportanddoc",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoicereportsanddocument/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getReportsPrinttinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Reports" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Reports & Document for Invoice</Modal.Title> </h1>

                            <div title="Reports & Document" className="flex-column form-group-selectt">
                                <label htmlFor="reportanddoc" className="form-labels  font-weight500    font-size-subheading">
                                    Reports & Document<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="reportanddoc"
                                    name="reportanddoc"
                                    placeholder="reportanddoc"
                                    value={formData.reportanddoc}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.reportanddoc ? "error" : inputState.reportanddoc ? "success" : ""}`}
                                >
                                    <option value="">Select Reports & Document*</option>
                                    {reportsPrinting?.sort((a, b) => a.description.localeCompare(b.description, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.description}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.reportanddoc && <span className="error-message font-size-text ">{errors.reportanddoc}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteReportsPrinttInvoice = ({ i, getReportsPrinttinvoice }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoicereportsanddocument/${i.id}/`);
            if (response.status === 200) {
                getReportsPrinttinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Reports" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )

}

const RoadSurveyEquipmentInvoice = ({ p_id, invoice_id, getRoadSurveyinvoice, month, year }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getRoadSurveyEquipment()
        setShow(true)
    };

    const [formData, setFormData] = useState([]);





    const [reportsPrinting, setReportsPrinting] = useState([]);

    const getRoadSurveyEquipment = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqroadsurveyequipmentbyproject/${p_id}/`);
            setReportsPrinting(res.data);
            if (Array.isArray(res.data)) {
                setReportsPrinting(res.data);
                const initialData = res.data.map((item) => ({
                    project: p_id,
                    invoice: invoice_id,
                    actual_month: "",
                    current_invoice_amount: "",
                    roadsurvey: item.id,
                    month: month,
                    year: year
                }));
                setFormData(initialData);
            } else {
                console.error("Error: Expected a list of transportation items");
            }
        } catch (err) { }
    };

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [



        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/bulkinvoiceroadsurveyequipment/`,
                    formData
                );

                if (res.status === 201) {
                    await getRoadSurveyinvoice();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <button title="Add Road Survey Equipment" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Road Survey Equipment
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>

                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">
                                <Modal.Title>Add Road Survey Equipment for Invoice</Modal.Title>
                            </h1>
                            <div className="flex-row justify-center">
                                <table className="table-css width-10vw">
                                    <thead>
                                        <tr className="custom-table-head-tr">
                                            <th className="align-left width-10vw">S no.</th>

                                            <th className="align-left">Road Survey Equipment</th>
                                            <th className="align-center">Actual Month</th>
                                            {/* <th className="align-center">Current Invoice Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.map((entry, index) => (
                                            <tr className="custom-table-head-td ">
                                                <td className="align-left width-5vw" >
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div title="Road Survey Equipment" className="flex-column form-group-selectt">

                                                        <input
                                                            id="roadsurvey"
                                                            name="roadsurvey"
                                                            value={reportsPrinting.find(t => t.id === entry.roadsurvey)?.description || "Loading..."}
                                                            onChange={handleInputChange}
                                                            className={`form-input-date width-10vw n ${errors.roadsurvey ? "error" : inputState.roadsurvey ? "success" : ""}`}
                                                            disabled
                                                        />

                                                    </div>
                                                </td>
                                                <td>
                                                    <div title="Actual Month" className="flex-column">
                                                        <input
                                                            type="number"
                                                            id={`actual_month_${index}`}
                                                            name="actual_month"
                                                            placeholder="Actual Month"
                                                            value={entry.actual_month}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date  width-5vw  ${errors[`${index}_actual_month`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div title="Invoice Amount" className="flex-column form-group-selectt">

                                                        <input
                                                            id={`current_invoice_amount_${index}`}
                                                            name="current_invoice_amount"
                                                            type="number"
                                                            value={entry.current_invoice_amount}
                                                            placeholder="Current Invoice Amount"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className={`form-input-date width-5vw   ${errors[`${index}_current_invoice_amount`] ? "error" : ""}`}
                                                        />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>



                            <div className="button-models">
                                <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button onClick={handleSubmit} type="submit" className="model-button font-weight500 model-button-submit font-size-heading" disabled={loading}>
                                    Add
                                </button>
                            </div>

                        </div>
                    </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal >
        </>
    );
};
const UpdateRoadSurveyEquipmentInvoice = ({ i, p_id, invoice_id, getRoadSurveyinvoice }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getRoadSurveyEquipment()
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        project: p_id,
        invoice: invoice_id,
        month: "",
        current_invoice_amount: "",
        roadsurvey: "",
    });


    const [reportsPrinting, setReportsPrinting] = useState([]);

    const getRoadSurveyEquipment = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqroadsurveyequipmentbyproject/${p_id}/`);
            setReportsPrinting(res.data);
        } catch (err) { }
    };

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "project",
            "invoice",
            "month",
            "current_invoice_amount",
            "roadsurvey",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/invoiceroadsurveyequipment/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getRoadSurveyinvoice();
                    handleClose();
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button className="model-edit-button" title="Update Road Survey" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Road Survey Equipment for Invoice</Modal.Title> </h1>

                            <div title="Road Survey Equipment" className="flex-column form-group-selectt">
                                <label htmlFor="roadsurvey" className="form-labels  font-weight500    font-size-subheading">
                                    Road Survey Equipment<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="roadsurvey"
                                    name="roadsurvey"
                                    placeholder="Road Survey Equipment"
                                    value={formData.roadsurvey}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.roadsurvey ? "error" : inputState.roadsurvey ? "success" : ""}`}
                                >
                                    <option value="">Select Road Survey Equipment</option>
                                    {reportsPrinting?.sort((a, b) => a.description.localeCompare(b.description, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.description}</option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.roadsurvey && <span className="error-message font-size-text ">{errors.roadsurvey}</span>}
                            </div>

                            <div title="Month" className="flex-column">
                                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                                    Month<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    onWheel={(e) => e.target.blur()}
                                    id="month"
                                    name="month"
                                    placeholder="Month"
                                    onChange={handleInputChange}
                                    value={formData.month}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                />
                                {errors.month && (
                                    <span className="error-message font-size-text ">{errors.month}</span>
                                )}
                            </div>

                            <div title="Invoice Amount" className="flex-column form-group-selectt">
                                <label htmlFor="current_invoice_amount" className="form-labels  font-weight500    font-size-subheading">
                                    Current Invoice Amount<span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    id="current_invoice_amount"
                                    name="current_invoice_amount"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Current Invoice Amount"
                                    onChange={handleInputChange}
                                    value={formData.current_invoice_amount}
                                    className={`form-input ${errors.current_invoice_amount ? "error" : inputState.current_invoice_amount ? "success" : ""
                                        }`}
                                />
                                {errors.current_invoice_amount && <span className="error-message font-size-text ">{errors.current_invoice_amount}</span>}
                            </div>
                            {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                            {errors.invoice && <span className="error-message font-size-text ">{errors.invoice}</span>}

                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};


const DeleteRoadSurveyEquipmentInvoice = ({ i, getRoadSurveyinvoice }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const response = await axios.delete(`${BASE_URL}/siteproject/invoiceroadsurveyequipment/${i.id}/`);
            if (response.status === 200) {
                getRoadSurveyinvoice();
                handleClose();
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    }
    return (
        <>
            <button title="Delete Road Survey" className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Delete Record of {i.item}</Modal.Title> </h1>

                        Are You Sure You Want to Delete {i.item}
                        <div className="button-models">
                            <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    )

}


export {

    AddEmployeeInvoice,




    AddTransportationInvoice,
    DutyTravelInvoice,
    OfficeRentInvoice,

    OfficeSuppliesInvoice,
    OfficeFurnitureInvoice,
    ReportsPrinttInvoice,
    RoadSurveyEquipmentInvoice,


    UpdateDutyTravelInvoice,
    UpdateTransportationInvoice,
    UpdateOfficeRentInvoice,
    UpdateOfficeSuppliesInvoice,
    UpdateFurnitureInvoice,
    UpdateReportsPrinttInvoice,
    UpdateRoadSurveyEquipmentInvoice,

    DeleteDutyTravelInvoice,
    DeleteTransportationInvoice,
    DeleteOfficeRent,
    DeleteOfficeSuppliesInvoice,
    DeleteFurnitureInvoice,
    DeleteReportsPrinttInvoice,
    DeleteRoadSurveyEquipmentInvoice,


}