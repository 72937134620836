import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { useLocation } from "react-router-dom";
import { UpdateProjectAllDetails, AddContractor } from "./ProjectDetailsCrudButtons";
import { BillingDetailsTable, BillTable, ConsultancyDetails, ContractorDetails, COSTable, EmployeeTable, EOTTable, LetterTracking, MilestoneTable, Progress } from "./SiteProjectManagementComponents";
import { handleErrorToast } from "../CustomFunctions";
import { CircularGrid } from "../AllSvg";
import usePermission from "../../config/permissions";

// tgr

const SiteProjectBillDetails = () => {

  const location = useLocation();
  const projectData = location.state ? location.state.project : null;
  const projectDataa = location.state ? location.state.project.id : null;

  const [toggleView, setToggleView] = useState("Bill Details");

  // *********** Employee Details Api Start ***********


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbar name={`Bill Details`} url="Site Project" />

      <div className="content-tabs">
        <div className="field-cont-between">
          <div className="attendance-heading font-weight500 font-size-heading">Bill Details of:<br />{projectData.project_short_name}</div>
          <div className="field-cont">
            <div className="table-searchh dropdown-container field-cont-div">
              <select
                onChange={(e) => setToggleView(e.target.value)}
                value={toggleView}
                className="dropdown-wh font-size-subheading  ">
                <option value="Bill Details">Bill Details</option>
                <option value="Bills">Bills</option>
                {/* {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))}; */}

              </select>
            </div>
          </div>

        </div>
        {toggleView === 'Bill Details' ?
          <div >
            <BillingDetailsTable projectId={projectDataa} />
          </div>

          :
          toggleView === 'Bills' ?
            <div >
              <BillTable projectId={projectDataa} />
            </div>
            : null
        }




      </div >
    </>
  );
};






const ProjectAllDetails = () => {
  const location = useLocation();
  const { projectID, siteRole } = usePermission();

  const projectIdd = projectID ? projectID : location.state?.project?.id;
  const [buffer, setBuffering] = useState(false);

  // State Variables
  const [projectData, setProjectsData] = useState();
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [contractorlist, setContractorList] = useState([]);
  const [contractor, setContractor] = useState(null);
  const [siteProject, setSiteProject] = useState();

  const [toggleState, setToggleState] = useState("consultant");
  const [toggleStatehange, setToggleStatechange] = useState(10);
  const [consultancyDetailsDetails, setConsultancyDetailsData] = useState([]);

  // Function to Fetch Project Data
  const getProjects = async () => {
    setBuffering(true);
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);

      const proj = await axios.get(`${BASE_URL}/siteproject/project/${projectIdd}/`);
      setProjectsData(proj.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);
    }
  };

  const getConsultancyDetails = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/contractor/${contractor}/`
      );
      setConsultancyDetailsData(response.data);
    } catch (err) {
    } finally {
      setBuffering(false);
    }
  };



  const getProjectContractorDetails = async () => {
    setBuffering(true);
    try {
      const contractordetails = await axios.get(
        `${BASE_URL}/siteproject/contractorbyproject/${projectIdd}/`
      );
      setContractorList(contractordetails.data);

      // Check if the contractor list is not empty and set the last contractor as selected
      if (contractordetails.data.length > 0) {
        setContractor(contractordetails.data.slice(-1)[0]?.id); // Using slice to get the last contractor
      } else {
        setContractor(undefined); // or handle it accordingly
      }
    } catch (err) {
      // handleErrorToast(err);
    } finally {
      setBuffering(false);
    }
  };
  // Load Data on Initial Render
  useEffect(() => {
    getProjects();
    // getProjectContractorDetails();
    getConsultancyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to Handle Toggle State Changes
  useEffect(() => {
    if (toggleState === "contractor") {
      getProjectContractorDetails();
      // Set default toggleStatehange for contractor view
      setToggleStatechange(11);
    }
    if (toggleState === "consultant") {
      setToggleStatechange(10); // Set to consultant default
      setContractor(null)
    }
    // Add additional logic if needed
  }, [toggleState]);




  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbar name={`Project Details`} url="Site Project" />

      <div className="content-tabs">

        <div className="info-box-div-bg">
          <div className="jd-heading-outer heading-bg-color-white">
            <div className="jd-heading-outer-flex">
              <h2 className="jd-heading-main font-weight600 font-size-heading">
                Project Details of "{projectData?.site_prcode} : {projectData?.project_short_name ? projectData?.project_short_name : "-"}"
              </h2>
              {
                sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" :
                  <UpdateProjectAllDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />
              }
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
          </div>
          {/* Project Details */}
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text">Project Name</td>
            <th className="personal-detail-2 font-weight500 font-size-text">{projectData?.project_name}</th>
          </tr>
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text">Regional Office Address (RO Address)</td>
            <th className="personal-detail-2 font-weight500 font-size-text">{projectData?.ro_address ? projectData?.ro_address : "-"}</th>
          </tr>
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text">PIU Address</td>
            <th className="personal-detail-2 font-weight500 font-size-text">{projectData?.piu_address ? projectData?.piu_address : "-"}</th>
          </tr>
          <tr>
            <td className="personal-detail-1 font-weight500 font-size-text">Project Key Point</td>
            <th className="personal-detail-2 font-weight500 font-size-text">{projectData?.project_key_point ? projectData?.project_key_point : "-"}</th>
          </tr>
        </div>

        <div className="filter-outer-box">
          <div className="field-cont">
            <div title="CC Filter" className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw date-field"
                value={toggleState}
                onChange={(e) => setToggleState(e.target.value)}
              >
                <option value="consultant">Consultant</option>
                <option value="contractor">Contractor</option>
              </select>
              {toggleState === "contractor" ? <hr className="field-cont-hr" /> : ""}
            </div>

            {/* Contractor Selector */}
            {toggleState === "contractor" ? (
              <div title="Contractor Name" className="field-cont-div">
                <CircularGrid />
                <select
                  onChange={(e) => setContractor(e.target.value === "" ? undefined : e.target.value)} // Set undefined if the value is empty
                  value={contractor || ""} // Default to empty string if contractor is undefined
                  className="attendance-input-field date-field"
                >

                  {contractorlist.map((e) => (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
            ) : ""}
          </div>

          {toggleState === "contractor" ?
            <div className="btn-cont">
              <AddContractor projectId={projectIdd} getProjects={getProjects} getProjectContractorDetails={getProjectContractorDetails} getDetails={getConsultancyDetails} />
            </div>
            : ""}
        </div>

        {/* Tabs and Content */}

        {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ?
          <div className="bloc-tabss wfm-without-margin">
            {toggleState === "consultant" && (
              <button
                className={toggleStatehange === 10 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                onClick={() => setToggleStatechange(10)}
              >
                Consultant Details
              </button>
            )}

            {toggleState === "contractor" && (
              <>
                <button
                  className={toggleStatehange === 11 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(11)}
                >
                  Contractor Details
                </button>


                <button
                  className={toggleStatehange === 1 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(1)}
                >
                  Contractor Bills
                </button>
                <button
                  className={toggleStatehange === 2 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(2)}
                >
                  Contractor Billing Details
                </button>
                <button
                  className={toggleStatehange === 6 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(6)}
                >
                  Contractor Milestone
                </button>


                <button
                  className={toggleStatehange === 9 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(9)}
                >
                  Site Progress
                </button>



              </>
            )}

            {/* Other Tabs */}
            <button
              className={toggleStatehange === 3 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
              onClick={() => setToggleStatechange(3)}
            >
              EOT
            </button>
            <button
              className={toggleStatehange === 4 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
              onClick={() => setToggleStatechange(4)}
            >
              COS
            </button>
          </div>
          : <div className="bloc-tabss wfm-without-margin">
            {toggleState === "consultant" && (
              <button
                className={toggleStatehange === 10 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                onClick={() => setToggleStatechange(10)}
              >
                Consultant Details
              </button>
            )}

            {toggleState === "contractor" && (
              <>
                <button
                  className={toggleStatehange === 11 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(11)}
                >
                  Contractor Details
                </button>
                {sessionStorage.getItem('sitemanagement_role') === "Hr" || sessionStorage.getItem('sitemanagement_role') === "BD" ? "" :
                  <> <button
                    className={toggleStatehange === 1 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                    onClick={() => setToggleStatechange(1)}
                  >
                    Contractor Bills
                  </button>
                    <button
                      className={toggleStatehange === 2 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                      onClick={() => setToggleStatechange(2)}
                    >
                      Contractor Billing Details
                    </button>
                  </>}
                <button
                  className={toggleStatehange === 6 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(6)}
                >
                  Contractor Milestone
                </button>
              </>
            )}

            {/* Other Tabs */}
            <button
              className={toggleStatehange === 3 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
              onClick={() => setToggleStatechange(3)}
            >
              EOT
            </button>
            <button
              className={toggleStatehange === 4 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
              onClick={() => setToggleStatechange(4)}
            >
              COS
            </button>
            {sessionStorage.getItem('sitemanagement_role') === "Hr" || sessionStorage.getItem('sitemanagement_role') === "Account" ? "" :
              <button
                className={toggleStatehange === 5 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                onClick={() => setToggleStatechange(5)}
              >
                Letters
              </button>
            }



            {toggleState === "consultant" && (
              <>
                <button
                  className={toggleStatehange === 7 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(7)}
                >
                  Employee
                </button>
                <button
                  className={toggleStatehange === 8 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                  onClick={() => setToggleStatechange(8)}
                >
                  Vehicles
                </button></>
            )}




          </div>}




        {/* Dynamic Component Loading */}
        {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ?
          <>
            <div className="info-box-div-without-bg">
              {toggleStatehange === 1 && toggleState === "contractor" && <BillTable projectId={projectIdd} contractor={contractor} contractorlist={contractorlist} />}
              {toggleStatehange === 2 && toggleState === "contractor" && <BillingDetailsTable projectId={projectIdd} contractor={contractor} />}
              {toggleStatehange === 10 && <ConsultancyDetails projectId={projectIdd} projectData={projectData} getProjects={getProjects} buffer={buffer} />}
              {toggleStatehange === 11 && toggleState === "contractor" && <ContractorDetails contractor={contractor} />}
              {toggleStatehange === 3 && <EOTTable projectId={projectIdd} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />}
              {toggleStatehange === 4 && <COSTable projectId={projectIdd} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />}
              {toggleStatehange === 6 && toggleState === "contractor" && <MilestoneTable projectId={projectIdd} appointed_date={projectData?.loa_award_date} contractor={contractor} contractorlist={getConsultancyDetails} />}
              {toggleStatehange === 9 && <Progress projectId={projectIdd} contractor={contractor} contractorlist={contractorlist} />}


            </div>

          </>
          :
          <div className="info-box-div-without-bg">
            {toggleStatehange === 1 && toggleState === "contractor" && <BillTable projectId={projectIdd} contractor={contractor} contractorlist={contractorlist} />}
            {toggleStatehange === 2 && toggleState === "contractor" && <BillingDetailsTable projectId={projectIdd} contractor={contractor} />}
            {toggleStatehange === 3 && <EOTTable projectId={projectIdd} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />}
            {toggleStatehange === 4 && <COSTable projectId={projectIdd} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />}
            {
              sessionStorage.getItem('sitemanagement_role') === "Hr" ? "" :
                <>
                  {toggleStatehange === 5 && <LetterTracking projectId={projectIdd} letterBy={toggleState} contractor={contractor} contractorlist={contractorlist} />}
                </>
            }
            {toggleStatehange === 6 && toggleState === "contractor" && <MilestoneTable projectId={projectIdd} appointed_date={projectData?.loa_award_date} contractor={contractor} contractorlist={contractorlist} />}
            {toggleStatehange === 7 && toggleState === "consultant" && <EmployeeTable projectId={projectIdd} companyId={projectData?.sub_company} />}
            {toggleStatehange === 9 && <Progress projectId={projectIdd} contractor={contractor} contractorlist={contractorlist} />}
            {toggleStatehange === 8 && toggleState === "consultant" && (
              <div className="table-css-white-background">
                <div>
                  <div className="jd-heading-outer-flex ">
                    <h2 className="jd-heading-main font-weight600 font-size-heading">Vehicles</h2>
                  </div>
                  <div>
                    <div className="jd-heading-bottom-bold"></div>
                    <div className="jd-heading-bottom-light"></div>
                  </div>
                </div>
              </div>
            )}
            {toggleStatehange === 9 && <Progress projectId={projectIdd} contractor={contractor} contractorlist={contractorlist} />}
            {toggleStatehange === 10 && <ConsultancyDetails projectId={projectIdd} projectData={projectData} getProjects={getProjects} buffer={buffer} />}
            {toggleStatehange === 11 && toggleState === "contractor" && <ContractorDetails projectId={projectIdd} contractor={contractor} getProjectContractorDetails={getProjectContractorDetails} />}
          </div>
        }

      </div >
    </>
  );
};





export {
  SiteProjectBillDetails,
  ProjectAllDetails,


};
