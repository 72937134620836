import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import Logo from "../../assets/images/resetpassword.png"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import { EyeBottonInput, Eyebuttonwithline } from "../../components/AllSvg";


const Resetpassword = () => {

  let { uidb64, token } = useParams();



  // http://localhost:3000/forgotpassword




  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");

  const [formData, setFormData] = useState({
    new_password: "",
    confirm_new_password: ""
  });


  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = ['new_password', 'confirm_new_password'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    if (formData.new_password !== formData.confirm_new_password) {
      newErrors.confirm_new_password = "Passwords do not match";
    }

    // Password requirements
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!passwordRegex.test(formData.new_password)) {
      newErrors.new_password =
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const [loading, setLoading] = useState(false); //loading logic


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      setLoading(true); //loading logic



      try {
        let res = await axios.put(`${BASE_URL}/user/forgotpasswordchange/${uidb64}/${token}/`, formData);


        if (res.status === 200) {
          navigate("/");
        } else {
          setErrMsg("Invalid Username Or Password")
        }
      } catch (err) {
        setErrMsg("Invalid Username Or Password")
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;
          if (typeof errorData.error === 'string') { // Single error message   
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    } else {
      console.log('Form contains errors:', errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });


    setFormData({
      ...formData,
      [name]: value,
    });
  };








  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='login-page'>
        <div>
          <Link to="/">
            <img src={Logo} alt='logo' className=' login-page-img width-25vw' /></Link>
        </div>
        <div className='login-page-right width-35vw'>
          <div className='login-page-second-heading   font-weight600'>Reset Password</div>


          <form onSubmit={handleSubmit} className="register-form">

            <div className="flex-column">

              <label htmlFor="new_password" className='form-labels'>New Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="new_password"
                  name="new_password"
                  onChange={handleInputChange
                  }
                  value={formData.new_password}

                  className={`form-input ${errors.new_password ? 'error' : inputState.new_password ? 'success' : ''}`}
                />
                {errors.new_password && (
                  <span className="error-message font-size-text ">{errors.new_password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                   {showPassword ? <EyeBottonInput/> : <Eyebuttonwithline/>}
                </button>
              </div>
            </div>
            <div className="flex-column">

              <label htmlFor="confirm_new_password" className='form-labels'>Confirm New Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="confirm_new_password"
                  name="confirm_new_password"
                  onChange={handleInputChange
                  }
                  value={formData.confirm_new_password}

                  className={`form-input ${errors.confirm_new_password ? 'error' : inputState.confirm_new_password ? 'success' : ''}`}
                />
                {errors.confirm_new_password && (
                  <span className="error-message font-size-text ">{errors.confirm_new_password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeBottonInput/> : <Eyebuttonwithline/>}
                </button>
              </div>
            </div>
            <div>
              <button type="submit" className="login-button font-weight600  font-size-heading" disabled={loading}>
                Update Password
              </button></div>
            <p
              style={{ color: "red" }}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

          </form>

        </div>
      </div>
    </>
  )
}

export default Resetpassword