import ReactToPrint from 'react-to-print';
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { formatDateDD_MM_YYYY, formattedDateLong } from "../Date";
import { BASE_URL } from "../../config/axios";
import { AddProjectBill, AddEOTDetails, AddCOSDetails, AddMilstoneDetails, AddTrackRecordDetails, UpdateTrackRecordDetails, AddProgressdetails, DeleteBillingDetails, UpdateProjectBill, DeleteBg, DeleteInsurance, AddInsurance, AddBankGuarantee, UpdateContractor, DeleteContractor, UpdateBankGuarantee, UpdateBank, UpdateInsurance, UpdateEOTDetails, DeleteTrackAndLetterDetails, UpdateTrackRecordDetailwithIDonly } from "./ProjectDetailsCrudButtons";



import { AddSiteEmployee, UpdateSiteEmployee, DeleteSiteEmployee } from "../Wfm/WorkforceCrudButton";


import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import usePermission from "../../config/permissions";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { formatCurrencyIndian, sortProjects, handleErrorToast, formatRoundoffCrores, formatRoundoff } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, Search, SearchSmall } from '../AllSvg';
import { LetterStatus, LetterType } from '../FilterDropdown';


const ConsultancyDetails = ({ projectId, projectData, getProjects, buffer }) => {

  const { siteRole } = usePermission();

  return (
    <>

      <div className="table-css-white-background">
        <div>
          <div className="jd-heading-outer-flex ">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Consultant Details</h2>
            <div className="field-cont">

            </div>
            {/* button here */}
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <div className="info-box-div">
          <div className="personal-detail">

            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id ? projectData?.tender_id : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead ? projectData?.lead : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv ? projectData?.jv : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Our Share (%)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.our_share ? projectData?.our_share : "-"}</th>
                </tr >


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Our Role</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.our_role ? projectData?.our_role : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association ? projectData?.association : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Work Type</td>
                  <th className="personal-detail-2 font-weight500 font-size-text form-text-trasformation-alluppercase">{projectData?.work_type ? projectData?.work_type : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contract Mode</td>
                  <th className="personal-detail-2 font-weight500 font-size-text form-text-trasformation-alluppercase ">{projectData?.contract_mode ? projectData?.contract_mode : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Authority Engineer</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.concern_person_name ? projectData?.concern_person_name : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Length (in Km)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_length ? projectData?.project_length : "-"}</th>
                </tr >
                <tr>

                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_duration ? projectData?.project_duration : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {formatRoundoffCrores(projectData?.consultancy_fees)}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Client</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.client_name ? projectData?.client_name : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Regional Office Name (RO Name)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.ro ? projectData?.ro : "-"}</th>
                </tr >
              </table >
            }
          </div>
          <div className="personal-detail">
            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Bid Due Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.bid_due_date ? formattedDateLong(projectData?.bid_due_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultant LOA Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.loa_award_date ? formattedDateLong(projectData?.loa_award_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Commencement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.commencement_date ? formattedDateLong(projectData?.commencement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.schedule_construction_completion_date ? formattedDateLong(projectData?.schedule_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_construction_completion_date ? formattedDateLong(projectData?.actual_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.schedule_completion_date ? formattedDateLong(projectData?.schedule_completion_date) : "-"}</th>
                </tr >
                {/* <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr > */}
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.employees?.length}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "consultant").length}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark ? projectData?.remark : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Location (State)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location ? projectData?.location : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Site Sub Company</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.sub_company ? projectData?.sub_company : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Site Address</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.site_office_address ? projectData?.site_office_address : "-"}</th>
                </tr >
              </table >
            }
          </div >

        </div >

        <div>
          <div>
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Insurance</h2>
              <div className="field-cont">
                <AddInsurance project={projectId} getProjects={getProjects} />
              </div>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            <br />
          </div>

          <>
            <div className="table-css-white-background ">
              <table className="table-css ">
                <thead className=''>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">S.No.</th>
                    <th className="align-center">Policy Number</th>
                    <th className="align-center">Insurance Name</th>
                    <th className="align-center">Amount</th>
                    <th className="align-center">Start Date </th>
                    <th className="align-center">Valid Upto</th>
                    <th className="align-center">File</th>
                    <th className="align-right">Action</th>
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner"></div>
                ) : (
                  <tbody>
                    {projectData?.insurance
                      .map((billData, monthIndex) => (
                        <React.Fragment key={monthIndex}>
                          <tr className="custom-table-head-td">
                            <td className="align-center">{monthIndex + 1}</td>
                            <td className="align-center">{billData.insurance_policy_num}</td>
                            <td className="align-center">{billData.insurance_name}</td>
                            <td className="align-center">{billData.insurance_amount}</td>
                            <td className="align-center">{formattedDateLong(billData.insurance_policy_start_date)}</td>
                            <td className="align-center">{formattedDateLong(billData.insurance_policy_valid_upto_date)}</td>
                            <td className="align-left ">
                              <a href={billData.insurance_file_upload} target="blank" className="document-download-button  width-5vw  width-5vw">
                                View
                              </a>
                            </td>
                            {siteRole === "SiteManagement" ?
                              <td className="align-right">
                                <UpdateInsurance i={billData} getProjects={getProjects} />
                              </td> :
                              <td className="align-right">
                                <UpdateInsurance i={billData} getProjects={getProjects} />
                                <DeleteInsurance i={billData} getInsuranceData={getProjects} />
                              </td>
                            }
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                )}
              </table>
            </div>

          </>
        </div>

        <div>
          <div>
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Bank / Performance Guarantee</h2>
              <div className="field-cont">
                <AddBankGuarantee project={projectId} getbg={getProjects} />
              </div>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            <br />
          </div>

          <>
            <div className="table-css-white-background ">
              <table className="table-css ">
                <thead className=''>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">S.No.</th>
                    <th className="align-center">Bank Name</th>
                    <th className="align-center">Bank Number</th>
                    <th className="align-center">Amount</th>
                    <th className="align-center">Start Date</th>
                    <th className="align-center">Expiry Date</th>
                    <th className="align-center">Remarks</th>
                    <th className="align-center">Document </th>
                    <th className="align-right">Action</th>
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner"></div>
                ) : (
                  <tbody>
                    {projectData?.bgdetails
                      .map((billData, monthIndex) => (
                        <React.Fragment key={monthIndex}>
                          <tr className="custom-table-head-td">
                            <td className="align-center">{monthIndex + 1}</td>
                            <td className="align-center">{billData.bank_name}</td>
                            <td className="align-center">{billData.bg_num}</td>
                            <td className="align-center">{billData.bg_amount}</td>

                            <td className="align-center">{formattedDateLong(billData.bg_date)}</td>
                            <td className="align-center">{formattedDateLong(billData.bg_expiry_date)}</td>

                            <td className="align-center">{billData.bg_remark}</td>
                            <td className="align-left ">
                              <a href={billData.bg_document} target="blank" className="document-download-button  width-5vw  width-5vw">
                                View
                              </a>
                            </td>

                            {siteRole === "SiteManagement" ?
                              <td className="align-right">
                                <UpdateBankGuarantee i={billData} getbg={getProjects} />

                              </td> :
                              <td className="align-right">
                                <UpdateBankGuarantee i={billData} getbg={getProjects} />
                                <DeleteBg i={billData} getbg={getProjects} />
                              </td>
                            }


                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                )}
              </table>
            </div>

          </>
        </div>


      </div>
    </>
  )
}

const ContractorDetails = ({ contractor }) => {

  const { siteRole } = usePermission();

  const [buffer, setBuffering] = useState(false);
  const [consultancyDetailsDetails, setConsultancyDetailsData] = useState([]);


  useEffect(() => {

    if (contractor) {
      getConsultancyDetails(contractor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  const getConsultancyDetails = async (contractor) => {
    setBuffering(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/contractor/${contractor}/`
      );
      setConsultancyDetailsData(response.data);
    } catch (err) {
    } finally {
      setBuffering(false);
    }
  };



  const formatRoundoff = (amount) => {
    if (amount === null || amount === undefined) return "-";

    // Function to format the number with Indian number system
    const formatIndianStyle = (num) => {
      let numStr = num.toString();
      let lastThreeDigits = numStr.slice(-3);
      let otherDigits = numStr.slice(0, -3);

      if (otherDigits) {
        lastThreeDigits = ',' + lastThreeDigits;
      }

      // Split other digits into groups of two and add commas
      const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

      return formattedOtherDigits + lastThreeDigits;
    };



    return formatIndianStyle(amount)
  };



  return (
    <>

      <div className="table-css-white-background">

        <div className="heading-bg-color-white">
          <div className="jd-heading-outer-flex ">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Contractors</h2>
            <div className="field-cont">
              {siteRole === "SiteManagement" ?

                <UpdateContractor contractor={contractor} getContractorDetails={getConsultancyDetails} projectId={consultancyDetailsDetails?.project} i={consultancyDetailsDetails} />

                :
                <>
                  <UpdateContractor contractor={contractor} getContractorDetails={getConsultancyDetails} projectId={consultancyDetailsDetails?.project} i={consultancyDetailsDetails} />
                  <DeleteContractor contractor={contractor} getProjectContractorDetails={getConsultancyDetails} />
                </>
              }
            </div>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>


        <div className="info-box-div">
          <div className="personal-detail">

            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Name</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.name ? consultancyDetailsDetails?.name : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor cost</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {consultancyDetailsDetails?.cost ? formatRoundoff(consultancyDetailsDetails.cost) : "-"}</th>
                </tr >


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Length (in Km)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_length ? consultancyDetailsDetails?.project_length : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_duration ? consultancyDetailsDetails?.project_duration : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration With EOT (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_duration_with_eot ? consultancyDetailsDetails?.project_duration_with_eot : "-"}</th>
                </tr >

                {/* <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees with COS (in Crs.)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {formatRoundoffCrores(consultancyDetailsDetails?.cost_with_cos)}</th>
                </tr > */}


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.eots ? consultancyDetailsDetails?.eots?.filter(eot => eot.workperformedby === "consultant").length : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">COS of Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.employees ? consultancyDetailsDetails?.employees?.length : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor address</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.contractor_address ? consultancyDetailsDetails?.contractor_address : "-"}</th>
                </tr >



              </table >
            }
          </div >
          <div className="personal-detail">
            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Bid Due Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.contractor_bid_due_date ? formattedDateLong(consultancyDetailsDetails?.contractor_bid_due_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor LOA Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.loa_award_date ? formattedDateLong(consultancyDetailsDetails?.loa_award_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Agreement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.agreement_date ? formattedDateLong(consultancyDetailsDetails?.agreement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Commencement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.commencement_date ? formattedDateLong(consultancyDetailsDetails?.commencement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.schedule_construction_completion_date ? formattedDateLong(consultancyDetailsDetails?.schedule_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.actual_construction_completion_date ? formattedDateLong(consultancyDetailsDetails?.actual_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Schedule Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.schedule_completion_date ? formattedDateLong(consultancyDetailsDetails?.schedule_completion_date) : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Actual Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.actual_completion_date ? formattedDateLong(consultancyDetailsDetails?.actual_completion_date) : "-"}</th>
                </tr >



              </table >
            }
          </div >




        </div >
      </div>

    </>
  )
}

const BillTable = ({ projectId, contractor, contractorlist }) => {


  const { siteRole } = usePermission();

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [bills, setBills] = useState([]);
  const getProjectBillDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const bill = await axios.get(
        `${BASE_URL}/siteproject/billingbycontractor/${contractor}/`
      );
      setBills(bill.data);



    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString("default", { month: "long" });
  };

  useEffect(() => {
    getProjectBillDetails();

  }, [contractor]);

  return (

    <div className="table-css-white-background attendance-history-cont">

      <div className="jd-heading-outer-flex ">
        <h2 className="jd-heading-main font-weight600  font-size-heading">Contractor Bills</h2>
        {
          contractor === undefined ? <></>
            :
            <>
              <div className="field-cont">
                <AddProjectBill project={projectId} getProjects={getProjectBillDetails} contractor={contractor} contractorlist={contractorlist} />
              </div>
            </>
        }

      </div>
      <div>
        <div className="jd-heading-bottom-bold"></div>
        <div className="jd-heading-bottom-light"></div>
      </div>

      <div className='table-box height-40vh paddingTop-0px '>
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="table-heading-text">Period</th>
              <th className="table-heading-text">Bill Details</th>
              <th className="table-heading-text ">Submited Details</th>
              <th className="table-heading-text">Site Recommended Details</th>
              <th className="table-heading-text">Site Deduction Details</th>

              <th className="table-heading-text">HO Recommended Details</th>
              <th className="table-heading-text">HO Deduction Details</th>
              {/* <th className="table-heading-text">Bill Date</th> */}
              <th className="table-heading-text">Letters</th>
              <th className="table-heading-text">Action</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {bills
                .map((billData, monthIndex) => (
                  <tr className="custom-table-head-td" key={monthIndex}>

                    <td className="table-body">{monthIndex + 1}</td>
                    <td className="table-body text-center">{formatDateDD_MM_YYYY(billData.bill_period_start)}<br />To  <br />{formatDateDD_MM_YYYY(billData.bill_period_end)}</td>

                    <td className="table-body">
                      <tr>
                      <td className="padding-bottom-5px">Number</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">{billData.bill_num}</td>
                      </tr>
                      <tr>
                      <td className="padding-bottom-5px">Type</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">{billData.bill_type}</td>
                      </tr>
                      <tr>
                      <td className="padding-bottom-5px">Catergory</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">{billData.bill_catagory}</td>
                      </tr>
                    </td>

                    <td className="table-body ">
                    <tr>
                      <td className="padding-bottom-5px">Date</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">{formatDateDD_MM_YYYY(billData.submitted_bill_date)}</td>
                      </tr>
                    <tr>
                      <td className="padding-bottom-5px">       
                        Amount                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">₹ {formatCurrencyIndian(billData.submitted_bill_amount)}</td>
                      </tr>
                    
                    </td>
                    <td className="table-body ">
                    <tr>
                      <td className="padding-bottom-5px">       
                        Date                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">{formatDateDD_MM_YYYY(billData.site_recomended_bill_date)}</td>
                      </tr>
                    <tr>
                      <td className="padding-bottom-5px">       
                      Amount                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">₹ {formatCurrencyIndian(billData.site_recommended_bill_amount)}</td>
                      </tr>
                    <tr>
                      <td className="padding-bottom-5px">       
                      Percentage                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      {/* <td className="align-right">{billData.site_recommended_percentage}%</td> */}
                      <td className="align-right">{((Number(billData.site_recommended_bill_amount)/Number(billData.submitted_bill_amount))*100).toFixed(0)}%</td>
                      </tr>
                      <div className="flex-column">
                        {billData.site_attachment && <span className="align-leftt "> SO Attachment
                          <a href={billData.site_attachment} target="blank" className="document-download-button  width-5vw">
                            View
                          </a>
                        </span>}
                      </div>
                    </td>

                    <td className="table-body width-15vw">
                      <tr>
                      <td className="padding-bottom-5px">       
                      LD Amount                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">₹{formatCurrencyIndian(billData.site_ld_amount?billData.site_ld_amount:"-")}</td>
                      </tr>
                      <tr>
                      <td className="padding-bottom-5px">       
                      Withheld Amount                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">₹{formatCurrencyIndian(billData.site_released_withheld_amt?billData.site_released_withheld_amt:"-")}</td>
                      </tr>
                      <tr>
                      <td className="padding-bottom-5px">       
                      Released Withheld Amount                 
                      </td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="align-right">₹{formatCurrencyIndian(billData.site_released_withheld_amt?billData.site_released_withheld_amt:"-")}</td>
                      </tr>
                    </td>

                    <td className="table-body ">
                      <div className="flex-column">
                        <span className="align-leftt">Date:{formatDateDD_MM_YYYY(billData.recomended_bill_date)}</span>
                        <span className="align-leftt">Amount: Rs. {formatCurrencyIndian(billData.recommended_bill_amount)}</span>
                        <span className="align-leftt">Percentage: {billData.recommended_percentage}%</span>
                        {billData.ho_attachment && <span className="align-leftt "> HO Attachment
                          <a href={billData.ho_attachment} target="blank" className="document-download-button  width-5vw">
                            View
                          </a>
                        </span>}
                      </div>
                    </td>


                    <td className="table-body width-15vw">
                      <div className="flex-column">
                        <span className="align-leftt">LD Amount: {billData.ld_amount}</span>
                        <span className="align-leftt">Withheld Amount: Rs. {(billData.withheld_amt)}</span>
                        <span className="align-leftt">Released Withheld Amount: Rs. {formatCurrencyIndian(billData.released_withheld_amt)}</span>
                      </div>
                    </td>



                    {/* <td className="table-body">{getMonthName(billData.month)}-{billData.year}</td> */}
                    <td className='flex-row'>
                      Submitted Letter : <UpdateTrackRecordDetailwithIDonly
                        i={billData.submitted_letter_num}
                        projectId={projectId}

                      />
                    </td>
                    <td className="table-body width-5vw">
                      <div className="flex-row justify-center">
                        <UpdateProjectBill i={billData} project={projectId} getProjects={getProjectBillDetails} />
                        {siteRole === "Account" ? "" :
                          <DeleteBillingDetails i={billData} getbillingDetail={getProjectBillDetails} />
                        }
                      </div>
                    </td>




                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>

    </div>
  );
}


const BillingDetailsTable = ({ projectId }) => {
  const [buffer, setBuffering] = useState(false);
  const [billingDetails, setBillingData] = useState([]);
  const ref = useRef();

  const getProjectBillDetails = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/billingdetailfilter/${projectId}/`
      );
      setBillingData(response.data);
    } catch (err) {
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    getProjectBillDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedDateLong = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderBillsAndBalanceBills = (contractorData) => {
    const { bill, balancebill } = contractorData;

    // Handle cases when only one of bill or balancebill exists
    const maxLength = Math.max(bill.length, balancebill.length);
    const rows = [];

    for (let i = 0; i < maxLength; i++) {
      const billDetails = bill[i] || {};
      const balanceBillDetails = balancebill[i] || {};

      rows.push(
        <tr key={i}>
          <td>Bill No.</td>
          <td>{`${billDetails.bill_type || ""}-${billDetails.bill_num || ""} (${billDetails.submitted_percentage || ""}%), Letter No. ${billDetails.submitted_letter_num || "-"}`}</td>
          <td>{`${billDetails.bill_type || ""}-${billDetails.bill_num || ""} (${billDetails.recommended_percentage || ""}%), Letter No. ${billDetails.recomended_letter_num || "-"}`}</td>
          <td>{`${balanceBillDetails.bill_type || ""}-${balanceBillDetails.bill_num || ""} (${balanceBillDetails.submitted_percentage || ""}%), Letter No. ${balanceBillDetails.submitted_letter_num || "-"}`}</td>
          <td>{`${balanceBillDetails.bill_type || ""}-${balanceBillDetails.bill_num || ""} (${balanceBillDetails.recommended_percentage || ""}%), Letter No. ${balanceBillDetails.recomended_letter_num || "-"}`}</td>
        </tr>,
        <tr key={`date-${i}`}>
          <td>Bill Date</td>
          <td>{formattedDateLong(billDetails.submitted_bill_date) || "-"}</td>
          <td>{formattedDateLong(billDetails.recomended_bill_date) || "-"}</td>
          <td>{formattedDateLong(balanceBillDetails.submitted_bill_date) || "-"}</td>
          <td>{formattedDateLong(balanceBillDetails.recomended_bill_date) || "-"}</td>
        </tr>,
        <tr key={`period-${i}`}>
          <td>Bill Period</td>
          <td>
            {billDetails.bill_period_start && billDetails.bill_period_end
              ? `${formattedDateLong(billDetails.bill_period_start)} - ${formattedDateLong(billDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {billDetails.bill_period_start && billDetails.bill_period_end
              ? `${formattedDateLong(billDetails.bill_period_start)} - ${formattedDateLong(billDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {balanceBillDetails.bill_period_start && balanceBillDetails.bill_period_end
              ? `${formattedDateLong(balanceBillDetails.bill_period_start)} - ${formattedDateLong(balanceBillDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {balanceBillDetails.bill_period_start && balanceBillDetails.bill_period_end
              ? `${formattedDateLong(balanceBillDetails.bill_period_start)} - ${formattedDateLong(balanceBillDetails.bill_period_end)}`
              : "-"}
          </td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>Bill Amt.</td>
          <td>{formatRoundoffCrores(billDetails.submitted_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(billDetails.recommended_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(balanceBillDetails.submitted_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(balanceBillDetails.recommended_bill_amount || "-")}</td>
        </tr>,

        <tr key={`amount-${i}`}>
          <td>Withheld Amt.</td>
          <td>{formatCurrencyIndian(billDetails.withheld_amt || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.withheld_amt || "-")}</td>
          <td>{"-"}</td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>Released withheld Amt</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(billDetails.released_withheld_amt || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.released_withheld_amt || "-")}</td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>LD Amt</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(billDetails.ld_amount || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.ld_amount || "-")}</td>
        </tr>
        ,
        <tr key={`amount-${i}`}>
          <td>Deduction Amt</td>
          <td>{formatCurrencyIndian(billDetails.deduction_amount || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.deduction_amount || "-")}</td>
          <td>{"-"}</td>
        </tr>

      );
    }
    return rows;
  };

  return (
    <div ref={ref} className="table-css-white-background">
      <div className="heading-bg-color-white">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600 font-size-heading">Contractor Billing Data</h2>
          <div className="field-cont">
            <ReactToPrint
              bodyClass="print-agreement"
              content={() => ref.current}
              trigger={() => (
                <button className="model-button model-button-black hide-on-print">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                    <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                  </svg> Print
                </button>
              )}
            />
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div  >
        <table className="nested-table-lightblue">
          <thead >
            <tr className="custom-table-head-tr ">
              <th className="align-left">Month</th>
              <th className="align-left">Contractor</th>
              <th className="align-center">Details</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {billingDetails.map((billingData, yearIndex) => {
                let totalRowsForMonth = 0;

                billingData.contractors.forEach(contractor => {
                  totalRowsForMonth += Math.max(contractor.bill.length, contractor.balancebill.length);
                });

                return (
                  <React.Fragment key={yearIndex}>
                    {billingData.contractors.map((contractorData, contractorIndex) => (
                      <tr className="custom-table-head-td" key={contractorIndex}>
                        {contractorIndex === 0 && (
                          <td className="align-left" rowSpan={totalRowsForMonth}>
                            {formattedDateLong(billingData.bill_period_start)}-{formattedDateLong(billingData.bill_period_end)}
                          </td>
                        )}
                        <td className="align-left">{contractorData.contractor}</td>
                        <td className="align-center">
                          {contractorData.bill.length || contractorData.balancebill.length ? (
                            <table className="nested-table font-size-subheading">
                              <thead>
                                <tr>
                                  <th rowSpan="2" className="align-center">Project Details</th>
                                  <th colSpan="2" className="align-center">Bill</th>
                                  <th colSpan="2" className="align-center">Balance Bill</th>
                                </tr>
                                <tr>
                                  <th>Submitted</th>
                                  <th>Recommended (%)</th>
                                  <th>Bal Submitted</th>
                                  <th>Bal Recommended (%)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderBillsAndBalanceBills(contractorData)}
                              </tbody>
                            </table>
                          ) : (
                            <p>No Billing Data Available.</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};



const EOTTable = ({ projectId, toggleState, contractor, contractorlist }) => {
  const { siteRole } = usePermission();
  const [eotList, setEotList] = useState([]);



  const getEot = async () => {

    try {
      let eot = await axios.get(`${BASE_URL}/siteproject/eotfilter/${projectId}/${toggleState}/${contractor}/null/`);
      setEotList(eot.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };


  useEffect(() => {
    getEot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, projectId, toggleState]);

  const handleEotDelete = async (e, i, getEot) => {
    e.preventDefault();


    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/eot/${i.id}/`);

      if (res.status === 200) {
        await getEot();
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (

    <div className="table-css-white-background ">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Extention of Time</h2>
          {
            contractor === undefined ?
              <></>
              :
              <>
                <div className="field-cont">

                  <AddEOTDetails project={projectId} getEot={getEot} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />
                </div>
              </>
          }

        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box paddingTop-0px height-40vh">
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">EOT Code</th>
              <th className="align-center">Initiate Date</th>

              <th className="align-center">Approved Date</th>
              <th className="align-center">Approved Duration</th>
              <th className="align-center">Initiate Letter</th>
              {siteRole === "SiteManagement" ? "" :
                <th className="align-right width-5vw">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {eotList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.eot_code}</td>
                  <td className="align-center form-text-trasformation-uppercase">
                    {i.process_initiate_date ? formattedDateLong(i.process_initiate_date) : "-"}
                  </td>
                  <td className="align-center form-text-trasformation-uppercase">
                    {i.approved_date ? formattedDateLong(i.approved_date) : "-"}
                  </td>
                  <td className="align-center">{i.approved_days}</td>
                  <td className="align-center">{i.letter_num}</td>

                  {siteRole === "SiteManagement" ? "" :
                    <td className="align-right width-5vw flex-row " >
                      <UpdateEOTDetails i={i} getEot={getEot} />
                      <button className="model-delete-button"
                        onClick={(e) => handleEotDelete(e, i, getEot)}
                      >
                        <DeleteDustbin />
                      </button>
                    </td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  )


}


const COSTable = ({ projectId, toggleState, contractorlist, contractor }) => {
  const [cosList, setCosList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const { siteRole } = usePermission();


  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let cos = await axios.get(`${BASE_URL}/siteproject/cosfilter/${projectId}/${toggleState}/${contractor}/null/`);
      setCosList(cos.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  const handleCosDelete = async (e, i, getProjects) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/cos/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };


  return (

    <div className="table-css-white-background">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Change of Scope</h2>
          <>

            {
              contractor === undefined ?
                <></>
                :
                <>
                  <div className="field-cont">

                    <AddCOSDetails project={projectId} contractor={contractor} getProjects={getProjects} toggleState={toggleState} />
                  </div>
                </>
            }

          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box paddingTop-0px height-40vh">
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">COS Code</th>
              <th className="align-center">Initiate Date</th>
              <th className="align-center">Approval Date</th>
              <th className="align-center">Amount</th>
              <th className="align-center">By</th>
              {siteRole === "SiteManagement" ? "" :
                <th className="align-right width-5vw">Action</th>
              }

            </tr>
          </thead>
          <tbody>
            {cosList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.cos_code}</td>
                  <td className="align-center">
                    {i.process_initiate_date ? formattedDateLong(i.process_initiate_date) : "-"}
                  </td>
                  <td className="align-center">
                    {i.approval_date ? formattedDateLong(i.approval_date) : "-"}
                  </td>

                  <td className="align-center">{i.amount}</td>
                  <td className="align-center">{i.workperformedby}</td>
                  {siteRole === "SiteManagement" ? "" :
                    <td className="align-right width-5vw ">
                      <button className="model-delete-button"
                        onClick={(e) => handleCosDelete(e, i, getProjects)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                            fill="#F72B50"
                          />
                        </svg>
                      </button>
                    </td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

    </div >
  )


}





const EmployeeTable = ({ projectId, companyId }) => {

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const { siteRole } = usePermission();


  const [siteEmployeeList, setSiteEmployeeList] = useState([]);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [siteProjectList, setSiteProjectList] = useState([]);

  const getSiteEmployee = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${projectId}/null/`);
      const dep = await axios.get(`${BASE_URL}/siteproject/designation/`);
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            // for general(admin)
            : `${BASE_URL}/siteproject/project/`
      );

      setSiteEmployeeList(res.data);
      setSiteDepartmentList(dep.data);
      setSiteSubCompanyList(sub.data);
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setSiteProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getSiteEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className="table-css-white-background">
      <div>
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Employee</h2>
          <div className="field-cont">
            {/* <div className="table-searchh dropdown-container field-cont-div">
              <select
                onChange={(e) => setToggleShow(e.target.value)}
                value={toggleShow}
                className="dropdown font-size-subheading  ">
                <option value="null">All</option>
                <option value="Contractor">Contractor</option>
                <option value="Consultant">Consultant</option>
              </select>
            </div> */}
            {siteRole === "Account" ? "" :
              <AddSiteEmployee
                projectId={projectId}
                getSiteEmployee={getSiteEmployee}
                siteProjectList={siteProjectList}
                siteDepartmentList={siteDepartmentList}
                siteSubCompanyList={siteSubCompanyList}
                companyId={companyId}


              />
            }
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box height-40vh paddingTop-0px ">
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="align-left">ID</th>
              <th className="align-center">Employee</th>
              <th className="align-center">Email</th>
              <th className="align-center">Mobile</th>
              <th className="align-center">Designation</th>
              <th className="align-center">Proffesional</th>
              <th className="align-center">Address</th>
              <th className="align-center">Join Date</th>
              <th className="align-center">Sub Company</th>
              {siteRole === "Account" ? "" :
                <th className="align-right width-5vw flex-row justify-center">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {siteEmployeeList?.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.emp_code}-{i.name}</td>
                  <td className="align-center">{i.email}</td>
                  <td className="align-center">{i.mobile}</td>
                  <td className="align-center">{i.designation_name}</td>
                  <td className="align-center">{i.professional_type}</td>
                  <td className="align-center">{i.current_address}</td>
                  <td className="align-center">{formattedDateLong(i.joining_date)}</td>
                  <td className="align-center">{i.sub_company_name}</td>

                  {siteRole === "Account" ? "" :
                    <td className="align-right flex-row justify-center ">
                      <UpdateSiteEmployee
                        i={i}
                        getSiteEmployee={getSiteEmployee}
                        siteProjectList={siteProjectList}
                        siteDepartmentList={siteDepartmentList}
                        siteSubCompanyList={siteSubCompanyList}
                        projectId={projectId}
                      />
                      {siteRole === "BD" ? "" :
                        <DeleteSiteEmployee
                          i={i}
                          getSiteEmployee={getSiteEmployee}
                        />
                      }
                    </td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  )

}

const MilestoneTable = ({ projectId, contractor }) => {

  const [projectData, setProjectsData] = useState({});
  const [milestoneList, setMilestoneList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const { siteRole } = usePermission();

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let mil = await axios.get(`${BASE_URL}/siteproject/milestonebycontractor/${contractor}/`);
      let proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);

      setProjectsData(proj.data);

      setMilestoneList(mil.data);
    } catch (err) {
      console.log("err")
      console.log(err)
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleShow, projectId, contractor]);

  const handleCosDelete = async (e, i, getProjects) => {
    e.preventDefault();
    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/milestone/${i.id}/`);

      if (res.status === 200) {
        await getProjects();
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  };

  const calculateDays = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";

    const start = new Date(startDate);
    const end = new Date(endDate);


    const timeDifference = end - start;


    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };
  console.log("contractor")
  console.log(contractor)
  return (

    <div className="table-css-white-background">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading"> Contractor Milestone </h2>
          <>
            {
              contractor === undefined ?
                <></>
                :
                <>
                  <div className="field-cont">



                    <AddMilstoneDetails project={projectId} getProjects={getProjects} contractor={contractor} appointed_date={projectData.commencement_date} />
                  </div>
                </>
            }

          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
        <br />
      </div>

      <div className="form-flex-wrap table-box height-40vh paddingTop-0px ">
        <table className="table-css ">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="align-left">S. No.</th>
              <th className="align-center">Name</th>
              <th className="align-center">Appointed Date</th>
              <th className="align-center">Milestone Date</th>
              <th className="align-center">Actual Milestone Date</th>
              <th className="align-center">Reschedule Milestone Date</th>
              <th className="align-center">Milestone Days</th>
              <th className="align-center">Actual Gap Days</th>
              <th className="align-center">Reschedule Gap Days</th>
              <th className="align-center">Work Performmed By</th>
              {siteRole === "SiteManagement" ?
                ""
                : <th className="align-right width-5vw">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {milestoneList?.sort(
              (a, b) =>
                new Date(a.milestone_date) - new Date(b.milestone_date)
            )
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.name}</td>

                    <td className="align-center text-center">{projectData.commencement_date ? formattedDateLong(projectData.commencement_date) : "-"}</td>
                    <td className="align-center">{i.milestone_date ? formattedDateLong(i.milestone_date) : "-"}</td>
                    <td className="align-center"> {i.milestone_as_per_actual ? formattedDateLong(i.milestone_as_per_actual) : "-"}  </td>
                    <td className="align-center">  {i.milestone_as_per_reschedule ? formattedDateLong(i.milestone_as_per_reschedule) : "-"}</td>

                    <td className="align-center">{i.day}</td>
                    <td className="align-center">{calculateDays(projectData.commencement_date, i.milestone_as_per_actual)}</td>
                    <td className="align-center">{calculateDays(projectData.commencement_date, i.milestone_as_per_reschedule)}</td>

                    <td className="align-center">{i.workperformedby}</td>
                    {
                      siteRole === "SiteManagement" ?
                        ""
                        :
                        <td className="align-right width-5vw ">
                          <button className="model-delete-button"
                            onClick={(e) => handleCosDelete(e, i, getProjects)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                                fill="#F72B50"
                              />
                            </svg>
                          </button>
                        </td>
                    }
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>

    </div>
  )


}

const Progress = ({ projectId, contractor }) => {
  const [progress, setProgress] = useState([]);
  const [buffer, setBuffering] = useState(false); // Buffering logic
  const [loading, setLoading] = useState(false); // Loading logic

  const getProgress = async () => {
    setBuffering(true); // Start buffering

    try {
      const response = await axios.get(`${BASE_URL}/siteproject/progressbyproject/${projectId}/${contractor}/`);


      const sortedData = response.data.sort((a, b) => {
        if (a.year === b.year) {
          return b.month - a.month;
        }
        return b.year - a.year;
      });
      setProgress(sortedData);
    } catch (err) {
      console.log("err");
      console.log(err);
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End buffering
    }
  };
  console.log(projectId)
  useEffect(() => {
    getProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, contractor]);


  const handleProgressDelete = async (e, i, getProgress) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/progressupdate/${i.id}/`);

      if (res.status === 200) {
        await getProgress();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  }

  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNumber - 1];
  };

  return (
    <>
      <div className="table-css-white-background">
        <div>
          <div className="jd-heading-outer-flex">
            <h2 className="jd-heading-main font-weight600 font-size-heading">Site Progress</h2>
            <div className="field-cont">
              <AddProgressdetails project={projectId} progress={progress[0]} getprogressDetails={getProgress} />
            </div>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <div className="form-flex-wrap">
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Month</th>
                <th className="align-center">Year</th>
                <th className="align-center">Physical Progress (%)</th>
                <th className="align-center">Financial Progress (%)</th>
                <th className="align-right width-5vw">Action</th>
              </tr>
            </thead>
            <tbody>
              {progress.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{getMonthName(i.month)}</td>
                    <td className="align-center">{i.year}</td>
                    <td className="align-center">{i.physical_progress}%</td>
                    <td className="align-center">{i.financial_progress}%</td>
                    <td className="align-right width-5vw ">
                      <button className="model-delete-button"
                        onClick={(e) => handleProgressDelete(e, i, getProgress)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                            fill="#F72B50"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};


const ProjectProgressBar = ({ data }) => {
  const renderCustomLabel = ({ x, y, width, height, value, index, dataKey }) => {
    const midX = x + width / 2;
    const midY = y + height / 2;
    const label = dataKey === 'Physical' ? 'Physical' : 'Financial';

    return (
      <text
        x={midX}
        y={midY}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        transform={`rotate(-90, ${midX}, ${midY})`}
        className="font-size-subheading"
      >
        {label}
      </text>
    );
  };


  return (
    <div style={{ padding: 0 }} className="width-5vw">
      <ResponsiveContainer height={200} width="100%">
        <BarChart
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 0,
          }} data={data}>
          <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
          <XAxis dataKey="month" />
          <Tooltip />
          <Bar label={{ position: 'top' }} legendType="line" dataKey="Physical" fill="#2576BC" barSize={20} radius={7} >
            <LabelList content={(props) => renderCustomLabel({ ...props, dataKey: 'Physical' })} />

          </Bar>
          <Bar label={{ position: 'top' }} legendType="line" dataKey="Financial" fill="#00dec7" barSize={20} radius={7} >
            <LabelList content={(props) => renderCustomLabel({ ...props, dataKey: 'Financial' })} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}






const LetterTracking = ({ projectId, letterBy, contractor, contractorlist }) => {
  const tableRef = useRef(null);
  const [toggleCompany, setToggleCompany] = useState("");
  const { siteRole } = usePermission();


  const [workperformedby, setWorkperformedby] = useState("null");

  const [lettertype, setLetterType] = useState("null");







  const [filteredData, setFilteredData] = useState([]);

  const [error, setError] = useState(null);

  const [selectedNo, setNo] = useState("");
  const [selectedFromDate, setFromDate] = useState('null');
  const [selectedToDate, setToDate] = useState('null');
  const [selectedLetterStatus, setLetterStatus] = useState('null');


  const [trackRecordList, setTrackRecordList] = useState([]);


  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {


      const res = await axios.get(`${BASE_URL}/siteproject/letterrecords/filter/${selectedLetterStatus}/${selectedFromDate}/${selectedToDate}/null/${projectId}/${letterBy ? letterBy : workperformedby}/${lettertype}/null/null/${contractor}/null/`);
      setTrackRecordList(res.data);


    } catch (err) {
      // handleErrorToast(err); // You can handle any general error here if needed
    } finally {
      setBuffering(false); // End Buffering
    }
  };


  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCompany, selectedLetterStatus, selectedFromDate, selectedToDate, workperformedby, lettertype, contractor, letterBy]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNo, trackRecordList]);

  const applyFilters = () => {
    let filteredData = trackRecordList;
    if (selectedNo !== "") {
      const lowercaseSelectedName = selectedNo.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const lid = employee.letter_num.toString().toLowerCase().includes(lowercaseSelectedName);
        return lid;
      });
    }


    setFilteredData(filteredData);
  };

  return (
    <>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div >



        <>
          <div>
            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
            <div className="attendance-subcontainer">
              <div className="field-cont">

                <LetterStatus selectedLetterStatus={selectedLetterStatus} setLetterStatus={setLetterStatus} />

                <LetterType setLetterType={setLetterType} lettertype={lettertype} />
                <div title='Search Filter' className="field-cont-div">
                  <SearchSmall />
                  <input className="attendance-input-field  width-10vw"
                    placeholder='Letter Id/No.'
                    type="text"
                    value={selectedNo}
                    onChange={(e) => setNo(e.target.value)} />

                  <hr className="field-cont-hr" />
                </div>
                {letterBy === undefined &&
                  <>
                    <div title="Letter For" className="field-cont-div">
                      <CircularGrid />
                      <select
                        onChange={(e) => setWorkperformedby(e.target.value)}
                        value={workperformedby}
                        className="attendance-input-field width-10vw   date-field"
                      >
                        <option value="null">Select Option</option>
                        <option value="contractor">Contractor</option>
                        <option value="consultant">Consultant</option>

                      </select>

                      <hr className="field-cont-hr" />
                    </div>
                  </>}



                <div title="From Date Filter" className="field-cont-div">
                  <input
                    className="attendance-input-field width-10vw   date-field"
                    placeholder="From Date"
                    type="date"
                    value={selectedFromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  <hr className="field-cont-hr" />
                </div>

                <div title="To Date Filter" className="field-cont-div ">
                  <input
                    className="attendance-input-field width-10vw   date-field"
                    placeholder="To Date"
                    type="date"
                    value={selectedToDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  <hr className="field-cont-hr" />
                </div>

              </div>


              <div className="btn-cont width-20vw float-right">
                {
                  contractor === undefined ?
                    <></>
                    :
                    <>
                      {siteRole === "BD" ? "" :
                        <AddTrackRecordDetails contractor={contractor} getProjects={getProjects} letterBy={letterBy} projectId={projectId} contractorlist={{ contractorlist }} />
                      }
                    </>
                }

                <DownloadTableExcel
                  filename="Site-ProjectDetails"
                  sheet="projectDetails"
                  currentTableRef={tableRef.current}
                >
                  <button className="model-button   font-weight500   model-button-print">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                      <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                      </g>
                    </svg>
                  </button>
                </DownloadTableExcel>

              </div>
            </div>
          </div>
          <div className='filter-showing'>
            {/* <div>Letter Records:</div> */}
            <div></div>
            <div>Showing {trackRecordList.length} of {trackRecordList.length} </div>
          </div>

          <div className="table-css-white-background height-40vh table-box paddingTop-0px" >
            <table ref={tableRef} className="table-css">
              <thead className='table-heading'>
                <tr className='custom-table-head-tr'>
                  <th className='align-left'>Letter No</th>
                  <th className='align-center'>Project</th>
                  <th className='align-center'>Letter Date.</th>
                  <th className='align-center'>Subject</th>
                  <th className='align-center'>Status</th>
                  {siteRole === "BD" ? "" :
                    <>
                      <th className='align-right width-5vw'>View/Edit</th>
                      <th className='align-right width-5vw'>Delete</th>
                    </>
                  }
                </tr>
              </thead>
              {buffer ? (
                <div className="spinner-small"> </div> //buffering logic 
              ) : error ? (
                <p>Error fetching data: {error.message}</p>
              ) :
                <tbody>
                  {filteredData.map((i, index) => (
                    <tr className="custom-table-head-td">
                      <td className='align-left'>{i.letter_num}</td>
                      <td className='align-center'>{i?.site_prcode}-{i.project_short_name}</td>
                      <td className='align-center'>{i.letter_date ? formattedDateLong(i.letter_date) : "---"}</td>
                      <td className='align-center'>{i.subject}</td>
                      <td className='align-center'>{i.status}</td>
                      {siteRole === "BD" ? "" :
                        <>
                          <td className='align-center'>
                            <UpdateTrackRecordDetails
                              i={i}
                              getProjects={getProjects}

                              projectId={projectId}
                            />

                          </td>
                          <td className='align-right'>
                            <DeleteTrackAndLetterDetails i={i} getLetterDetail={getProjects} />
                          </td>
                        </>}
                    </tr>
                  ))}
                </tbody>}
            </table>
          </div>
        </>
      </div>
    </>
  );
}


export {
  BillTable,
  BillingDetailsTable,
  EOTTable,
  COSTable,
  EmployeeTable,
  LetterTracking,
  // LetterTable,
  // VehiclesTable,
  MilestoneTable,
  ProjectProgressBar,
  Progress,
  ConsultancyDetails,

  ContractorDetails
};
