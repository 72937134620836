// config/permissions.js
import { useState, useEffect } from "react";
import { BASE_URL } from "./axios";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { handleErrorToast } from "../components/CustomFunctions";

const usePermission = () => {
  const [PERMISSION_ROLE, setPermissionRole] = useState(false);
  const [PERMISSION_AUTHORITY, setPermissionAuthority] = useState(false);
  const [PERMISSION_HR_ADMIN, setPermissionHRAdmin] = useState(false);
  const [SUB_COMPANIES, setSubCompanies] = useState([]);
  const [siteRole, setSiteRole] = useState(false);
  const projectID = sessionStorage.getItem("sitemanagement_project");
  const companyId = sessionStorage.getItem("sitemanagement_project_sub_company");

  const updatePermissions = () => {
    const role = sessionStorage.getItem("role");
    const sitemanagement_role = sessionStorage.getItem("sitemanagement_role");
    const code = sessionStorage.getItem("emp_code");
    setPermissionRole(sitemanagement_role === "HR" || sitemanagement_role === "Admin");
    setPermissionAuthority(sitemanagement_role === "Admin" || sitemanagement_role === "SuperAdmin");
    setPermissionHRAdmin(
      code === "CIPL1" || code === "CIPL6" || code === "CIPL10001"
    );
    setSiteRole(sitemanagement_role);

  };
  const getSubCompany = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/null/active/`
      );
      setSubCompanies(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    }
  };
  useEffect(() => {
    updatePermissions();
    
    const handleStorageChange = () => {
      updatePermissions();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(()=>{
    getSubCompany();
  },[]);

  return {
    PERMISSION_ROLE,
    PERMISSION_AUTHORITY,
    PERMISSION_HR_ADMIN,
    updatePermissions,
    SUB_COMPANIES,
    projectID,
    companyId,
    siteRole,
  };
};

export default usePermission;
