import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config/axios';
import { Modal } from 'react-bootstrap';
import { sortProjects } from '../CustomFunctions';
import { formattedDateLong, formatDate } from '../Date';
import { SearchSmall } from '../AllSvg';
import { UpdateTrackRecordDetails } from '../SiteManagement/ProjectDetailsCrudButtons';

// const ProjectByOurRoleModel = ({ project_number, our_role }) => {
//   const [show, setshow] = useState(false);
//   const handleClose = () => {
//     setshow(false);
//   };
//   const handleShow = () => {
//     setshow(true);
//     getProjectData()
//   };

//   const [searchQuery, setSearchQuery] = useState("");
//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };



//   const [projectdata, setProjectData] = useState([]);

//   const getProjectData = async () => {
//     try {
//       const res = await axios.get(
//         `${BASE_URL}/siteproject/project-by-our-role/${our_role}/`);

//       const sortedProjects = sortProjects(res.data, 'site_prcode')

//       setProjectData(sortedProjects);
//     } catch (err) { }
//   };





//   return (
//     <>
//       <div title='Project Details' style={{ cursor: 'pointer' }} onClick={handleShow}>
//         {project_number}
//       </div>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         dialogClassName="model-width60vw"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Project Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>


//           <div className="table-heading-flex">
//             <div></div>
//             <div className="flex-row">
//               <div className="table-searchh">
//                 <div>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="11"
//                     height="12"
//                     viewBox="0 0 11 12"
//                     fill="none"
//                   >
//                     <circle
//                       cx="5"
//                       cy="5"
//                       r="4.3"
//                       stroke="#2B3674"
//                       strokeWidth="1.4"
//                     />
//                     <line
//                       x1="10.0101"
//                       y1="11"
//                       x2="8"
//                       y2="8.98995"
//                       stroke="#2B3674"
//                       strokeWidth="1.4"
//                       strokeLinecap="round"
//                     />
//                   </svg>
//                 </div>
//                 <div>
//                   <input
//                     placeholder="Search by Project Name/ Authority Engineer"
//                     className="navbar-inputt font-weight400  font-size-text"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>



//           <table className='custom-table'>
//             <thead className='heading'>
//               <tr className='custom-table-head-tr global-table-tr'>
//                 <th className='align-left font-size-text font-weightbold'>Sno.</th>
//                 <th className='align-center font-size-text font-weightbold'>Project Code</th>
//                 <th className='align-leftt font-size-text font-weightbold'>Project Name</th>
//                 <th className='align-center font-size-text font-weightbold'>Authority Engineer</th>

//               </tr>
//             </thead>
//             <tbody>
//               {projectdata.filter((e) =>
//                 String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 String(e.authority_engineer_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
//               ).map((project, index) => (
//                 <>
//                   <React.Fragment key={index}>
//                     <tr className="tr-border-bottom">
//                       <td colSpan="8"></td>
//                     </tr>
//                     <tr className='custom-table-head-td global-table-td' key={index}>
//                       <td className='align-center font-size-text font-weight400'>{index + 1}</td>
//                       <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>
//                       <td className='align-leftt font-size-text font-weight400'>{project.project_short_name}</td>

//                       <td className='align-center font-size-text font-weight400'>{project.authority_engineer_name}</td>


//                     </tr>
//                   </React.Fragment>
//                 </>
//               ))}
//             </tbody>
//           </table>

//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };


const ProjectByOurRoleModel = ({ project_number, our_role }) => {

  const [toggle, setToggle] = useState('all');

  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getProjectData(toggle)
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [projectdata, setProjectData] = useState([]);
  const [projectcontdata, setProjectCountData] = useState([]);


  const getProjectData = async (selectedToggle) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/project-by-our-role/${our_role}/${selectedToggle}/`);

      const sortedProjects = sortProjects(res.data.projects, 'site_prcode')

      setProjectData(sortedProjects);

      setProjectCountData(res.data.counts)

    } catch (err) { }
  };


  const handleToggleChange = (e) => {
    const selectedToggle = e.target.value; // Get the value of the clicked radio button
    setToggle(selectedToggle); // Update toggle state
    getProjectData(selectedToggle); // Trigger API call explicitly
  };

  return (
    <>
      <div title='Project Details' style={{ cursor: 'pointer' }} onClick={handleShow}>
        {project_number}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width80vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>{our_role === "active" ? "Active Project Details" : our_role === "partially" ? "Partially Project Details" : our_role === "silent" ? "Silent Project Details" : "All Project Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="table-heading-flex">
            <div className="leave-duration-tabs">

              <input type="radio" id="leave-single-day" name="leave-type" value="all" defaultChecked onClick={handleToggleChange} />
              <label htmlFor="leave-single-day" className="leave-tab">All
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.all}
                </span>
              </label>


              <input type="radio" id="leave-multi-day" name="leave-type" value="lead" onClick={handleToggleChange} />
              <label htmlFor="leave-multi-day" className="leave-tab">Lead
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.lead_count}
                </span>
              </label>


              <input type="radio" id="leave-half-day" name="leave-type" value="jv" onClick={handleToggleChange} />
              <label htmlFor="leave-half-day" className="leave-tab">Jv
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.jv_count}
                </span>
              </label>


              <input type="radio" id="leave-short-day" name="leave-type" value="association" onClick={handleToggleChange} />
              <label htmlFor="leave-short-day" className="leave-tab">Association
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.association_count}
                </span>
              </label>

              <span className="leave-tab-glider"></span>
            </div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Project Name/ Authority Engineer"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='table-container'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='table-heading-text'>Sno.</th>
                  <th className='table-heading-text'>Project Code</th>
                  <th className='table-heading-text'>Project Name</th>
                  <th className='table-heading-text'>Authority Engineer</th>
                  <th className='table-heading-text'>Lead</th>
                  <th className='table-heading-text'>JV</th>
                  <th className='table-heading-text'>JV Share</th>
                  <th className='table-heading-text'>Association</th>
                  <th className='table-heading-text'>Our Role</th>
                  <th className='table-heading-text'>Our Share</th>

                </tr>
              </thead>
              <tbody>
                {projectdata.filter((e) =>
                  String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                  String(e.authority_engineer).toLowerCase().includes(searchQuery.toLowerCase()) ||
                  String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
                ).map((project, index) => (
                  <>
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="8"></td>
                      </tr>
                      <tr className='custom-table-head-td global-table-td' key={index}>
                        <td className='table-body'>{index + 1}</td>
                        <td className='table-body'>{project.site_prcode}</td>
                        <td className='table-body'><div className='whitespace-normal'>{project.project_short_name}</div> </td>
                        <td className='table-body'>{project.authority_engineer ? project.authority_engineer : <p className='text-center'>-</p>}</td>
                        <td className='table-body'><div className='whitespace-normal'>{project.lead === "." ? <p className=''>-</p> : <>{project.lead}</>}</div></td>
                        <td className='table-body'><div className='whitespace-normal'>{project.jv ? project.jv : <p className=''>-</p>}</div></td>
                        <td className='table-body'><div className='whitespace-normal'>{project.jv_share ? project.jv_share : <p className=''>-</p>}</div></td>
                        <td className='table-body'><div className='whitespace-normal'>{project.association ? project.association : <p className=''>-</p>}</div></td>
                        <td className='table-body'>{project.our_role ? project.our_role : <p className='text-center'>-</p>}</td>
                        <td className='table-body'>{project.our_share ? project.our_share : <p className='text-center'>-</p>}</td>
                      </tr>
                    </React.Fragment>
                  </>
                ))}
              </tbody>
            </table>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};



const ProjectByWorkTypeModel = ({ project_number, work_type }) => {
  const [toggle, setToggle] = useState('all');
  const [show, setshow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectdata, setProjectData] = useState([]);
  const [projectcontdata, setProjectCountData] = useState([]);

  const handleClose = () => {
    setshow(false);
  };

  const handleShow = () => {
    setshow(true);
    getProjectData(toggle); // Ensure API is called with the initial toggle value
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getProjectData = async (selectedToggle) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/project-by-work-type/${work_type}/${selectedToggle}/`
      );

      const sortedProjects = sortProjects(res.data.projects, 'site_prcode');
      setProjectCountData(res.data.counts);
      setProjectData(sortedProjects);
    } catch (err) {
      console.error(err); // Handle error if needed
    }
  };

  const handleToggleChange = (e) => {
    const selectedToggle = e.target.value; // Get the value of the clicked radio button
    setToggle(selectedToggle); // Update toggle state
    getProjectData(selectedToggle); // Trigger API call explicitly
  };

  return (
    <>
      <div title="Project Sub Details" style={{ cursor: 'pointer' }} onClick={handleShow}>
        {project_number}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width80vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {work_type === "construction consultant"
              ? "AE/IE Project Details"
              : work_type === "operations & maintenance consultant"
                ? "O&M Project Details"
                : work_type === "safety consultant"
                  ? "Safety Consultant Project Details"
                  : work_type === "dpr"
                    ? "DPR Project Details"
                    : "All Project Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-row justify-between">
            <div className="leave-duration-tabs">
              <input
                type="radio"
                id="leave-single-day"
                name="leave-type"
                value="all"
                defaultChecked
                onClick={handleToggleChange}
              />
              <label htmlFor="leave-single-day" className="leave-tab">All
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.all}
                </span>
              </label>

              <input
                type="radio"
                id="leave-multi-day"
                name="leave-type"
                value="lead"
                onClick={handleToggleChange}
              />
              <label htmlFor="leave-multi-day" className="leave-tab">Lead
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.lead_count}
                </span>
              </label>

              <input
                type="radio"
                id="leave-half-day"
                name="leave-type"
                value="jv"
                onClick={handleToggleChange}
              />
              <label htmlFor="leave-half-day" className="leave-tab">JV
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.jv_count}
                </span>
              </label>

              <input
                type="radio"
                id="leave-short-day"
                name="leave-type"
                value="association"
                onClick={handleToggleChange}
              />
              <label htmlFor="leave-short-day" className="leave-tab">Association
                <span className="leave-balance-duration">
                  :{" "}{projectcontdata?.association_count}
                </span>
              </label>

              <span className="leave-tab-glider"></span>
            </div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Project Code/ Name/ Authority Engineer"
                    className="navbar-inputt font-weight400 font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="table-container">
            <table className="custom-table">
              <thead className="heading">
                <tr className="custom-table-head-tr global-table-tr">
                  <th className="table-heading-text">Sno.</th>
                  <th className="table-heading-text">Project Code</th>
                  <th className="table-heading-text">Project Name</th>
                  <th className="table-heading-text">Authority Engineer</th>
                  <th className="table-heading-text">Lead</th>
                  <th className="table-heading-text">JV</th>
                  <th className="table-heading-text">JV Share</th>
                  <th className="table-heading-text">Association</th>
                  <th className="table-heading-text">Our Role</th>
                  <th className="table-heading-text">Our Share</th>
                </tr>
              </thead>
              <tbody>
                {projectdata
                  .filter((e) =>
                    String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                    String(e.authority_engineer).toLowerCase().includes(searchQuery.toLowerCase()) ||
                    String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((project, index) => (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="8"></td>
                      </tr>
                      <tr className="custom-table-head-td global-table-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{project.site_prcode}</td>
                        <td className="table-body">
                          <div className="whitespace-normal">{project.project_short_name}</div>
                        </td>
                        <td className="table-body">
                          {project.authority_engineer || <p className="text-center">-</p>}
                        </td>
                        <td className="table-body">
                          <div className="whitespace-normal">
                            {project.lead === "." ? <p className="">-</p> : project.lead}
                          </div>
                        </td>
                        <td className="table-body">
                          <div className="whitespace-normal">{project.jv || <p className="">-</p>}</div>
                        </td>
                        <td className="table-body">
                          <div className="whitespace-normal">{project.jv_share || <p className="">-</p>}</div>
                        </td>
                        <td className="table-body">
                          <div className="whitespace-normal">{project.association || <p className="">-</p>}</div>
                        </td>
                        <td className="table-body">
                          {project.our_role || <p className="text-center">-</p>}
                        </td>
                        <td className="table-body">
                          {project.our_share || <p className="text-center">-</p>}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeebyTypeModel = ({ count, professional_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [employeedata, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/employee-by-type/${professional_type}/`);
      setEmployeeData(res.data);
    } catch (err) { }
  };





  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Employee Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Name/ Desigantion/ Project Name"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-leftt font-size-text font-weightbold'>Name</th>


                <th className='align-center font-size-text font-weightbold'>Designation Name</th>


                {professional_type === "null" ? <th className='align-centre font-size-text font-weightbold'>Professional Type</th> : ""}



                <th className='align-center font-size-text font-weightbold'>Project Code</th>

                <th className='align-center font-size-text font-weightbold'>Project Short Name</th>



              </tr>
            </thead>
            <tbody>
              {employeedata.filter((e) =>
                e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.designation_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.name}</td>
                      <td className='align-center font-size-text font-weight400'>{project.designation_name}</td>

                      {professional_type === "null" ? <td className='align-center font-size-text font-weight400'>{project.professional_type}</td> : ""}


                      <td className='align-center font-size-text font-weight400'>{project.site_project}</td>

                      <td className='align-center font-size-text font-weight400'>{project.project_short_name}</td>



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const VacantEmployeebyTypeModel = ({ count, professional_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [employeedata, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/vacant-employee-by-designation-project/${professional_type}/`);



      const sortedProjects = sortProjects(res.data, 'site_prcode')

      setEmployeeData(sortedProjects);

    } catch (err) { }
  };


  console.log(employeedata)




  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            {professional_type === "Key Professional" ? "Key Professional Vacent Details" : professional_type === "Sub Professional" ? "Sub Professional Vacent Details" : professional_type === "Support Staff" ? "Support Staff Vacent Details" : "All Vacent Position details"}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Desigantion/ Code/Project Name"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='table-heading-text '>Sno.</th>



                <th className='table-heading-text '>Designation Name</th>


                {professional_type === "null" ? <th className='table-heading-text '>Professional Type</th> : ""}



                <th className='table-heading-text '>Project Code</th>

                <th className='table-heading-text '>Project Short Name</th>



              </tr>
            </thead>
            <tbody>
              {employeedata.filter((e) => String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.designation_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='table-body font-size-text font-weight400'>{index + 1}</td>

                      <td className='table-body font-size-text font-weight400'><div className='whitespace-normal'>{project.designation_name}</div></td>

                      {professional_type === "null" ? <td className='table-body font-size-text font-weight400'>{project.professional_type}</td> : ""}


                      <td className="table-body font-size-text font-weight400">
                        {project.projects.map((p, idx) => (
                          <table key={idx}>
                            <tbody>

                              <tr>
<td className="padding-bottom-5px">
{idx+1}.
</td>
<td style={{ padding: "0px 4px" }}></td>
                                <td className="padding-bottom-5px">
                                  {p.site_prcode}
                                </td>
                              </tr>
                            </tbody></table>
                        ))}
                      </td>

                      {/* <td className='table-body font-size-text font-weight400'>{project.projects[0].project_short_name}</td> */}
                      <td className="table-body font-size-text font-weight400">
                        {project.projects.map((p, idx) => (
                          <table key={idx}>
                            <tbody>

                              <tr>
<td className="padding-bottom-5px">
{idx+1}.
</td>
<td style={{ padding: "0px 4px" }}></td>
                                <td className="padding-bottom-5px">
                                  {p.project_short_name}
                                </td>
                              </tr>
                            </tbody></table>
                        ))}
                      </td>


                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const VacantEmployeebyDesignationTypeModel = ({ count, professional_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [employeedata, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/vacant-employee-by-designation-project/${professional_type}/`);



      const sortedProjects = sortProjects(res.data.projects, 'site_prcode')

      setEmployeeData(sortedProjects);

    } catch (err) { }
  };


  console.log(employeedata)




  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Vacant Position Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Desigantion/ Code/Project Name"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>



                <th className='align-leftt font-size-text font-weightbold'>Designation Name</th>


                {professional_type === "null" ? <th className='table-body font-size-text font-weightbold'>Professional Type</th> : ""}



                <th className='table-body font-size-text font-weightbold'>Project Code & Short Name</th>

                {/* <th className='align-center font-size-text font-weightbold'>Project Short Name</th> */}



              </tr>
            </thead>
            <tbody>
              {employeedata.filter((e) => String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.designation_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='table-body font-size-text font-weight400'>{index + 1}</td>

                      {/* <td className='table-body font-size-text font-weight400'>{project.designation_name}</td> */}

                      {professional_type === "null" ? <td className='table-body font-size-text font-weight400'>{project.professional_type}</td> : ""}


                      <td className='table-body font-size-text font-weight400'>
                        {project.projects.map((e) => (
                          e.site_prcode
                        )).join(', ')}
                      </td>



                      {/* <td className='align-center font-size-text font-weight400'>{project.project_short_name}</td> */}



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};




const EmployeeDesignationWiseModel = ({ count, projectdata }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };






  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Project Name/ Authority Engineer"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>



          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-center font-size-text font-weightbold'>Project Code</th>
                <th className='align-leftt font-size-text font-weightbold'>Project Name</th>

              </tr>
            </thead>
            <tbody>
              {projectdata.filter((e) =>
                String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.authority_engineer_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.project_short_name}</td>



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const LetterbyStatusModel = ({ count, letter_status }) => {

  const [toggle, setToggle] = useState('all');


  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData(toggle)
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [letterdata, setLetterData] = useState([]);

  const [lettercontdata, setLetterCountData] = useState([]);


  const getEmployeeData = async (selectedToggle) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/lettery-by-status/${letter_status}/${selectedToggle}/`);



      const sortedProjects = sortProjects(res.data.letters, 'site_prcode')

      setLetterData(sortedProjects);

      setLetterCountData(res.data.counts)


    } catch (err) { }
  };


  const handleToggleChange = (e) => {
    const selectedToggle = e.target.value; // Get the value of the clicked radio button
    setToggle(selectedToggle); // Update toggle state
    getEmployeeData(selectedToggle); // Trigger API call explicitly
  };




  // "all" : all,
  //                 "eot": eot,
  //                 "cos": cos,
  //                 "ncr" :ncr,
  //                 "billing": billing,
  //                 "design" : design,
  //                 "informative": informative,
  //                 "showcause": showcause,
  //                 "other" :other


  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width90vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            {letter_status === "pending" ? "Pending Letters" : letter_status === "in-progress" ? "In Progress Letters" : letter_status === "partially-close" ? "Partially Close Letters" : "All Letters"}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div className="leave-duration-tabs">

              <input type="radio" id="leave-single-day" name="leave-type" value="all" defaultChecked onClick={handleToggleChange} />
              <label htmlFor="leave-single-day" className="leave-tab">All
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.all}
                </span>
              </label>

              <input type="radio" id="leave-multi-day" name="leave-type" value="eot" onClick={handleToggleChange} />
              <label htmlFor="leave-multi-day" className="leave-tab">EOT
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.eot}
                </span>
              </label>

              <input type="radio" id="leave-half-day" name="leave-type" value="cos" onClick={handleToggleChange} />
              <label htmlFor="leave-half-day" className="leave-tab">COS
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.cos}
                </span>
              </label>

              <input type="radio" id="leave-short-day" name="leave-type" value="ncr" onClick={handleToggleChange} />
              <label htmlFor="leave-short-day" className="leave-tab">NCR
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.ncr}
                </span>
              </label>



              <input type="radio" id="billing" name="leave-type" value="billing" onClick={handleToggleChange} />
              <label htmlFor="billing" className="leave-tab">Billing
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.billing}
                </span>
              </label>


              <input type="radio" id="design" name="leave-type" value="design" onClick={handleToggleChange} />
              <label htmlFor="design" className="leave-tab">Design
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.design}
                </span>
              </label>


              <input type="radio" id="informative" name="leave-type" value="informative" onClick={handleToggleChange} />
              <label htmlFor="informative" className="leave-tab">Informative
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.informative}
                </span>
              </label>


              <input type="radio" id="showcause" name="leave-type" value="showcause" onClick={handleToggleChange} />
              <label htmlFor="showcause" className="leave-tab">Show-Cause
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.showcause}
                </span>
              </label>


              <input type="radio" id="other" name="leave-type" value="other" onClick={handleToggleChange} />
              <label htmlFor="other" className="leave-tab">Other
                <span className="leave-balance-duration">
                  :{" "}{lettercontdata?.other}
                </span>
              </label>

              <span className="leave-tab-glider-7"></span>
            </div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <SearchSmall />
                </div>
                <div>
                  <input
                    placeholder="Search by Site Code/Project Name/ Number"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='table-container'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>

                  <th className='table-heading-text'>S.no.</th>
                  <th className='table-heading-text'>Project</th>
                  <th className='table-heading-text'>Number</th>
                  <th className='table-heading-text'>Exchange</th>
                  <th className='table-heading-text'>Date</th>
                  <th className='table-heading-text'>Subject</th>
                  <th className='table-heading-text'>From /To</th>
                  <th className='table-heading-text'>Type</th>
                  <th className='table-heading-text'>Associated Letter</th>
                  <th className='table-heading-text'>Status</th>

                  <th className='table-heading-text'>View/Edit</th>

                </tr>
              </thead>

              <tbody>
                {letterdata.filter((e) => String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||

                  String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                  String(e.letter_num).toLowerCase().includes(searchQuery.toLowerCase()) ||
                  String(e.subject).toLowerCase().includes(searchQuery.toLowerCase())
                ).map((i, index) => (
                  <tr key={index} className="custom-table-head-td">
                    <td className='table-body'>{index + 1}.</td>
                    <td className='table-body'><div className='whitespace-normal'>{i.project_short_name} {i?.site_prcode} </div></td>
                    <td className='table-body form-text-trasformation-uppercase'><div className='whitespace-normal' style={{ width: "200px" }}>{i.letter_num ? i.letter_num : "-"}</div></td>
                    <td className='table-body form-text-trasformation-uppercase'>{i.letterExchange ? i.letterExchange : "-"}</td>
                    <td className='table-body'>{formatDate(i.letter_date ? i.letter_date : "-")}</td>
                    <td className='table-body'><div className='whitespace-normal' style={{ width: "344px" }}>{i.subject ? i.subject : "-"}</div></td>
                    <td className='table-body form-text-trasformation-uppercase'><div className='whitespace-normal'>{i.letter_from ? i.letter_from : "-"} <br /> {i.letter_to ? i.letter_to : "-"}</div></td>

                    <td className='table-body form-text-trasformation-uppercase'>{i.lettertype ? i.lettertype : "-"}</td>
                    <td className='table-body'><div className='whitespace-normal'> {i.associated_letters_get.length > 0
                      ? i.associated_letters_get[0].letter_num
                      : "-"}</div> </td>
                    {/* <td className='align-center'>{i.letter_date ? formattedDateLong(i.letter_date) : "---"}</td> */}
                    <td className='table-body form-text-trasformation-uppercase'>{i.status ? i.status : "-"}</td>

                    <td className='table-body'>
                      <UpdateTrackRecordDetails
                        i={i}

                        projectId={i.project}
                        letterType={i.lettertype}
                        workperformedby={i.workperformedby}
                      // associatedletters={i.associated_letters_get[0].letter_num }
                      />

                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>


        </Modal.Body>
      </Modal>
    </>
  );
};







export { ProjectByOurRoleModel, ProjectByWorkTypeModel, EmployeebyTypeModel, VacantEmployeebyTypeModel, EmployeeDesignationWiseModel, LetterbyStatusModel, VacantEmployeebyDesignationTypeModel }
