import ReactToPrint from 'react-to-print';
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import { useParams } from 'react-router-dom';
import { formatDate, formatDateDD_MM_YYYY, formattedDate } from '../../Date';
import { formatCurrencyIndian } from '../../CustomFunctions';

const VehicleLogBook = () => {
  const ref = useRef();

  let { id } = useParams();

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // const logData = [
  //   { date: "1/6/24", name: "O.M. SIR", place: "office", purpose: "site", fromTime: "9 AM", toTime: "6 PM", meterStart: 69156, meterEnd: 69228, distance: 72, signature: "Y", remarks: "" },
  //   { date: "2/6/24", name: "SUNDAY", place: "office", purpose: "sunday", fromTime: "9 AM", toTime: "SUNDAY", meterStart: 69336, meterEnd: 69366, distance: 395, signature: "Y", remarks: "" },
  //   // Add more rows as needed
  // ];

  const [vechichlelog, setVechichlelog] = useState([]);

  const getVehiclelog = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/vehiclelogdetailfilter/${id}/${selectedMonth}/${selectedYear}/`
      );
      setVechichlelog(res.data);
    } catch (err) { }
  };

  useEffect(() => { getVehiclelog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <div className="content-tabs  flex-center">
        <div className="attendance-history-cont">
          <div className="attendance-subcont">
            <div className="field-cont">

              <div className="field-cont-div">
                <input
                  type="number"
                  maxLength={4}
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="attendance-input-field width-10vw   date-field"
                />
                <hr className="field-cont-hr" />
              </div>

              <div className="field-cont-div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_650_3324)">
                    <path
                      d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                      fill="#707070"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_650_3324">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {/* <option value="">All</option> */}
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>

                <hr className="field-cont-hr" />
              </div>
            </div>
            <div className="btn-cont">
              <button
                //   type="submit"
                className="model-button model-button-submit font-size-heading"
                onClick={getVehiclelog}

              >
                Submit
              </button>
              <hr className="field-cont-hr" />
              <ReactToPrint
                bodyClass="print-agreement"
                content={() => ref.current}
                // pageStyle="@page { size: landscape; }"
                trigger={() => (
                  <button className="model-button model-button-black">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                      <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                    </svg> Print
                  </button>
                )}
              />

            </div>

          </div>
        </div>
        <div ref={ref}>
          <div className="table-css-white-background">


            <h2 className="title">VEHICLE LOG BOOK</h2>
            <div className="header-section">
              <div className="header-item"> Date.: {formatDateDD_MM_YYYY(formattedDate)} </div>
              <div>
                <div className="header-item"> Name of Owner: {vechichlelog[0]?.account_holder_name}</div>
                <div className="header-item">Vehicle Name.: {vechichlelog[0]?.vehicle_name}</div>
                <div className="header-item">Vehicle No.: {vechichlelog[0]?.vehicle_number}</div>
              </div>

            </div>
  
            <table className="nested-table-lightblue">
              <thead>
                <tr>
                  <th rowSpan="2">Date</th>
                  <th  rowSpan="2" >Name and Designation of the person</th>
                  <th rowSpan="2">Place visited and Purpose</th>
                  <th colSpan="2" className='align-center'>Period of use</th>
                  <th colSpan="2" className='align-center'>Reading of Meter </th>
                  <th rowSpan="2">Distance covered</th>
      
                  <th rowSpan="2">Remarks</th>
                </tr>
                <tr>

                  <th>Form(Hrs) </th>
                  <th>To(Hrs)</th>
                  <th>Commencement<br/> of the trip</th>
                  <th>Conclusion<br/> of the trip</th>
                
                </tr>
              </thead>
              <tbody>
                {vechichlelog.map((log, index) => (
                  <tr key={index}>
                    <td> {formatDateDD_MM_YYYY(log.date)}</td>
                    <td>{log.personusingconveyance} - {log.person_designation}</td>
                    <td>{log.purpose_and_place}</td>
 
                    <td>{log.from_time}</td>
                    <td>{log.to_time}</td>
                    <td className='align-right'>{formatCurrencyIndian(log.commencement_of_trip)}</td>
                    <td className='align-right'>{formatCurrencyIndian(log.conclusion_of_trip)}</td>
                    <td className='align-right'>{formatCurrencyIndian(log.trip_duration)}</td>
        
                    <td>{log.remark}</td>
                  </tr>

                ))}

<tr>
      <td colSpan="7" className="align-right"><strong>Total Distance Covered:</strong></td>
      <td className='align-right'>
        {formatCurrencyIndian(vechichlelog.reduce((total, log) => total + (parseFloat(log.trip_duration) || 0), 0))}
      </td>
      <td></td>
    </tr>
              </tbody>
            </table>


            {/* <div className="footer">
              <div className="footer-item">Resident Engineer</div>
              <div className="footer-item">Advisory Services Pvt. Ltd.</div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleLogBook;

