import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";





import {
  BarChart, Bar, PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis, CartesianGrid, LineChart, Line, ResponsiveContainer, LabelList
} from 'recharts';



import { BASE_URL } from '../../config/axios';
import { formatCurrencyIndian, formatRoundoff, formatRoundoffCrores, handleErrorToast } from '../../components/CustomFunctions';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { ProgressBar } from 'react-bootstrap';
import { EmployeebyTypeModel, EmployeeDesignationWiseModel, LetterbyStatusModel, ProjectByOurRoleModel, ProjectByWorkTypeModel, VacantEmployeebyTypeModel } from '../../components/Dashborad/DashboardModels';
import { formatDate } from '../../components/Date.jsx'

const DashboardChart = () => {
  const [letterData, setLetterData] = useState(null);

  const getLetterDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/letter-admin-wise/`);
      setLetterData(response.data);
    } catch (err) {
      handleErrorToast(err);
    }
  };

  useEffect(() => {
    getLetterDetails();
  }, []);

  if (!letterData) {
    return <div>Loading...</div>;
  }

  // Updated colors for "Letters by Status"
  const statusColors = {
    "pending": "#F97066", // Orange
    "close": "#B692F6",  // Teal
    "informative": "#53B1FD", // Deep Orange
    "complete": "#32D583", // Green
    "Partially Complete": "#FFEB3B", // Yellow
    "Overdue": "#E57373", // Light Red
    "in-progress": "#FDB022", // Light Blue
    "partially-close": ""
  };
  // Generate status data with colors
  const statusData = Object.keys(letterData.total_by_status).map(status => ({
    name: status,
    count: letterData.total_by_status[status],
    fill: statusColors[status] || "#BDBDBD" // Default Grey color for any undefined status
  }));

  const typeColors = [
    '#64C7FF', // Lighter Light Blue
    '#CE72DF', // Lighter Purple
    '#FF8F66', // Lighter Deep Orange
    '#8CE5A3', // Lighter Green
    '#FFD95B', // Lighter Amber
    '#66E6F1', // Lighter Cyan
    '#F88282', // Lighter Red
    '#BCAAA4', // Lighter Brown
    '#A992EA', // Lighter Indigo
    '#FFF176', // Lighter Light Yellow
  ];

  const typeColor = {
    "cos": "#F97066", // Orange
    "eot": "#B692F6",  // Teal
    "informative": "#53B1FD", // Deep Orange
    "billing": "#32D583", // Green
    "design": "#FDB022", // Yellow
    "null": "#98A2B3", // Light Red

  }

  const typeData = Object.keys(letterData.total_by_type).map((type, index) => ({
    name: type,
    count: letterData.total_by_type[type],
    fill: typeColor[type] || "#BDBDBD"
  }));

  // Data for the line chart
  const lineChartData = Object.entries(letterData.pending_by_concern_person).map(([person, count]) => ({
    name: person, // Person's name as the x-axis label
    count,        // Number of pending letters as the y-axis value
  }));



  const generateRandomData = () => {

    return Array.from({ length: 50 }, (_, index) => {
      const projectName = `Railway Tunnel Site Project ${index + 1}`;
      const prCode = `PR${index + 1}`;
      const actualSalary = Math.floor(Math.random() * 10000) + 5000; // Random actual salary between 5000 and 15000
      const boqSalary = Math.floor(Math.random() * 10000) + 5000; // Random BOQ salary between 5000 and 15000

      let profit = 0;
      let loss = 0;

      if (boqSalary > actualSalary) {
        profit = ((actualSalary / boqSalary) * 100).toFixed(2);
      } else if (actualSalary > boqSalary) {
        loss = ((boqSalary / actualSalary) * 100).toFixed(2);
      }
      return {
        projectName,
        prCode,
        actualSalary,
        boqSalary,
        profit,
        loss
      };
    });

  };

  return (
    <div className="chart-container table-css-white-background">
      <h3 className="chart-title">Letter Statistics (This Month)</h3>
      <div className="statistics-list">
        <li style={{ backgroundColor: "#32D583" }}>Received: {letterData.total_received}</li>
        <li style={{ backgroundColor: "#B692F6" }}>Closed: {letterData.total_closed}</li>
        <li style={{ backgroundColor: "#F97066" }}>Pending: {letterData.total_pending}</li>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {/* Bar Chart for Letters by Status */}
        <div className="chart-container" style={{ width: "35vw" }}>
          <h3 className="chart-title">Letters by Status</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={statusData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={{ fontSize: 10 }} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" barSize={50}>
                {statusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Pie Chart for Letters by Type */}
        <div className="chart-container" style={{ width: "25vw" }}>
          <h3 className="chart-title">Letters by Type</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="count"
                isAnimationActive={false}
                data={typeData}
                cx="50%"
                cy="50%"
                outerRadius={90}
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
              >
                {typeData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Line Chart for Pending Letters by Concern Person */}
        <div className="chart-container" style={{ width: "40vw" }}>
          <h3 className="chart-title">Pending Letters by Concern Person</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={lineChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={{ fontSize: 10 }} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="count" stroke="#42A5F5" activeDot={{ r: 8 }} /> {/* Light Blue */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};




const BillingStatisticsChart = () => {
  const [billingData, setBillingData] = useState(null);

  const getBillingDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/billing-admin-wise/`);
      setBillingData(response.data);
    } catch (err) {
      handleErrorToast(err);
    }
  };

  useEffect(() => {
    getBillingDetails();
  }, []);

  if (!billingData) {
    return <div>Loading...</div>;
  }

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF0000'];

  const billTypeData = Object.keys(billingData.bill_type_statistics).map((type, index) => ({
    name: type.toUpperCase(),
    count: billingData.bill_type_statistics[type].count,
    totalSubmitted: parseFloat(billingData.bill_type_statistics[type].total_submitted_amount),
    totalRecommended: parseFloat(billingData.bill_type_statistics[type].total_recommended_amount),
  }));

  const pieData = [
    { name: 'Total Submitted', value: parseFloat(billingData.total_submitted_amount) },
    { name: 'Total Recommended', value: parseFloat(billingData.total_recommended_amount) },
  ];

  return (
    <div className="chart-container ">http://localhost:3000/admin/wfm
      <h3 className="chart-title">Billing Statistics Dashboard</h3>
      <div className='flex-row justify-evenly'>
        <div className="chart-container  width-40vw">
          <h3 className="chart-title">Total Submitted vs Recommended Amount</h3>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h3 className="chart-title">Bill Count and Amount by Type</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={billTypeData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="count" fill="#8884d8" name="Bill Count" />
              <Bar yAxisId="right" dataKey="totalSubmitted" fill="#00C49F" name="Total Submitted Amount" />
              <Bar yAxisId="right" dataKey="totalRecommended" fill="#FFBB28" name="Total Recommended Amount" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="chart-container">
        <h3 className="chart-title">Average Submitted vs Recommended Amount</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={[{ name: 'Average', submitted: parseFloat(billingData.average_submitted_amount), recommended: parseFloat(billingData.average_recommended_amount) }]} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="submitted" stroke="#8884d8" name="Average Submitted Amount" />
            <Line type="monotone" dataKey="recommended" stroke="#82ca9d" name="Average Recommended Amount" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};






const ProjectStatusChart = () => {
  const [statusData, setStatusData] = useState([]);

  const getStatusCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/project-status-stats/`);
      const rawData = response.data;

      const statusOrder = [
        "Yet to Bid",
        "Yet to Award",
        "Yet to Sign Agreement",
        "Yet to Commence",
        "In Progress",
        "Completed",
        "In Progress including O&M",
        "Yet to Complete including O&M"
      ];

      const formattedData = Object.entries(rawData)
        .filter(([key]) => key && key !== "null") // Remove invalid keys
        .map(([status, count]) => ({
          status,
          count,
        }))
        .sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)); // Sort by order

      setStatusData(formattedData);
    } catch (error) {
      console.error("Failed to fetch project status count:", error);
    }
  };

  useEffect(() => {
    getStatusCount();
  }, []);

  // Short names and corresponding colors
  const statusShortNames = {
    "Yet to Bid": "To Bid",
    "Yet to Award": "To Award",
    "Yet to Sign Agreement": "To Sign",
    "Yet to Commence": "To Start",
    "In Progress": "Ongoing",
    "Completed": "Completed",
    "In Progress including O&M": "Prog_O&M",
    "Yet to Complete including O&M": "Comp_O&M",
  };

  const statusColors = {
    "To Bid": "#FFCCBC",
    "To Award": "#E1BEE7",
    "To Sign": "#C8E6C9",
    "To Start": "#FFEBEE",
    "Ongoing": "#64B5F6",
    "Completed": "#FFD54F",
    "Prog_O": "#64B5F6",
    "Comp_O": "#FFD54F",
  };

  // Custom Tick Component for Wrapping Short Names
  const CustomTick = ({ x, y, payload }) => {
    const shortName = statusShortNames[payload.value] || payload.value; // Get short name or fallback to original status name
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="middle" fill="#666" fontSize={10}>
          {shortName}
        </text>
      </g>
    );
  };

  return (
    <div className="invoice-cont  width-43vw  cont-col cards-shadow2 bgwhite transaction-cont box-shadow" style={{ margin: "10px" }}>
      <h2 className="dashboard-card-heading-blue font-weight500 " style={{ padding: "0px 25px" }}>Project Status</h2>
      <div className="flex-row justify-center">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={statusData} barCategoryGap="10%">
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="status"
              interval={0}
              tick={<CustomTick />}
              height={50} // Adjusted for short names
            />
            <YAxis tick={{ fontSize: 12 }} />
            <Bar dataKey="count" barSize={20} radius={[10, 10, 0, 0]}>
              <LabelList
                dataKey="count"
                position="top"
                style={{ fill: "#000", fontWeight: "bold", fontSize: "14px" }}
              />
              {statusData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={statusColors[statusShortNames[entry.status]] || "#607D8B"}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};




const VacantPositionsDashboard = () => {
  const [vacantData, setVacantData] = useState(null);

  const getVacantPositionsData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/employee-admin-wise/`);
      setVacantData(response.data);
    } catch (err) {
      console.error('Failed to fetch vacant positions data:', err);
    }
  };

  useEffect(() => {
    getVacantPositionsData();
  }, []);

  if (!vacantData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <h3 className="dashboard-title">Vacant Positions Status</h3>

      {['key_professionals', 'sub_professionals', 'support_staff'].map((type) => (
        <div key={type} className="vacant-positions-section">
          <h4 className="section-title">{type.replace('_', ' ').toUpperCase()}</h4>
          <ul className="vacant-positions-list">
            {vacantData[type].map((position, index) => (
              <li
                key={index}
                className={`vacant-item ${position.status}`}
                style={{ backgroundColor: position.status === 'vacant_more_than_20_days' ? '#FF747C' : '#F7BA1E' }}
              >
                <strong>{position.designation}</strong> in <strong>{position.project}</strong>
                <p>Status: {position.status.replace('_', ' ')}</p>
                <p>Days since vacant: {position.days_since_vacant}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};


const projectNames = [
  'Tunnel Expansion',
  'Tunnel Rehab',
  'Tunnel Upgrade',
  'Tunnel Safety',
  'Tunnel Modernization',
  'Tunnel Inspection',
  'Tunnel Integrity',
  'Tunnel Access',
  'Tunnel Lighting',
  'Tunnel Maintenance',
  'Tunnel Response',
  'Tunnel Feasibility',
  'Tunnel Sustainability',
  'Tunnel Restoration',
  'Tunnel Traffic',
  'Tunnel Connectivity',
  'Tunnel Network',
  'Tunnel Capacity',
  'Tunnel Quality',
  'Tunnel Community',
  'Tunnel Survey',
  'Tunnel Digitalization',
  'Tunnel Monitoring',
  'Tunnel Training',
  'Tunnel Assessment',
  'Tunnel Drainage',
  'Tunnel Noise',
  'Tunnel Aesthetics',
  'Tunnel Pollution',
  'Tunnel Upgrade',
  'Tunnel Management',
  'Tunnel Budgeting',
  'Tunnel Compliance',
  'Tunnel Integration',
  'Tunnel Innovation',
  'Tunnel Legacy',
  'Tunnel Benefits',
  'Tunnel Art',
  'Tunnel Preparedness',
  'Tunnel Preservation',
  'Tunnel Intermodal',
  'Tunnel Collaboration',
  'Tunnel Education',
  'Tunnel Development',
  'Tunnel Green',
  'Tunnel Urban',
  'Tunnel Enhancement',
  'Tunnel Engagement',
  'Tunnel Partnership',
  'Tunnel Mobility',
  'Tunnel Future-Proofing',
  'Tunnel Sourcing',
  'Tunnel Development',
  'Tunnel Revenue',
];

const generateRandomData = () => {
  return Array.from({ length: 50 }, (_, index) => {
    const projectName = projectNames[index % projectNames.length]; // Cycle through project names
    const prCode = `PR${index + 1}`;
    const actualSalary = Math.floor(Math.random() * 10000) + 5000; // Random actual salary between 5000 and 15000
    const boqSalary = Math.floor(Math.random() * 10000) + 5000; // Random BOQ salary between 5000 and 15000

    let profit = 0;
    let loss = 0;

    let profits = actualSalary < boqSalary; // Profit if actualSalary is less than BOQ
    let losss = actualSalary > boqSalary; // Loss if actualSalary is more than BOQ

    if (boqSalary > actualSalary) {
      profit = ((actualSalary / boqSalary) * 100).toFixed(2);
    } else if (actualSalary > boqSalary) {
      loss = ((boqSalary / actualSalary) * 100).toFixed(2);
    }

    return {
      projectName,
      prCode,
      actualSalary,
      boqSalary,
      profit,
      loss,
      profits,
      losss,

    };
  });
};

const ContractorBilling = () => {

  const [billData, setBillData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('pending'); // Default to 'pending'

  // Fetch data from the API based on the selected status
  useEffect(() => {
    const fetchEOTData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/billing-details-without-recommended-letter/`);
        setBillData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch EOT data');
        setLoading(false);
      }
    };

    fetchEOTData();
  }, [status]);

  // Handle status change from the dropdown
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };


  return (
    <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px' style={{ margin: "10px" }}>
      <div className='dashboard-header'>
        <h2 className='repo-heading font-weight500 ' style={{ padding: "0px 25px" }}>Billing Details</h2>

      </div>
      <div className='global-table-cont'>
        <table className='custom-table'>
          <thead className='heading'>
            <tr className='custom-table-head-tr global-table-tr'>
              <th className='table-heading-text'>S no.</th>
              <th className='table-heading-text'>PR NO & Project Name</th>
              <th className='table-heading-text'>Contractor</th>
              <th className='table-heading-text '>Bill Details</th>
              <th className='table-heading-text text-center'>Submitted</th>
              <th className='table-heading-text text-center'>Recommended</th>
              <th className='table-heading-text text-center'>Other Amount</th>

            </tr>
          </thead>
          <tbody>
            {billData.map((project, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="8"></td>
                </tr>
                <tr className='custom-table-head-td global-table-td'>
                  <td className='table-body'>{index + 1}.</td>
                  <td className='table-body'>{project.site_prcode} <br /><div className='whitespace-normal'>{project.project_short_name}</div>

                  </td>
                  <td className='table-body'><div className='whitespace-normal'>{project.contractorname ? project.contractorname : <p className=''>-</p>}</div></td>
                  <td className='table-body' >
                    <div >
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                          <tr>

                            <td className="align-right table-body">
                              <div className='whitespace-normal'>
                                {project?.bill_num
                                  ? project?.bill_num
                                  : "-"}, {" "} {project?.bill_type
                                    ? project?.bill_type
                                    : "-"},{" "}  {project?.bill_catagory
                                      ? project?.bill_catagory === "balancebills" ? "Bal Bill" : project?.bill_catagory
                                      : "-"}
                              </div>
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="padding-bottom-5px table-body">Type</td>
                            <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                            <td className="table-body">
                              {project?.bill_type
                                ? project?.bill_type
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="padding-bottom-5px table-body">Category</td>
                            <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                            <td className="table-body">
                              {project?.bill_catagory
                                ? project?.bill_catagory
                                : "-"}
                            </td>
                          </tr> */}
                          <tr>
                            {/* <td className="padding-bottom-5px table-body">Start date</td> */}
                            {/* <td className='table-body' style={{ padding: "0px 4px" }}>:</td> */}
                            <td className="table-body">
                              {project?.bill_period_start
                                ? formatDate(project?.bill_period_start)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            {/* <td className="padding-bottom-5px table-body">End date</td> */}
                            {/* <td className='table-body' style={{ padding: "0px 4px" }}>:</td> */}
                            <td className="table-body">
                              {project?.bill_period_end
                                ? formatDate(project?.bill_period_end)
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td className='table-body'>
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="padding-bottom-5px table-body">Date</td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end table-body">
                            {project?.submitted_bill_date
                              ? formatDate(project?.submitted_bill_date)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px table-body">Amount</td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="table-body text-end" >
                            {project?.submitted_bill_amount
                              ? `₹ ${formatRoundoffCrores(project?.submitted_bill_amount)}`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className='table-body'>
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="padding-bottom-5px table-body">Date</td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end table-body">
                            {project?.recomended_bill_date
                              ? formatDate(project?.recomended_bill_date)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px table-body">Amount</td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="table-body text-end" >
                            {project?.recommended_bill_amount
                              ? `₹ ${formatRoundoffCrores(project?.recommended_bill_amount)}`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className='table-body'>
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="padding-bottom-5px table-body">Ld </td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end table-body">
                            {project?.ld_amount
                              ? `₹ ${formatRoundoffCrores(project?.ld_amount)}`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px table-body">Withheld</td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="table-body text-end">
                            {project?.withheld_amt
                              ? `₹ ${formatRoundoffCrores(project?.withheld_amt)}`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px table-body">Released Withheld </td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="table-body text-end">
                            {project?.released_withheld_amt
                              ? `₹ ${formatRoundoffCrores(project?.released_withheld_amt)}`
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px table-body">Deduction </td>
                          <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                          <td className="table-body text-end">
                            {project?.deduction_amount
                              ? `₹ ${formatRoundoffCrores(project?.deduction_amount)}`
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [showProfit, setShowProfit] = useState(5); // Default to showing top 10 projects
  const [showLoss, setShowLoss] = useState(5); // Default to showing top 10 projects

  useEffect(() => {
    const data = generateRandomData();
    setProjects(data);
  }, []);

  const profitProjectss = projects.filter(project => project.profits).length;
  const lossProjectss = projects.filter(project => project.losss).length;
  const totalProjects = projects.length;

  const profitPercentage = totalProjects > 0 ? ((profitProjectss / totalProjects) * 100).toFixed(2) : 0;
  const lossPercentage = totalProjects > 0 ? ((lossProjectss / totalProjects) * 100).toFixed(2) : 0;

  // Update profit projects based on the show count
  const profitProjects = projects
    .filter(project => project.profit > 0)
    .sort((a, b) => b.profit - a.profit)
    .slice(0, showProfit);

  const lossProjects = projects
    .filter(project => project.loss > 0)
    .sort((a, b) => b.loss - a.loss)
    .slice(0, showLoss); // Get top 5 loss projects

  return (
    <div className='flex-column justify-between'>

      <h2 className='text-center headingcolor'>Project Statistics</h2>

      <div className='flex-row justify-between margin-20px '>
        <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>
          <div className='flex-row justify-between'>
            <h2 className='repo-heading font-weight500'>{showProfit === 5 ? 'Top 5 Profit Projects' : showProfit === 10 ? 'Top 10 Profit Projects' : 'All Profit Projects'}</h2>
            <div>
              <select
                className='dropdown'
                name="showProjects"
                value={showProfit}
                onChange={(e) => setShowProfit(parseInt(e.target.value))}
              >
                <option value={5}> Top 5 Profit Projects</option>
                <option value={10}>Top 10 Profit Projects</option>
                <option value={projects.length}>Show All Profit Projects</option>
              </select>
            </div>
          </div>

          <div className='global-table-cont'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-center font-size-text font-weightbold'>Sno.</th>
                  <th className='align-center font-size-text font-weightbold'>Project Code</th>
                  <th className='align-center font-size-text font-weightbold'>Project Name</th>
                  <th className='align-center font-size-text font-weightbold'>Actual Salary</th>
                  <th className='align-center font-size-text font-weightbold'>BOQ Salary</th>
                  <th className='align-center font-size-text font-weightbold'>Profit (%)</th>
                </tr>
              </thead>
              <tbody>
                {profitProjects.map((project, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td'>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.prCode}</td>
                      <td className='align-center font-size-text font-weight400'>{project.projectName}</td>
                      <td className='align-center font-size-text font-weight400'>{project.actualSalary}</td>
                      <td className='align-center font-size-text font-weight400'>{project.boqSalary}</td>
                      <td className='align-center font-size-text font-weight400 text-green'>+{project.profit}%</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>
          <div className='flex-row justify-between'>
            <h2 className='repo-heading font-weight500'>{showLoss === 5 ? 'Top 5 Loss Projects' : showLoss === 10 ? 'Top 10 Loss Projects' : 'All Loss Projects'}</h2>
            <div>
              <select
                className='dropdown'
                name="showProjects"
                value={showLoss}
                onChange={(e) => setShowLoss(parseInt(e.target.value))}
              >
                <option value={5}> Top 5 Loss Projects</option>
                <option value={10}>Top 10 Loss Projects</option>
                <option value={projects.length}>Show All Loss Projects</option>
              </select>
            </div>
          </div>
          <div className='global-table-cont'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-center font-size-text font-weightbold'>Sno.</th>
                  <th className='align-center font-size-text font-weightbold'>PR Code</th>
                  <th className='align-center font-size-text font-weightbold'>Project Name</th>
                  <th className='align-center font-size-text font-weightbold'>Actual Amount</th>
                  <th className='align-center font-size-text font-weightbold'>BOQ Amount</th>
                  <th className='align-center font-size-text font-weightbold'>Loss (%)</th>
                </tr>
              </thead>
              <tbody>
                {lossProjects.map((project, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td'>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.prCode}</td>
                      <td className='align-center font-size-text font-weight400'>{project.projectName}</td>
                      <td className='align-center font-size-text font-weight400'>{project.actualSalary}</td>
                      <td className='align-center font-size-text font-weight400'>{project.boqSalary}</td>
                      <td className='align-center font-size-text font-weight400 text-red'>-{project.loss}%</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};





const projectNamess = [
  'Tunnel Expansion', 'Tunnel Rehab', 'Tunnel Upgrade', 'Tunnel Safety', 'Tunnel Modernization',
  'Tunnel Inspection', 'Tunnel Integrity', 'Tunnel Access', 'Tunnel Lighting', 'Tunnel Maintenance',
  'Tunnel Response', 'Tunnel Feasibility', 'Tunnel Sustainability', 'Tunnel Restoration', 'Tunnel Traffic',
  'Tunnel Connectivity', 'Tunnel Network', 'Tunnel Capacity', 'Tunnel Quality', 'Tunnel Community',
  'Tunnel Survey', 'Tunnel Digitalization', 'Tunnel Monitoring', 'Tunnel Training', 'Tunnel Assessment',
  'Tunnel Drainage', 'Tunnel Noise', 'Tunnel Aesthetics', 'Tunnel Pollution', 'Tunnel Upgrade',
  'Tunnel Management', 'Tunnel Budgeting', 'Tunnel Compliance', 'Tunnel Integration', 'Tunnel Innovation',
  'Tunnel Legacy', 'Tunnel Benefits', 'Tunnel Art', 'Tunnel Preparedness', 'Tunnel Preservation',
  'Tunnel Intermodal', 'Tunnel Collaboration', 'Tunnel Education', 'Tunnel Development', 'Tunnel Green',
  'Tunnel Urban', 'Tunnel Enhancement', 'Tunnel Engagement', 'Tunnel Partnership', 'Tunnel Mobility',
  'Tunnel Future-Proofing', 'Tunnel Sourcing', 'Tunnel Revenue'
];

const generateRandomDataa = () => {
  return Array.from({ length: 50 }, (_, index) => {
    const projectName = projectNamess[index % projectNamess.length]; // Cycle through project names
    const prCode = `PR${index + 1}`;
    const actualSalary = Math.floor(Math.random() * 10000) + 5000; // Random actual salary between 5000 and 15000
    const boqSalary = Math.floor(Math.random() * 10000) + 5000; // Random BOQ salary between 5000 and 15000
    const submitted = Math.floor(Math.random() * 20000) + 10000; // Random submitted value
    const recommended = Math.floor(Math.random() * 20000) + 10000; // Random recommended value
    const withheld = Math.floor(Math.random() * 5000); // Random withheld
    const deduction = Math.floor(Math.random() * 3000); // Random deduction
    const penalty = Math.floor(Math.random() * 2000); // Random penalty

    const difference = Math.abs(submitted - recommended);

    let profit = 0;
    let loss = 0;

    if (boqSalary > actualSalary) {
      profit = ((actualSalary / boqSalary) * 100).toFixed(2);
    } else if (actualSalary > boqSalary) {
      loss = ((boqSalary / actualSalary) * 100).toFixed(2);
    }

    return {
      projectName,
      prCode,
      actualSalary,
      boqSalary,
      profit,
      loss,
      submitted,
      recommended,
      withheld,
      deduction,
      penalty,
      difference
    };
  });
};

const ProjectListSubmitted = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const data = generateRandomDataa();
    setProjects(data);
  }, []);

  // Filter and sort for top 5 best projects based on the smallest difference between submitted and recommended
  const bestSubmittedProjects = projects
    .filter(project => project.submitted && project.recommended)
    .sort((a, b) => a.difference - b.difference)
    .slice(0, 5);

  // Filter and sort for top 5 worst projects based on the largest difference between submitted and recommended
  const worstSubmittedProjects = projects
    .filter(project => project.submitted && project.recommended)
    .sort((a, b) => b.difference - a.difference)
    .slice(0, 5);

  return (
    <div className='flex-column justify-between margin-20px bgwhite'>
      <h2 className='text-center headingcolor'>Submitted vs Recommended</h2>

      <div className='flex-row justify-between margin-20px bgwhite'>
        {/* Best 5 Projects Table */}
        <div className='invoice-cont  width-43vw  cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>
          <h2 className='repo-heading  font-weight500  '>Top 5 Best Submitted Projects</h2>
          <div className='global-table-cont'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-left font-size-text font-weightbold'>Sno.</th>
                  <th className='align-center font-size-text font-weightbold'>Project Code</th>
                  <th className='align-center font-size-text font-weightbold'>Project Name</th>
                  <th className='align-left font-size-text font-weightbold'>Submitted</th>
                  <th className='align-left font-size-text font-weightbold'>Recommended</th>
                  <th className='align-left font-size-text font-weightbold'>Withheld</th>
                  <th className='align-left font-size-text font-weightbold'>Deduction</th>
                  <th className='align-left font-size-text font-weightbold'>Penalty</th>
                  <th className='align-left font-size-text font-weightbold'>Difference</th>
                </tr>
              </thead>
              <tbody>
                {bestSubmittedProjects.map((project, index) => (
                  <tr className='custom-table-head-td global-table-td' key={index}>
                    <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                    <td className='align-center font-size-text font-weight400'>{project.prCode}</td>
                    <td className='align-center font-size-text font-weight400'>{project.projectName}</td>
                    <td className='align-center font-size-text font-weight400'>{project.submitted}</td>
                    <td className='align-center font-size-text font-weight400'>{project.recommended}</td>
                    <td className='align-center font-size-text font-weight400'>{project.withheld}</td>
                    <td className='align-center font-size-text font-weight400'>{project.deduction}</td>
                    <td className='align-center font-size-text font-weight400'>{project.penalty}</td>
                    <td className='align-center font-size-text font-weight400'>{project.difference}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Worst 5 Projects Table */}
        <div className='invoice-cont  width-43vw  cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>
          <h2 className='repo-heading  font-weight500  '>Top 5 Worst Submitted Projects</h2>
          <div className='global-table-cont'>
            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-left font-size-text font-weightbold'>Sno.</th>
                  <th className='align-center font-size-text font-weightbold'>Project Code</th>
                  <th className='align-left font-size-text font-weightbold'>Project Name</th>
                  <th className='align-left font-size-text font-weightbold'>Submitted</th>
                  <th className='align-left font-size-text font-weightbold'>Recommended</th>
                  <th className='align-left font-size-text font-weightbold'>Withheld</th>
                  <th className='align-left font-size-text font-weightbold'>Deduction</th>
                  <th className='align-left font-size-text font-weightbold'>Penalty</th>
                  <th className='align-left font-size-text font-weightbold'>Difference</th>
                </tr>
              </thead>
              <tbody>
                {worstSubmittedProjects.map((project, index) => (
                  <>
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="8"></td>
                      </tr>
                      <tr className='custom-table-head-td global-table-td' key={index}>
                        <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                        <td className='align-center font-size-text font-weight400'>{project.prCode}</td>
                        <td className='align-center font-size-text font-weight400'>{project.projectName}</td>
                        <td className='align-center font-size-text font-weight400'>{project.submitted}</td>
                        <td className='align-center font-size-text font-weight400'>{project.recommended}</td>
                        <td className='align-center font-size-text font-weight400'>{project.withheld}</td>
                        <td className='align-center font-size-text font-weight400'>{project.deduction}</td>
                        <td className='align-center font-size-text font-weight400'>{project.penalty}</td>
                        <td className='align-center font-size-text font-weight400'>{project.difference}</td>
                      </tr>
                    </React.Fragment>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};





// Letter Types and Subcategories
const letterTypes = {
  Contractor: [
    'Show Cause',
    'Informative',
    'EOT',
    'COS',
    'Billing',
    'Design',
    'NCR',
  ],
  Consultant: [
    'Show Cause',
    'Informative',
    'EOT',
    'COS',
    'Billing',
    'Design',
    'NCR',
  ],
};

// Generate random letter data
const generateRandomLetters = () => {
  const letters = [];
  for (let i = 1; i <= 60; i++) {
    const type =
      Math.random() > 0.5
        ? 'Contractor'
        : 'Consultant'; // Randomly choose type
    const subcategory =
      letterTypes[type][Math.floor(Math.random() * letterTypes[type].length)];
    const letterId = `LTR-${i}`;
    const date = new Date(
      Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 30) // Random date within the last month
    ).toLocaleDateString();

    letters.push({
      id: letterId,
      type,
      subcategory,
      date,
    });
  }
  return letters;
};

const LetterList = () => {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    const data = generateRandomLetters();
    setLetters(data);
  }, []);

  // Count letters by type and subcategory
  const countLetters = () => {
    const counts = {
      Contractor: {
        'Show Cause': 0,
        Informative: 0,
        EOT: 0,
        COS: 0,
        Billing: 0,
        Design: 0,
        NCR: 0,
      },
      Consultant: {
        'Show Cause': 0,
        Informative: 0,
        EOT: 0,
        COS: 0,
        Billing: 0,
        Design: 0,
        NCR: 0,
      },
    };

    letters.forEach(letter => {
      const { type, subcategory } = letter;
      counts[type][subcategory] += 1;
    });

    return counts;
  };

  const counts = countLetters();

  return (
    <div className='flex-column justify-between margin-20px bgwhite'>
      <h2 className='text-center headingcolor'>Letter Statistics</h2>

      <div className='flex-row justify-between margin-20px'>
        <div className='invoice-cont  width-30vw  cont-col cards-shadow2 bgwhite transaction-cont  box-shadow margin-20px'>

          <h2 className='repo-heading  font-weight500  '>Letters List</h2>
          <div className='global-table-cont'>

            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-center font-size-text font-weightbold'>Work Performed By</th>
                  <th className='align-left font-size-text font-weightbold'>Show Cause</th>
                  <th className='align-left font-size-text font-weightbold'>Informative</th>
                  <th className='align-left font-size-text font-weightbold'>EOT</th>
                  <th className='align-left font-size-text font-weightbold'>COS</th>
                  <th className='align-left font-size-text font-weightbold'>Billing</th>
                  <th className='align-left font-size-text font-weightbold'>Design</th>
                  <th className='align-left font-size-text font-weightbold'>NCR</th>
                </tr>
              </thead>
              <tbody>
                <React.Fragment >
                  <tr className="tr-border-bottom">
                    <td colSpan="8"></td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td' >
                    <td className='align-center font-size-text font-weight400'>Contractor</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor['Show Cause']}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.Informative}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.EOT}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.COS}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.Billing}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.Design}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Contractor.NCR}</td>
                  </tr>

                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Authority</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant['Show Cause']}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.Informative}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.EOT}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.COS}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.Billing}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.Design}</td>
                    <td className='align-center font-size-text font-weight400'>{counts.Consultant.NCR}</td>
                  </tr>
                </React.Fragment>
              </tbody>
            </table>

          </div>
        </div>
        <div className='invoice-cont  width-20vw  cont-col cards-shadow2 bgwhite transaction-cont  box-shadow margin-20px'>

          <h2 className='repo-heading  font-weight500  '>Letters Status of Cosultant</h2>
          <div className='global-table-cont'>

            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-left font-size-text font-weightbold'>Letter Type</th>
                  <th className='align-left font-size-text font-weightbold'>Pending</th>
                  <th className='align-left font-size-text font-weightbold'>Complete</th>
                  <th className='align-left font-size-text font-weightbold'>Inprogress</th>
                  <th className='align-left font-size-text font-weightbold'>Close</th>

                </tr>
              </thead>
              <tbody>
                <React.Fragment >
                  <tr className="tr-border-bottom">
                    <td colSpan="8"></td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td' >
                    <td className='align-center font-size-text font-weight400'>Eot</td>
                    <td className='align-center font-size-text font-weight400'>20</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>7</td>
                    <td className='align-center font-size-text font-weight400'>5</td>

                  </tr>

                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Cos</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Informative</td>
                    <td className='align-center font-size-text font-weight400'>20</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>7</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>ShowCause</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Cos</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                </React.Fragment>
              </tbody>
            </table>

          </div>
        </div>
        <div className='invoice-cont  width-20vw  cont-col cards-shadow2 bgwhite transaction-cont  box-shadow margin-20px'>

          <h2 className='repo-heading  font-weight500  '>Letters Status of Contractor</h2>
          <div className='global-table-cont'>

            <table className='custom-table'>
              <thead className='heading'>
                <tr className='custom-table-head-tr global-table-tr'>
                  <th className='align-left font-size-text font-weightbold'>Letter Type</th>
                  <th className='align-left font-size-text font-weightbold'>Pending</th>
                  <th className='align-left font-size-text font-weightbold'>Complete</th>
                  <th className='align-left font-size-text font-weightbold'>Inprogress</th>
                  <th className='align-left font-size-text font-weightbold'>Close</th>

                </tr>
              </thead>
              <tbody>
                <React.Fragment >
                  <tr className="tr-border-bottom">
                    <td colSpan="8"></td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td' >
                    <td className='align-center font-size-text font-weight400'>Eot</td>
                    <td className='align-center font-size-text font-weight400'>20</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>7</td>
                    <td className='align-center font-size-text font-weight400'>5</td>

                  </tr>

                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Cos</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Informative</td>
                    <td className='align-center font-size-text font-weight400'>20</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>7</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>ShowCause</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                  <tr className='custom-table-head-td global-table-td'>
                    <td className='align-center font-size-text font-weight400'>Cos</td>
                    <td className='align-center font-size-text font-weight400'>10</td>
                    <td className='align-center font-size-text font-weight400'>5</td>
                    <td className='align-center font-size-text font-weight400'>6</td>
                    <td className='align-center font-size-text font-weight400'>3</td>
                  </tr>
                </React.Fragment>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
};



// Generate random project data
const generateRandomDatas = () => {
  return Array.from({ length: 50 }, (_, index) => {
    const projectName = projectNames[index % projectNames.length]; // Cycle through project names
    const prCode = `PR${index + 1}`;
    const payment = Math.floor(Math.random() * 50000) + 10000; // Random payment between 10000 and 60000
    const deduction = Math.floor(Math.random() * 5000); // Random deduction
    const GST = Math.floor(Math.random() * 3000); // Random GST
    const withheld = Math.floor(Math.random() * 2000); // Random withheld amount
    const daysAfterSubmission = Math.floor(Math.random() * 30) + 1; // Random days after submission between 1 and 30

    return {
      projectName,
      prCode,
      payment,
      deduction,
      GST,
      withheld,
      daysAfterSubmission
    };
  });
};

const ProjectLists = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const data = generateRandomDatas();
    setProjects(data);
  }, []);

  // Sort the projects by daysAfterSubmission in descending order and get the top 5
  const topDelayedProjects = projects
    .sort((a, b) => b.daysAfterSubmission - a.daysAfterSubmission)
    .slice(0, 5);

  return (
    <div className='flex-column justify-between margin-20px bgwhite'>
      <h2 className='text-center headingcolor '>Payment Statistics</h2>

      <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>
        <h2 className='repo-heading font-weight500 '>Billing Information</h2>
        <div className='global-table-cont'>
          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-center font-size-text font-weightbold'>Sno.</th>
                <th className='align-center font-size-text font-weightbold'>PR Code</th>
                <th className='align-center font-size-text font-weightbold'>Project Name</th>
                <th className='align-center font-size-text font-weightbold'>Payment</th>
                <th className='align-center font-size-text font-weightbold'>Deduction</th>
                <th className='align-center font-size-text font-weightbold'>GST</th>
                <th className='align-center font-size-text font-weightbold'>Withheld</th>

                <th className='align-center font-size-text font-weightbold'>Days After Submission</th>
              </tr>
            </thead>
            <tbody>
              {topDelayedProjects.map((project, index) => (
                <tr className='custom-table-head-td global-table-td' >
                  <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                  <td className='align-center font-size-text font-weight400'>{project.prCode}</td>
                  <td className='align-center font-size-text font-weight400'>{project.projectName}</td>
                  <td className='align-center font-size-text font-weight400'>{project.payment}</td>
                  <td className='align-center font-size-text font-weight400'>{project.deduction}</td>
                  <td className='align-center font-size-text font-weight400'>{project.GST}</td>
                  <td className='align-center font-size-text font-weight400'>{project.withheld}</td>
                  <td className='align-center font-size-text font-weight400'>{project.daysAfterSubmission}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};



const VacantListt = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/vacant-positions-status/`);
        setProjects(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch project data');
        setLoading(false);
      }
    };

    fetchProjectData();
  }, []);


  const getShortForm = (designation) => {
    const shortForms = {
      'Team Leader Cum Senior Highway Engineer': 'TL/SHE',
      'Team Leader Cum Senior Bridge/ Structural Engineer': 'TL/SBSE',
      'Bridge / Structural Engineer': 'BSE',
      'Senior Architect': 'SA',
      'Assistant Bridge Engineer': 'ABE',
      'Bridge/Structure Design Engineer': 'BSDE',
      // Add more mappings as needed
    };

    // Return the short form if it exists, otherwise return the original designation
    return shortForms[designation] || designation;
  };

  // Modify remarks to use short form
  const getShortFormRemarks = (remarks) => {
    const match = remarks.match(/^(.+?),\s*(-?\d+)\s*days\s*left$/);
    if (!match) return remarks;

    const designation = match[1].trim();
    const daysLeft = match[2].trim();

    // Get the short form of the designation
    const shortFormDesignation = getShortForm(designation);

    return `${shortFormDesignation}, ${daysLeft} days left`;
  };

  // Function to determine the text color based on the days left
  const getColorForDaysLeft = (remarks) => {
    // Extract the number of days left from the remarks
    const match = remarks.match(/(-?\d+)\s*days\s*left/);
    if (!match) return 'black'; // Default color if no days left is specified

    const daysLeft = parseInt(match[1], 10);

    // Apply color logic based on days left
    if (daysLeft > 10) {
      return 'black';
    } else if (daysLeft >= 5 && daysLeft <= 10) {
      return 'green';
    } else {
      return 'red';
    }
  };





  return (

    <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow' style={{ margin: "10px" }}>
      <h2 className='repo-heading font-weight500 padding-heading'>Vacant Positions</h2>
      <div className='global-table-cont'>
        <table className='custom-table'>
          <thead className='heading'>
            <tr className='custom-table-head-tr'>
              <th className='table-heading-text'>S.no.</th>
              <th className='table-heading-text'>PR NO & Name</th>
              {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
              <th className='table-heading-text text-center'>KP</th>
              <th className='table-heading-text text-center'>SP</th>
              <th className='table-heading-text text-center'>SS</th>
              <th className='table-heading-text text-center'>Total Vacant</th>
              <th className='table-heading-text'>Remark</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => (
              <React.Fragment key={index}>
                <tr className='custom-table-head-td global-table-td'>
                  <td className='table-body'>{index + 1}.</td>

                  <td className='table-body'><div className='whitespace-normal'>{project.pr_no} &
                    {project.project_short_name}</div></td>

                  <td className='table-body text-center'>{project.key_professionals ? project.key_professionals : "-"}</td>
                  <td className='table-body text-center'>{project.sub_professionals ? project.sub_professionals : "-"}</td>
                  <td className='table-body text-center'>{project.support_staff ? project.support_staff : "-"}</td>
                  <td className='table-body text-center'>{project.total_vacant_positions ? project.total_vacant_positions : "-"}</td>
                  <td
                    className='table-body'
                    style={{ color: getColorForDaysLeft(project.remarks) }}
                  >
                    <div className='whitespace-normal'>
                      {getShortFormRemarks(project.remarks ? project.remarks : "-")}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};


const VacantList = () => {
  const [projects, setProjects] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [professionalType, setProfessionalType] = useState('null'); // 'all', 'Key Professional', 'Sub Professional', 'Support Staff'

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/vacant-employee-by-designation-project/${professionalType}/`);
        setProjects(response.data);
        setFilteredData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch project data');
        setLoading(false);
      }
    };

    fetchProjectData();
  }, []);

  // Update filtered data when viewType or professionalType changes





  return (
    <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px'>


      <div className="table-heading-flex">
        <div><h2 className='dashboard-card-heading-blue font-weight500'>Vacant Positions</h2></div>

        <div className="table-searchh"> <select
          value={professionalType}
          onChange={(e) => setProfessionalType(e.target.value)}

          style={{ border: "none" }}

          className='navbar-inputt font-weight400  font-size-text'
        >
          <option value='null'>All Types</option>
          <option value='Key Professional'>Key Professionals</option>
          <option value='Sub Professional'>Sub Professionals</option>
          <option value='Support Staff'>Support Staff</option>
        </select></div>
      </div>


      <div className='global-table-cont'>

        <table className='custom-table'>
          <thead className='heading'>
            <tr className='custom-table-head-tr-with-less-padding global-table-tr'>
              <th className='align-leftt font-size-text font-weightbold'>Desigantion Name</th>

              <th className='align-leftt font-size-text font-weightbold'> Project</th>

              {professionalType === 'null' ? <th className='align-center font-size-text font-weightbold'>Professional Type</th> : ""}

            </tr>
          </thead>
          <tbody>
            {projects.sort((a, b) => b.designation_name.localeCompare(a.designation_name)).map((item, index) => (
              <tr key={index} className='custom-table-head-td-with-less-padding global-table-td'>
                <td className='align-leftt font-size-text font-weight400'>
                  {item.designation_name}
                </td>

                <td className='align-center font-size-text font-weight400'>

                  <EmployeeDesignationWiseModel count={item.projects.length} projectdata={item.projects} />

                </td>

                {professionalType === "null" ? <td className='align-center'>
                  {item.professional_type}
                </td> : ""}



              </tr>
            ))

            }
          </tbody>
        </table>

      </div>
    </div>
  );
};



const EOTDashboard = () => {
  const [eotData, setEotData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('pending'); // Default to 'pending'

  // Fetch data from the API based on the selected status
  useEffect(() => {
    const fetchEOTData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/eotdashboard/${status}/`);
        setEotData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch EOT data');
        setLoading(false);
      }
    };

    fetchEOTData();
  }, [status]);

  // Handle status change from the dropdown
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };


  return (
    <div className='invoice-cont  width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow' style={{ margin: "10px" }}>
      <div className='dashboard-header'>
        <h2 className='repo-heading font-weight500' style={{ padding: "0px 25px" }}>EOT Project</h2>
        <select className='status-dropdown' value={status} onChange={handleStatusChange} style={{ borderColor: "#9ec2e1" }}>
          <option value='pending'>Pending</option>
          <option value='approved'>Approved</option>
        </select>
      </div>
      <div className='global-table-cont'>
        <table className='custom-table' style={{ minHeight: "311px" }}>
          <thead className='heading'>
            <tr className='custom-table-head-tr'>
              <th className='table-heading-text'>S.no.</th>
              <th className='table-heading-text'>PR NO & Project Name</th>
              <th className='table-heading-text'>EOT Code</th>
              <th className='table-heading-text'>Letter No.</th>
              {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
              <th className='table-heading-text'>Contractor</th>
              <th className='table-heading-text text-center'>No. of Days</th>
              {status === "pending" ?
                <th className='table-heading-text '>Initiated Date</th>
                :
                <th className='table-heading-text '>Approval Date</th>
              }
              {/* <th className='table-heading-text'>Remark</th> */}
            </tr>
          </thead>
          <tbody>
            {eotData.map((project, index) => (
              <React.Fragment key={index}>
                <tr className='custom-table-head-td global-table-td'>
                  <td className='table-body'>{index + 1}.</td>
                  <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                    {project.project_short_name}</div></td>
                  <td className='table-body'>{project.eot_code}</td>
                  <td className='table-body'>{project.letter_number}</td>
                  <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                  <td className='table-body text-center'>{project.approved_days ? project.approved_days : "-"}</td>
                  {status === "pending" ?
                    <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                    :
                    <td className='table-body '>{project.approved_date ? formatDate(project.approved_date) : "-"}</td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {/* <div className='custom-table' style={{minHeight:"311px"}}>
          {eotData.map((eot, index) => (
            <div key={eot.id} className='eot-list custom-table-head-td-with-less-padding global-table-td'>
              <div className='eot-header flex-column'>
                <div className='flex-row'>
                  <div>
                    <span className='eot-code'>{eot.eot_code}</span></div>
                  <div>
                    <p className='eot-project-name'>{eot.project_code}<br />{eot.project_short_name}</p>
                  </div>
                </div>

                <p>

                  Contractor: <strong>{eot.contractor_name}</strong>

                </p>
              </div>
              <div className='eot-details align-right'>

                {status === 'approved' ? (
                  <>
                    <p>
                      No of Days: <span className='days-left' style={{ color: 'green' }}>
                        {eot.approved_days}
                      </span>
                    </p>
                    <br />
                    <p>
                      Approval Date: <span className='approval-date'>
                        {new Date(eot.approved_date).toLocaleDateString()}
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Letter Number: <span className='letter-number'>{eot.letter_number}</span>
                    </p>
                    <p>
                      Process Initiate Date: <span className='process-initiate-date'>
                        {eot.process_initiate_date ? new Date(eot.process_initiate_date).toLocaleDateString() : 'N/A'}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div> */}

      </div>
    </div>
  );
};


const COSDashboard = () => {
  const [eotData, setEotData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('pending'); // Default to 'pending'

  // Fetch data from the API based on the selected status
  useEffect(() => {
    const fetchEOTData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/cosdashboard/${status}/`);
        setEotData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch EOT data');
        setLoading(false);
      }
    };

    fetchEOTData();
  }, [status]);

  // Handle status change from the dropdown
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };


  console.log(eotData)


  return (
    <div className='invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow' style={{ margin: "10px" }}>
      <div className='dashboard-header'>
        <h2 className='repo-heading font-weight500' style={{ padding: "0px 25px" }}>COS Project</h2>
        <select className='status-dropdown' value={status} onChange={handleStatusChange} style={{ border: "none" }}>
          <option value='pending'>Pending</option>
          <option value='approved'>Approved</option>
        </select>
      </div>
      <div className='global-table-cont'>
        <table className='custom-table' style={{ minHeight: "311px" }}>
          <thead className='heading'>
            <tr className='custom-table-head-tr'>
              <th className='table-heading-text'>S.no.</th>
              {/* <th className='table-heading-text'>PR NO & Project Name</th> */}
              <th className='table-heading-text'>COS Code</th>
              <th className='table-heading-text'>Letter No.</th>
              {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
              <th className='table-heading-text'>Contractor</th>
              <th className='table-heading-text text-end'>Amount</th>
              {status === "pending" ?
                <th className='table-heading-text '>Initiated Date</th>
                :
                <th className='table-heading-text '>Approval Date</th>
              }
              {/* <th className='table-heading-text'>Remark</th> */}
            </tr>
          </thead>
          <tbody>
            {eotData.map((project, index) => (
              <React.Fragment key={index}>
                <tr className='custom-table-head-td global-table-td'>
                  <td className='table-body'>{index + 1}.</td>
                  {/* <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                    {project.project_short_name}</div></td> */}
                  <td className='table-body'>{project.cos_code}</td>
                  <td className='table-body'>{project.letter_number}</td>
                  <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                  <td className='table-body text-end'>₹{project.amount ? formatCurrencyIndian(project.amount) : "-"}</td>
                  {status === "pending" ?
                    <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                    :
                    <td className='table-body '>{project.approval_date ? formatDate(project.approval_date) : "-"}</td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {/* <div className='custom-table' style={{ minHeight: "311px" }}>
          {eotData.map((eot, index) => (
            <div key={eot.id} className='eot-list custom-table-head-td-with-less-padding global-table-td'>
              <div className='eot-header flex-column'>
                <div className='flex-row'>
                  <div>
                    <span className='eot-code'>{eot.cos_code}</span></div>
                  <div>
                    <p className='eot-project-name'>{eot.project_code}<br />{eot.project_short_name}</p>
                  </div>
                </div>

                <p>

                  Contractor: <strong>{eot.contractor_name}</strong>

                </p>
              </div>
              <div className='eot-details align-right'>

                {status === 'approved' ? (
                  <>
                    <p>
                      No of Days: <span className='days-left' style={{ color: 'green' }}>
                        {eot.approved_days}
                      </span>
                    </p>
                    <br />
                    <p>
                      Approval Date: <span className='approval-date'>
                        {new Date(eot.approved_date).toLocaleDateString()}
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Letter Number: <span className='letter-number'>{eot.letter_number}</span>
                    </p>
                    <p>
                      Process Initiate Date: <span className='process-initiate-date'>
                        {eot.process_initiate_date ? new Date(eot.process_initiate_date).toLocaleDateString() : 'N/A'}
                      </span>
                    </p>

                  </>
                )}
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};




const LetterSummary = () => {
  const [letterSummary, setLetterSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLetterSummary = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/letter-summary/`);
        setLetterSummary(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch letter summary');
        setLoading(false);
      }
    };

    fetchLetterSummary();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const letterTypes = ['Show Cause', 'Informative', 'EOT', 'COS', 'Billing'];

  return (
    <div className='letter-summary-container'>
      <h2 className='summary-heading'>Letter List</h2>
      <div className='letter-summary'>
        <table className='summary-table'>
          <thead>
            <tr>
              <th>Ltter</th>
              {letterTypes.map((type) => (
                <th key={type} colSpan={2} className='align-center'>{type}</th>
              ))}
            </tr>
            <tr>
              <th></th>
              {letterTypes.map((type) => (
                <>
                  <th key={`${type}-consultant`} className='align-center'>Consultant</th>
                  <th key={`${type}-contractor`} className='align-center'>Contractor</th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(letterSummary).map(([status, roles], index) => (
              <tr key={index}>
                <td className='align-left'>{status}</td>
                {letterTypes.map((type) => (
                  <>
                    <td key={`${status}-${type}-consultant`} className='align-center'>
                      {roles.Consultant[type] || 0}
                    </td>
                    <td key={`${status}-${type}-contractor`} className='align-center'>
                      {roles.Contractor[type] || 0}
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};




const categories = ["EOT", "COS", "NCR", "Billing", "Design", "Informative", "SC", "Others"];

const LetterTypeTable = () => {

  const [letterdata, setLetterdata] = useState({}); // Default to 'pending'

  // Fetch data from the API based on the selected status
  useEffect(() => {
    const fetchEOTData = async () => {

      try {
        const response = await axios.get(`${BASE_URL}/siteproject/letter-summary/`);
        setLetterdata(response.data);
      } catch (err) {

      }
    };

    fetchEOTData();
  }, []);


  // Option 1: Render Consultant and Contractor values in separate cells
  const renderSeparateCells = (type, category) => (
    <React.Fragment key={`${type}-${category}`}>
      <td style={{ color: "red", textAlign: "center" }}>
        {letterdata[type]?.Consultant?.[category] || 0}
      </td>
      <td style={{ color: "green", textAlign: "center" }}>
        {letterdata[type]?.Contractor?.[category] || 0}
      </td>
    </React.Fragment>
  );



  return (
    <div className="invoice-cont width-43vw cont-col cards-shadow2 bgwhite transaction-cont box-shadow margin-20px" style={{ margin: "10px" }}>
      <div className='flex-row justify-between'>
        <h2 className="repo-heading font-weight500" style={{ padding: "0px 25px",  cursor: "pointer" }} title='All'>Letter Type</h2>
        <div className='flex-row'>
          <th style={{ textAlign: "center", color: "red", marginRight: "10px", cursor: "pointer"}} className='flex-row'><div style={{height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "red", marginRight: "5px" }}></div>Consultant</th>
          <th style={{ textAlign: "center", color: "green", cursor: "pointer" }} className='flex-row'><div style={{height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "green", marginRight: "5px" }}></div>Contractor</th>
        </div>
      </div>
      <div className="global-table-cont">
        <table className="custom-table">
          <thead className="heading">
            <tr className="custom-table-head-tr global-table-tr">
              <th className="align-leftt font-size-text font-weightbold">Type</th>
              {categories.map((category) => (
                <th
                  key={category}
                  className="align-center font-size-text font-weightbold"
                  colSpan={2} // Option for separate cells
                >
                  {category}
                </th>
              ))}
            </tr>
            {/* Optional header row for Consultant and Contractor */}

          </thead>
          <tbody>
            {Object.keys(letterdata).map((type) => (
              <tr key={type} className="custom-table-head-td global-table-td">
                <td style={{ fontWeight: "bold" }} className='align-leftt'>{type}</td>
                {categories.map((category) => renderSeparateCells(type, category))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};







const StatsCards = () => {
  const [projectData, setProjectData] = useState(null);

  const getProjectData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/dashboard-summary-stats/`);
      setProjectData(res.data);
    } catch (err) {
      console.error('Failed to fetch project data:', err);
    }
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projectData) return <div>Loading...</div>;

  return (
    <div className="stat-cards cont-col cards-shadow2 bgwhite transaction-cont box-shadow" style={{ margin: "10px 20px" }}>
      {/* Total Projects Work type Card */}
      <div className="stat-card">
        <div className="stat-value"><ProjectByWorkTypeModel project_number={projectData.total_project_work_type['ae/ie'] + projectData.total_project_work_type['o&m'] + projectData.total_project_work_type.safety + projectData.total_project_work_type.dpr} work_type="null" /></div>
        <div className="stat-label">Total Projects</div>
        <div className="stat-details">
          <div className="detail-item">
            <span className="detail-label">AE/IE</span>
            <span className="detail-count" style={{ color: 'green' }}>
              {/* {projectData.total_projects_by_role.Active} */}

              {/* <ProjectByOurRoleModel project_number={projectData.total_project_work_type['ae/ie']} our_role="active" /> */}
              <ProjectByWorkTypeModel project_number={projectData.total_project_work_type['ae/ie']} work_type="construction consultant" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">O&M</span>
            <span className="detail-count" style={{ color: 'orange' }}>
              {/* <ProjectByOurRoleModel project_number={projectData.total_project_work_type['o&m']} our_role="partially" /> */}
              <ProjectByWorkTypeModel project_number={projectData.total_project_work_type['o&m']} work_type="operations & maintenance consultant" />
            </span>
          </div>

          <div className="detail-item">
            <span className="detail-label">Safety</span>
            <span className="detail-count" style={{ color: 'red' }}>
              {/* {projectData.total_projects_by_role.Silent} */}
              {/* <ProjectByOurRoleModel project_number={projectData.total_project_work_type.safety} our_role="silent" /> */}
              <ProjectByWorkTypeModel project_number={projectData.total_project_work_type?.safety} work_type="safety consultant" />
            </span>
          </div>

          <div className="detail-item">
            <span className="detail-label">DPR</span>
            <span className="detail-count" style={{ color: 'green' }}>
              {/* {projectData.total_projects_by_role.Active} */}
              {/* <ProjectByOurRoleModel project_number={projectData.total_project_work_type.dpr} our_role="active" /> */}
              <ProjectByWorkTypeModel project_number={projectData.total_project_work_type?.dpr} work_type="dpr" />
            </span>
          </div>

        </div>
      </div>


      {/* Total Projects Card */}
      <div className="stat-card">
        <div className="stat-value"><ProjectByOurRoleModel project_number={projectData.total_projects_by_role?.Active + projectData.total_projects_by_role?.Partially + projectData.total_projects_by_role?.Silent} our_role="null" /></div>
        <div className="stat-label">Supervision</div>
        <div className="stat-details">
          <div className="detail-item">
            <span className="detail-label">Active</span>
            <span className="detail-count" style={{ color: 'green' }}>
              {/* {projectData.total_projects_by_role.Active} */}
              {/* <ProjectByOurRoleModel project_number={projectData.total_projects_by_role.Active} our_role="active" /> */}
              <ProjectByOurRoleModel project_number={projectData.total_projects_by_role?.Active} our_role="active" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Partially</span>
            <span className="detail-count" style={{ color: 'orange' }}>
              {/* <ProjectByOurRoleModel project_number={projectData.total_projects_by_role.Partially} our_role="partially" /> */}
              <ProjectByOurRoleModel project_number={projectData.total_projects_by_role?.Partially} our_role="partially" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Silent</span>
            <span className="detail-count" style={{ color: 'red' }}>
              {/* {projectData.total_projects_by_role.Silent} */}
              {/* <ProjectByOurRoleModel project_number={projectData.total_projects_by_role.Silent} our_role="silent" /> */}
              <ProjectByOurRoleModel project_number={projectData.total_projects_by_role?.Silent} our_role="silent" />
            </span>
          </div>



        </div>
      </div>


      {/* Total Employees Card
       <div className="stat-card">
        <div className="stat-value"><EmployeebyTypeModel count={projectData.total_employees} professional_type="null"/> </div>
        <div className="stat-label">Total Employees</div>
        <div className="stat-details">
          <div className="detail-item">
            <span className="detail-label">Key Prof</span>
            <span className="detail-count" style={{ color: 'green' }}>
            

              <EmployeebyTypeModel count= {projectData.employees_by_professional_type.KP} professional_type="Key Professional"/>

            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Sub Prof</span>
            <span className="detail-count" style={{ color: 'orange' }}>
             

              <EmployeebyTypeModel count= {projectData.employees_by_professional_type.SP} professional_type="Sub Professional"/>

            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Sup Staff</span>
            <span className="detail-count" style={{ color: 'red' }}>
             

              <EmployeebyTypeModel count=  {projectData.employees_by_professional_type.SS} professional_type="Support Staff"/>


            </span>
          </div>
        </div>
      </div> */}

      {/* Total Vacant Positions Card */}
      <div className="stat-card">
        <div className="stat-value"> <VacantEmployeebyTypeModel count={projectData.total_vacant } professional_type="null" />
        </div>
        <div className="stat-label">Total Vacant</div>
        <div className="stat-details">
          <div className="detail-item">
            <span className="detail-label">Key Prof</span>
            <span className="detail-count" style={{ color: 'green' }}>
              <VacantEmployeebyTypeModel count={projectData.vacant_designations_count.KP / 2 + 0.5} professional_type="Key Professional" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Sub Prof</span>
            <span className="detail-count" style={{ color: 'orange' }}>
              <VacantEmployeebyTypeModel count={projectData.vacant_designations_count.SP / 2} professional_type="Sub Professional" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Sup Staff</span>
            <span className="detail-count" style={{ color: 'red' }}>
              <VacantEmployeebyTypeModel count={projectData.vacant_designations_count.SS / 2 + 0.5} professional_type="Support Staff" />
            </span>
          </div>
        </div>
      </div>

      {/* Invoice Card */}
      {/* <div className="stat-card">
        <div className="stat-value">{projectData.invoices}</div>
        <div className="stat-label">Invoice</div>
        <div className="stat-details">
          
        </div>
      </div> */}


      {/* Inovice Card */}
      <div className="stat-card">
        <div className="stat-value">
          {/* <LetterbyStatusModel count={projectData.letter_pendencies.Pnd + projectData.letter_pendencies.Inprs + projectData.letter_pendencies.PrtyClose} letter_status="null" /> */}
          148.81 Cr.
          </div>
        <div className="stat-label">BOQ Amount</div>
        <div className="stat-details">
          <div className="detail-item">
            {/* <span className="detail-label">BOQ</span> */}
            <span className="detail-label">Invoice</span>

            <span className="detail-count" style={{ color: 'green' }}>
              {/* <LetterbyStatusModel count={projectData.letter_pendencies.Pnd} letter_status="pending" /> */}
              0.44 Cr.

            </span>
          </div>
          <div className="detail-item">
            {/* <span className="detail-label">Invoice</span> */}
            <span className="detail-label">Payment Rec</span>

            <span className="detail-count" style={{ color: 'orange' }}>
              {/* <LetterbyStatusModel count={projectData.letter_pendencies.Inprs} letter_status="in-progress" /> */}
          {/* 0.44 Cr. */}
          0.21 Cr.
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Pending</span>
            <span className="detail-count" style={{ color: 'red' }}>
              {/* <LetterbyStatusModel count={projectData.letter_pendencies.PrtyClose} letter_status="partially-close" /> */}
          148.37 Cr. 
            </span>
          </div>
        </div>
      </div>

      {/* Letter Pendency Card */}
      <div className="stat-card">
        <div className="stat-value">
          <LetterbyStatusModel count={projectData.letter_pendencies.Pnd + projectData.letter_pendencies.Inprs + projectData.letter_pendencies.PrtyClose} letter_status="null" />
        </div>
        <div className="stat-label">Letter Pendency</div>
        <div className="stat-details">
          <div className="detail-item">
            <span className="detail-label">Pend</span>
            <span className="detail-count" style={{ color: 'green' }}>
              <LetterbyStatusModel count={projectData.letter_pendencies.Pnd} letter_status="pending" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">In prs</span>
            <span className="detail-count" style={{ color: 'orange' }}>
              <LetterbyStatusModel count={projectData.letter_pendencies.Inprs} letter_status="in-progress" />
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">PrtyClose</span>
            <span className="detail-count" style={{ color: 'red' }}>
              <LetterbyStatusModel count={projectData.letter_pendencies.PrtyClose} letter_status="partially-close" />
            </span>
          </div>
        </div>
      </div>

      {/* Pending Salary Card */}
      {/* <div className="stat-card">
        <div className="stat-value">{projectData.pending_salary}</div>
        <div className="stat-label">Pending Salary</div>
        <div className="stat-details">
        
        </div>
      </div> */}
    </div>
  );
};





const AdminDashboardDetails = () => {

  const [today, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;

  return (
    <div>
      <div className='workarea-background'>
        <Dashboardnavbar url="Dashboard" name={wish} />

        <div>
          <div>
            <StatsCards />
          </div>



          <div className='flex-row justify-evenly' style={{ margin: "0px 10px" }}>
            <ProjectStatusChart />
            <VacantListt />
            {/* <VacantPositionsDashboard /> */}
            {/* <VacantList /> */}
          </div>
          <div className='flex-row justify-evenly' style={{ margin: "0px 10px" }}>
            <EOTDashboard />
            <COSDashboard />
          </div>


          <div className='flex-row justify-evenly' style={{ margin: "0px 10px" }}>
            <ContractorBilling />
            <LetterTypeTable />
          </div>

          {/* <ProjectList /> */}
          {/* <ProjectListSubmitted /> */}
          {/* <LetterSummary/> */}

          {/* <LetterTypeTable /> */}
          {/* <LetterList />
          <ProjectLists /> */}

          {/* <VacantPositionsDashboard/> */}
        </div>

        {/* <div >
          <DashboardChart />
          <BillingStatisticsChart />


        </div> */}




      </div>

    </div>
  )
}

export default AdminDashboardDetails
