import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import usePermission from '../../../config/permissions';
import { AddTrackRecordDetails, DeleteTrackAndLetterDetails, UpdateTrackRecordDetails, } from '../../../components/SiteManagement/ProjectDetailsCrudButtons';
import { formattedDateLong } from '../../../components/Date';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import Dashboardnavbar from '../../../layout/Dashboardnavbar';
import { handleErrorToast, sortProjects } from '../../../components/CustomFunctions';
import { CircularGrid, Search, SearchSmall } from '../../../components/AllSvg';
import { LetterStatus, LetterType } from '../../../components/FilterDropdown';
import { Projects } from '../../../components/FilterDropdown';



const LetterTracking = () => {
  const tableRef = useRef(null);
  const [toggleCompany, setToggleCompany] = useState("null");

  const { PERMISSION_AUTHORITY, projectID, siteRole } = usePermission();

  const [project, setProject] = useState(projectID ? projectID : "null");

  const [workperformedby, setWorkperformedby] = useState("null");

  const [lettertype, setLetterType] = useState("null");




  const [projectList, setProjectList] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedNo, setNo] = useState("");
  const [selectedFromDate, setFromDate] = useState('null');
  const [selectedToDate, setToDate] = useState('null');
  const [selectedLetterStatus, setLetterStatus] = useState('null');
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

  const [trackRecordList, setTrackRecordList] = useState([]);


  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);
      // const pro = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`
      );
      const sortedList = sortProjects(pro.data, "site_prcode");
      setProjectList(sortedList);

      // const res = await axios.get(`${BASE_URL}/siteproject/letterrecord/`);
      // setTrackRecordList(res.data);


    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getLetters = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/letterrecords/filter/${selectedLetterStatus}/${selectedFromDate}/${selectedToDate}/null/${project}/${workperformedby}/${lettertype}/null/null/null/null/`);
      setTrackRecordList(res.data);
    } catch (error) {
      handleErrorToast(error);
    }
  }
  useEffect(() => {
    getProjects();
  }, [toggleCompany])

  useEffect(() => {
    getLetters();
  }, [selectedNo, selectedLetterStatus, selectedFromDate, selectedToDate, project, workperformedby, lettertype])


  useEffect(() => {
    applyFilters();
  }, [selectedNo, trackRecordList, filteredData]);

  const applyFilters = () => {
    let filteredData = trackRecordList;

    if (selectedNo !== "") {
      const lowercaseSelectedName = selectedNo.toLowerCase();

      // Log to verify data
      console.log("SelectedNo:", lowercaseSelectedName);

      filteredData = filteredData.filter(employee => {
        if (!employee || !employee.letter_num) {
          console.log("Skipping undefined or missing letter_num for employee:", employee);
          return false; // Skip entries with undefined or missing letter_num
        }

        // Convert the letter_num to string and filter
        const lid = employee.letter_num.toString().toLowerCase().includes(lowercaseSelectedName);
        return lid;
      });
    }

    // Check if filteredData is empty
    console.log("Filtered data:", filteredData);

    setFilteredData(filteredData);
  };

  console.log(projectID)

  return (
    <>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dashboardnavbar name={"Letter Tracking"} url="Letter Tracking" />
      <div className='content-tabs'>

        <div className="attendance-history-cont">

          <>
            <div>
              <div className="attendance-heading  font-weight500    font-size-heading"> </div>
              <div className="attendance-subcontainer">
                <div className="field-cont">

                  {PERMISSION_AUTHORITY ?
                    <div title="Company Filter" className="field-cont-div">
                      <CircularGrid />
                      <select
                        onChange={(e) => setToggleCompany(e.target.value)}
                        value={toggleCompany}
                        className="attendance-input-field width-15vw   date-field"
                      // className="dropdown font-size-subheading  "
                      >
                        <option value="null">Select Sub Company</option>
                        {siteSubCompanyList.map((company) => (
                          <option value={company.id}>{company.title}</option>
                        ))}
                        ;
                      </select>

                      <hr className="field-cont-hr" />
                    </div>
                    : null
                  }
                  {siteRole === "SiteManagement" ? "" :

                    <Projects toggleProject={project} setToggleProject={setProject} />

                  }

                  <div title="Letter For" className="field-cont-div">
                    <CircularGrid />
                    <select
                      onChange={(e) => setWorkperformedby(e.target.value)}
                      value={workperformedby}
                      className="attendance-input-field width-15vw   date-field"
                    // className="dropdown font-size-subheading  "
                    >
                      <option className='' value="null">Letter For</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    <hr className="field-cont-hr" />
                  </div>


                  <LetterStatus selectedLetterStatus={selectedLetterStatus} setLetterStatus={setLetterStatus} />

                  <LetterType setLetterType={setLetterType} lettertype={lettertype} />












                  <div title='Search Filter' className="field-cont-div">
                    <SearchSmall />
                    <input className="attendance-input-field width-15vw"
                      placeholder='Letter Id/No.'
                      type="text"
                      value={selectedNo}
                      onChange={(e) => setNo(e.target.value)} />

                    <hr className="field-cont-hr" />
                  </div>

                  <div title="Status Filter" className="field-cont-div">
                    <CircularGrid />
                    <select
                      id="status"
                      name="status"
                      placeholder="Status"
                      value={selectedLetterStatus}
                      onChange={(e) => setLetterStatus(e.target.value)}
                      className={`attendance-input-field width-10vw `}
                    >
                      <option value="null">Select a Status</option>

                      <option value="pending">Pending</option>

                      <option value="in-progress">InProgress</option>
                      <option value="partially-close">Partially Close</option>

                      <option value="close">Close</option>
                    </select>
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="From Date Filter" className="field-cont-div">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="From Date"
                      type="date"
                      value={selectedFromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="To Date Filter" className="field-cont-div ">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="To Date"
                      type="date"
                      value={selectedToDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                </div>
                <div className="btn-cont">
                  <DownloadTableExcel
                    filename="Site-ProjectDetails"
                    sheet="projectDetails"
                    currentTableRef={tableRef.current}
                  >
                    <button className="model-button font-weight500   model-button-print">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                        <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                          <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                        </g>
                      </svg>
                    </button>
                  </DownloadTableExcel>
                  {siteRole === "BD" ? "" :

                    <AddTrackRecordDetails getProjects={getLetters} projectList={projectList} projectId={project} />

                  }

                </div>
              </div>
            </div>
            <div className='filter-showing'>
              {/* <div>Letter Records:</div> */}
              <div></div>
              <div>Showing {trackRecordList.length} of {trackRecordList.length} </div>
            </div>

            <div className="table-css-white-background">


              <div className='table-box height-73vh'>
                <div >
                  <table ref={tableRef} className="table-css">
                    <thead className='table-heading'>
                      <tr className='custom-table-head-tr'>
                        <th className='table-heading-text'>S.no.</th>
                        {!projectID ? <th className='table-heading-text'>Project</th> : ""}
                        <th className='table-heading-text'>Letter No.</th>
                        <th className='table-heading-text'>Exchange</th>
                        <th className='table-heading-text'>Date</th>
                        <th className='table-heading-text'>Subject</th>
                        <th className='table-heading-text'>From</th>
                        <th className='table-heading-text'>To</th>
                        <th className='table-heading-text'>Type</th>
                        <th className='table-heading-text'>Associated Letter</th>
                        <th className='table-heading-text'>Status</th>
                        {siteRole === "BD" ? "" :
                          <>
                            <th className='table-heading-text'>Attachments</th>
                            {siteRole === 'Admin' ? <th className='table-heading-text'>Delete</th> : ""}
                          </>
                        }
                      </tr>
                    </thead>
                    {buffer ? (
                      <div className="spinner-small"> </div> //buffering logic 
                    ) : error ? (
                      <p>Error fetching data: {error.message}</p>
                    ) :
                      <tbody>
                        {filteredData.map((i, index) => (
                          <React.Fragment key={index}>
                            <tr className="custom-table-head-td">
                              <td className='table-body'>{index + 1}</td>



                              {!projectID ? <td className='table-body'><div style={{ width: "120px", whiteSpace: "normal" }}>{i?.site_prcode}-{i.project_short_name}</div></td> : ""}
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "120px", whiteSpace: "normal" }}>{i.letter_num}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'>{i.letterExchange ? i.letterExchange : "-"}</td>
                              <td className='table-body'>{formattedDateLong(i.letter_date)}</td>
                              <td className='table-body'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.subject ? i.subject : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.letter_from ? i.letter_from : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.letter_to ? i.letter_to : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'>{i.lettertype}</td>
                              <td className='table-body'> {i.associated_letters_get.length > 0
                                ? i.associated_letters_get[0].letter_num
                                : <p className=''>-</p>}</td>
                              {/* <td className='align-center'>{i.letter_date ? formattedDateLong(i.letter_date) : "---"}</td> */}
                              <td className='table-body form-text-trasformation-uppercase'>{i.status}</td>
                              {siteRole === "BD" ? "" :
                                <>
                                  <td className='table-body'>
                                    <UpdateTrackRecordDetails
                                      i={i}
                                      getProjects={getLetters}
                                      projectList={projectList}
                                      projectId={i.project}
                                      letterType={i.lettertype}
                                      workperformedby={i.workperformedby}
                                    // associatedletters={i.associated_letters_get[0].letter_num }
                                    />

                                  </td>
                                  {siteRole === 'Admin' ? <td className='table-body'><DeleteTrackAndLetterDetails i={i} getLetterDetail={getLetters} /></td> : ""}
                                </>
                              }
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>}
                  </table>
                </div>
              </div>

            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default LetterTracking
