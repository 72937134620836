import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from '../../../config/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AddwithBlueCircle, AddwithWhiteCircle, Eye, Reciept, UpdatePencil } from '../../AllSvg';
import { ViewFile, ViewReciept } from '../../CustomFunctions';
import { AddPaymentReceipt } from './PaymentReceipts';


const InvoiceList = ({ project }) => {

  const [show, setShow] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const handleShow = () => {
    setShow(true);
    getInvoice();
  }
  const handleClose = () => {
    setShow(false);
  }

  const getInvoice = async () => {

    try {
      const response = await axios.get(`${BASE_URL}/siteproject/consultant-invoiceBy-projectfilter/${project.id}/null/null/`);
      setInvoice(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const navigate = useNavigate();
  const p_id = project.id;
  const ViewInvoice = (invoice_id, month, invoice_number, year) => {
    navigate(`/${urlParts[3]}/ConsultancyInvoice/${p_id}/${invoice_id}/`, { state: { invoice_id, p_id, month, year, invoice_number } }
    );

  };
  const ViewPaymentReceipt = (invoice_id, month, invoice_number, year) => {
    navigate(`/${urlParts[3]}/PrintInvoice/${p_id}/${invoice_id}/`, { state: { invoice_id, p_id, month, year, invoice_number } }
    );

  };


  return (
    <>
      <button
        className=""
        title="View Invoice"
        onClick={handleShow}
      >
        <Eye />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title> Invoice List </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-css-white-background">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr-with-less-padding ">
                  <th className='align-left'>S.No.</th>
                  <th className='align-center'>Invoice Number</th>
                  <th className='align-center'>Month</th>
                  <th className='align-center'>Year</th>
                  <th className='align-right'>View</th>
                  <th className='align-right'>Payment Reciept</th>
                </tr>
              </thead>
              <tbody>
                {invoice.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className='custom-table-head-td'>
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.invoice_number}</td>
                      <td className="align-center">{i.month}</td>
                      <td className="align-center">{i.year}</td>

                      <td className="align-right ">
                        <button
                          onClick={() => ViewInvoice(i.id, i.month, i.invoice_number, i.year)}
                          title="View BOQ Details"
                        >
                          <Eye />
                        </button>
                      </td>
                      <td className="align-center width-5vw flex-row justify-between">

                        {/* <button
                            onClick={() => ViewInvoice(i.id, i.month, i.invoice_number, i.year)}
                            title="Update Payment Reciept"
                            className='model-edit-button'
                          >
                            <UpdatePencil />
                          </button> */}
                        <button
                          onClick={() => ViewPaymentReceipt(i.id, i.month, i.invoice_number, i.year)}
                          title="View BOQ Details"
                          className=''
                        >
                          <Reciept />
                        </button>
                        <ViewReciept filename={i.invoice_number} filesrc={i.invoice_number} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body >
      </Modal >
    </>
  )
}

export default InvoiceList;