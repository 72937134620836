import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import { AddBreakdownOfCosts, AddContingencies, AddDutyTravel, AddEmployeeBOQ, AddOfficeFurniture, AddOfficeFurnitureHeading, AddOfficeRent, AddOfficeSupplies, AddReportsPrinting, AddRoadSurveyEquipment, AddTransportation, DeleteBreakdownOfCosts, DeleteEmployeeBOQ, DeleteIDutyTravel, DeleteITransportation, DeleteOfficeFurniture, DeleteOfficeRent, DeleteOfficeSupplies, DeleteReportsPrinting, DeleteRoadSurveyEquipment, DeleteSubprofessionalStaff, DeleteSupportStaff, DeleteUpdateContingenciest, UpdateAddOfficeFurnitureHeading, UpdateBreakdownOfCosts, UpdateContingenciest, UpdateDutyTravel, UpdateEmployeeBOQ, UpdateOfficeFurniture, UpdateOfficeRent, UpdateOfficeSupplies, UpdateReportsPrinting, UpdateRoadSurveyEquipment, UpdateTransportation } from './InvoiceModel';
import { useParams } from 'react-router-dom';
import usePermission from "../../../config/permissions";
import { calculateTotal, formatCurrencyIndian } from "../../CustomFunctions";








const SummaryTable = ({ costData, subTotal, tax, totalCost }) => (
    <div className="table-css-background">

        <div className='heads-div'>
            <th className="">Summary of Costs</th>
            {/* <AddSupportStaff id={id} getSupportStaff={getBreakdownCost}/> */}
        </div>
        <table className="nested-table-lightblue">
            <thead>
                <tr>
                    <th style={{ borderBottom: '1px solid #ddd' }}>No.</th>
                    <th style={{ borderBottom: '1px solid #ddd' }}>Description</th>
                    <th style={{ borderBottom: '1px solid #ddd' }} className="align-right">Amount (Rs.)</th>
                </tr>
            </thead>
            <tbody>
                {costData.map((item, index) => (
                    <tr key={index}>
                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{formatCurrencyIndian(item.amount)}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="2" style={{ textAlign: 'right', padding: '8px', fontWeight: 'bold' }}>Sub Total:</td>
                    <td style={{ padding: '8px', textAlign: 'right' }}>{formatCurrencyIndian(subTotal)}</td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ textAlign: 'right', padding: '8px', fontWeight: 'bold' }}>Goods and Services Tax:</td>
                    <td style={{ padding: '8px', textAlign: 'right' }}>{formatCurrencyIndian(tax)}</td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ textAlign: 'right', padding: '8px', fontWeight: 'bold' }}>Total Cost (Including Tax):</td>
                    <td style={{ padding: '8px', textAlign: 'right' }}><b>{formatCurrencyIndian(totalCost)}</b></td>
                </tr>
            </tfoot>
        </table>
    </div>
);



const BreakdownOfCosts = ({ id, props, breakdown, getBreakdownCost }) => {
    const { siteRole } = usePermission();



    return (
        <div className="table-css-background" >
            <div className='heading-div'>
                <h3 className="main-heading"> Appendix C-3: Breakdown of Costs</h3>
                <div className="hide-on-print">
                    <AddBreakdownOfCosts id={id} getBreakdowncost={getBreakdownCost} />
                </div>

            </div>
            <th className="">1. REMUNEATION FOR LOCAL PROFESSIONAL STAFF</th>
            <table className="nested-table-lightblue">
                <thead>
                    <div className='pre-heading width-10vw'>
                        A. Key Personal
                    </div>
                    <tr>
                        <th>Sr.No</th>
                        <th>Key Personal</th>
                        <th>Name</th>
                        <th className=''>MM Construction Period</th>
                        <th className=''>MM OM Period</th>
                        <th className=''>No. of Man-Months</th>
                        <th className=''>Rate (Rs.)</th>
                        <th className=''>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {breakdown.filter((e) => e.professional_type === "Key Professional").map((item, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.designation_name}</td>
                                <td>
                                    {item.employee_name_details && item.employee_name_details.length > 0
                                        ? item.employee_name_details.map((e, index) => (
                                            <React.Fragment key={index}>
                                                <span>{e.name}</span>
                                                {index < item.employee_name_details.length - 1 ? ", " : ""}
                                            </React.Fragment>
                                        ))
                                        : "TBN"
                                    }</td>
                                {/* <td>{item.professional_type}</td> */}
                                <td>{item.MM_construction_period}</td>
                                <td>{item.MM_OM_period}</td>

                                <td>{item.MM_construction_period + item.MM_OM_period}</td>
                                <td className="align-right">{formatCurrencyIndian(Number(item.rate))}</td>
                                <td className="align-right">{formatCurrencyIndian((item.MM_construction_period + item.MM_OM_period) * item.rate)}</td>
                                <td className="hide-on-print flex-row">
                                    <UpdateBreakdownOfCosts i={item} id={id} getBreakdownCost={getBreakdownCost} />
                                    {siteRole === "BD" || siteRole === "Account" ? "" :
                                        <DeleteBreakdownOfCosts i={item} getBreakdownCost={getBreakdownCost} />
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="7" className="total-label">Sub Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(breakdown.filter((e) => e.professional_type === "Key Professional").reduce(
                                (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
                                0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const SubprofessionalStaff = ({ id, props, breakdown, getBreakdownCost }) => {
    const { siteRole } = usePermission();

    return (
        <div className="table-css-background">
            {/* <h3 className="main-heading"> Appendix C-3: Breakdown of Costs</h3> */}
            <div className='heads-div'>
                <th className="">B. Sub-professional Staff</th>
                {/* <AddSubprofessionalStaff /> */}
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Sub-Professional</th>
                        <th>Name</th>
                        <th className=''>MM Construction Period</th>
                        <th className=''>MM OM Period</th>
                        <th className=''>No. of Man-Months</th>
                        <th className=''>Rate (Rs.)</th>
                        <th className=''>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                    {/* <div className='pre-heading'>
                        A. Key Personal
                    </div> */}
                </thead>
                <tbody>
                    {breakdown.filter((e) => e.professional_type === "Sub Professional").map((item, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.designation_name}</td>
                                <td>  {item.employee_name_details && item.employee_name_details.length > 0
                                    ? item.employee_name_details.map((e, index) => (
                                        <React.Fragment key={index}>
                                            <span>{e.name}</span>
                                            {index < item.employee_name_details.length - 1 ? ", " : ""}
                                        </React.Fragment>
                                    ))
                                    : "TBN"
                                }</td>
                                {/* <td>{item.professional_type}</td> */}
                                <td>{item.MM_construction_period}</td>
                                <td>{item.MM_OM_period}</td>

                                <td>{item.MM_construction_period + item.MM_OM_period}</td>
                                <td className="align-right">{formatCurrencyIndian(item.rate)}</td>
                                <td className="align-right">{formatCurrencyIndian((item.MM_construction_period + item.MM_OM_period) * item.rate)}</td>
                                <td className="hide-on-print flex-row">
                                    <UpdateBreakdownOfCosts i={item} id={id} getBreakdownCost={getBreakdownCost} />
                                    {siteRole === "BD" || siteRole === "Account" ? "" :
                                        <DeleteSubprofessionalStaff i={item} getBreakdownCost={getBreakdownCost} />
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="7" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(breakdown.filter((e) => e.professional_type === "Sub Professional").reduce(
                                (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
                                0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                    <tr>
                        <td colSpan="9"><b>TBN = To Be Named</b></td>

                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const SupportStaff = ({ id, props, breakdown, getBreakdownCost }) => {

    const { siteRole } = usePermission();




    return (
        <div className="table-css-background">
            {/* <th className="">1. REMUNEATION FOR LOCAL PROFESSIONAL STAFF</th> */}
            <div className='heads-div'>
                <th className="">2. Support Staff</th>
                {/* <AddSupportStaff id={id} getSupportStaff={getBreakdownCost}/> */}
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Support Staff</th>
                        <th>Name</th>
                        <th className=''>MM Construction Period</th>
                        <th className=''>MM OM Period</th>
                        <th className=''>No. of Man-Months</th>
                        <th className=''>Rate (Rs.)</th>
                        <th className=''>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                    {/* <div className='pre-heading'>
                        A. Key Personal
                    </div> */}
                </thead>
                <tbody>
                    {breakdown.filter((e) => e.professional_type === "Support Staff").map((item, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.designation_name}</td>
                                <td>    {item.employee_name_details && item.employee_name_details.length > 0
                                    ? item.employee_name_details.map((e, index) => (
                                        <React.Fragment key={index}>
                                            <span>{e.name}</span>
                                            {index < item.employee_name_details.length - 1 ? ", " : ""}
                                        </React.Fragment>
                                    ))
                                    : "TBN"
                                }</td>
                                {/* <td>{item.professional_type}</td> */}
                                <td>{item.MM_construction_period}</td>
                                <td>{item.MM_OM_period}</td>

                                <td>{item.MM_construction_period + item.MM_OM_period}</td>
                                <td className="align-right">{formatCurrencyIndian(Number(item.rate))}</td>
                                <td className="align-right">{formatCurrencyIndian((item.MM_construction_period + item.MM_OM_period) * item.rate)}</td>
                                <td className="hide-on-print flex-row">
                                    <UpdateBreakdownOfCosts i={item} id={id} getBreakdownCost={getBreakdownCost} />
                                    {siteRole === "BD" || siteRole === "Account" ? "" :
                                        <DeleteSupportStaff i={item} id={id} getBreakdownCost={getBreakdownCost} />
                                    }
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="7" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(breakdown.filter((e) => e.professional_type === "Support Staff").reduce(
                                (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
                                0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};



const Transportation = ({ props, transportation, getTransportation, id }) => {

    const { siteRole } = usePermission();

    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <h2>3. Transportation (Fixed rate on rental basis)</h2>

                <div className="hide-on-print">
                    <AddTransportation id={id} getTransportation={getTransportation} />
                </div>
            </div>
            <p>
                The vehicles provided by the Consultants shall include the cost for rental, drivers, operation, maintenance, repairs, insurance, etc. for all complete approx. 3000km/month run.
            </p>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th rowSpan="2">Sr.No</th>
                        <th rowSpan="2">Description of Vehicles</th>
                        <th colSpan="2" className='align-center'>Qty. (No. of vehicle month)</th>
                        <th rowSpan="2">Total</th>
                        <th rowSpan="2">Rate / Vehicle - Month (Rs.)</th>
                        <th rowSpan="2" >Amount (Rs.)</th>
                        <th rowSpan="2" className="hide-on-print align-center">Action</th>
                    </tr>
                    <tr>

                        <th>During Construction Period</th>
                        <th>During DLP and O&M Period</th>

                    </tr>
                </thead>
                <tbody>
                    {transportation.map((item, index) => (

                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.vehicle_description}</td>
                            <td>{item.during_construction_period}</td>
                            <td>{item.during_dlp_OM_period}</td>
                            <td>{item.during_construction_period + item.during_dlp_OM_period}</td>
                            <td className="align-right">{formatCurrencyIndian(Number(item.rate))}</td>
                            <td className="align-right">{formatCurrencyIndian((item.during_construction_period + item.during_dlp_OM_period) * (item.rate))}</td>
                            <td className="hide-on-print flex-row">
                                <UpdateTransportation i={item} getTransportation={getTransportation} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteITransportation i={item} getTransportation={getTransportation} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(transportation.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const DutyTravel = ({ props, id, dutyTravel, getDutyTravel }) => {

    const { siteRole } = usePermission();


    return (
        <>
            <div className="table-css-background ">
                <div className='heads-div'>
                    <div>
                        <h2>4. Duty Travel to Site (Fixed Costs) (For all Lengths of projects):</h2>
                        <p>Professional and Sub-Professional Staff</p>
                    </div>
                    <div className="hide-on-print">
                        <AddDutyTravel id={id} getDutyTravel={getDutyTravel} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th>Sr.no</th>
                            <th>Trips</th>
                            <th>Number of minimum Trips</th>
                            <th>Rate per trip (Rs.)</th>
                            <th>Amount (Rs.)</th>
                            <th className="hide-on-print align-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dutyTravel.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.trips}</td>
                                <td>{item.num_of_min_trip}</td>
                                <td className="align-right">{formatCurrencyIndian(item.rate_per_trip)}</td>
                                <td className="align-right">{formatCurrencyIndian(item.num_of_min_trip * item.rate_per_trip)}</td>
                                <td className="hide-on-print flex-row">
                                    <UpdateDutyTravel i={item} getDutyTravel={getDutyTravel} />
                                    {siteRole === "BD" || siteRole === "Account" ? "" :
                                        <DeleteIDutyTravel i={item} getDutyTravel={getDutyTravel} />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" className="total-label">Total:</td>
                            <td className="align-right">
                                {formatCurrencyIndian(dutyTravel.reduce(
                                    (total, item) => total + (item.num_of_min_trip * item.rate_per_trip), 0
                                ))}
                            </td>
                            <td className="hide-on-print"></td>
                        </tr>
                    </tfoot>
                </table>
                <p className="note">
                    * Rate quoted includes Hotel charges, travel cost etc. complete.
                </p>
            </div>
        </>
    );
};



const OfficeRent = ({ props, id, getOfficeRent, officeRent }) => {

    const { siteRole } = usePermission();

    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <div>
                    <h2>5. Office Rent (Fixed Costs)</h2>
                    <p>
                        (Minimum 200 sqm area of office shall be rented. The rent cost includes electricity and water charges, maintenance, cleaning, repairs, etc. complete.)
                    </p>
                </div>
                <div className="hide-on-print">
                    <AddOfficeRent id={id} getOfficeRent={getOfficeRent} />
                </div>
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.no</th>
                        <th className="">No. of Months</th>
                        <th className="">Rate / Month (Rs.)</th>
                        <th className="">Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {officeRent.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td className="">{item.num_of_months}</td>
                            <td className="align-right">{formatCurrencyIndian(item.rate_per_month)}</td>
                            <td className="align-right">{formatCurrencyIndian(item.num_of_months * item.rate_per_month)}</td>
                            <td className="hide-on-print flex-row justify-center">
                                <UpdateOfficeRent i={item} getOfficeRent={getOfficeRent} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteOfficeRent i={item} getOfficeRent={getOfficeRent} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(officeRent.reduce(
                                (total, item) => total + (item.num_of_months * item.rate_per_month), 0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const OfficeSuppliess = ({ props, id, officeSupplies, getOfficeSupplies }) => {
    const { siteRole } = usePermission();



    return (
        <>
            <div className="table-css-background">
                <div className='heads-div'>
                    <h2>6. Office Supplies, Utilities and
                        <br />
                        Communication (Fixed Costs)</h2>
                    <div className="hide-on-print">
                        <AddOfficeSupplies id={id} getOfficeSupplies={getOfficeSupplies} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Item</th>
                            <th>Months</th>
                            <th>Monthly Rate (Rs.)</th>
                            <th>Amount (Rs.)</th>
                            <th className="hide-on-print align-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {officeSupplies.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.item}</td>
                                <td>{item.months}</td>
                                <td className="align-right">{formatCurrencyIndian(item.monthly_rate)}</td>
                                <td className="align-right">{formatCurrencyIndian(item.months * item.monthly_rate)}</td>
                                <td className="hide-on-print flex-row justify-center">
                                    <UpdateOfficeSupplies i={item} getOfficeSupplies={getOfficeSupplies} />
                                    {siteRole === "BD" || siteRole === "Account" ? "" :
                                        <DeleteOfficeSupplies i={item} getOfficeSupplies={getOfficeSupplies} />
                                    }
                                </td>

                            </tr>
                        ))}

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" className="total-label">Total:</td>
                            <td className="align-right">
                                {formatCurrencyIndian(officeSupplies.reduce(
                                    (total, item) => total + (item.months * item.monthly_rate), 0
                                ))}
                            </td>
                            <td className="hide-on-print"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
};


const OfficeFurniture = ({ props, id, officeFurniture, officeFurnitureheading, getOfficeFurniture, getOfficeFurnitureheading }) => {
    const { siteRole } = usePermission();



    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <div>
                    <h2>7. Office Furniture and Equipment (Rental) [Fixed Monthly Cost]</h2>
                    {officeFurnitureheading[0]?.BOQoffice_furnitur_equipment}
                    {" "}
                    {officeFurnitureheading.length === 0 ?
                        <AddOfficeFurnitureHeading id={id} getOfficeFurnitureheading={getOfficeFurnitureheading} />
                        : <UpdateAddOfficeFurnitureHeading i={officeFurnitureheading[0]} id={id} getOfficeFurnitureheading={getOfficeFurnitureheading} />
                    }
                    {/* <p>
                        The cost shall include rental charges towards all such furniture and equipment as required for proper functioning of office. <br /> Office furniture shall include executive tables, chairs, visitor chairs, steel almirahs, computer furniture, conference table etc. <br /> Office equipment shall include as a minimum of telephone (2 external & 10 internal lines), photocopier (15ppm, 12000 copies per month with A3 & A4 input)
                        <br /> fax machine, PCs (5 No., Intel Core 2 Duo E8300, 19” colour TFT, Cache-6MB, RAM-2 GB, HDD-250 GB, DVD Writer, Key board, optical scroll mouse, MS-Windows Vista Business, pre loaded anti virus etc.), <br /> laser printers (2 no., 14 ppm, 266 MHZ, 5000 pages per month, 600x600 dpi or better etc., Engineering Plan printer (1 no.), binding machine (1 no.), plotter A0 size, overhead projector, AC (4 no., 1.5 Ton), Water Coolers (as required) etc.
                    </p> */}
                </div>
                <div className="hide-on-print">
                    <div className="hide-on-print">
                        <AddOfficeFurniture id={id} getOfficeFurniture={getOfficeFurniture} />
                    </div>
                </div>
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.no</th>
                        <th>No. of Months</th>
                        <th>Rate / Month (Rs.)</th>
                        <th>Amount (Rs.)</th>
                        <th className="hide-on-print flex-row justify-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {officeFurniture.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.num_of_months}</td>
                            <td className="align-right">{formatCurrencyIndian(item.rate_per_month)}</td>
                            <td className="align-right">{formatCurrencyIndian(item.num_of_months * item.rate_per_month)}</td>
                            <td className="hide-on-print flex-row justify-center">
                                <UpdateOfficeFurniture i={item} getOfficeFurniture={getOfficeFurniture} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteOfficeFurniture i={item} getOfficeFurniture={getOfficeFurniture} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(officeFurniture.reduce(
                                (total, item) => total + (item.num_of_months * item.rate_per_month), 0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const ReportsPrinting = ({ props, id, reportsPrinting, getReportsPrinting }) => {

    const { siteRole } = usePermission();


    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <h2>8. Reports and Document Printing</h2>
                <div className="hide-on-print">
                    <AddReportsPrinting id={id} getReportsPrinting={getReportsPrinting} />
                </div>
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Description</th>
                        <th>No. of Reports</th>
                        <th>No. of Copies/Report</th>
                        <th>Total Nos. of copies</th>
                        <th>Rate per Copy (Rs.)</th>
                        <th>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {reportsPrinting.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.description}</td>
                            <td>{item.num_of_reports}</td>
                            <td>{item.num_of_copies_per_report}</td>
                            <td>{item.num_of_reports * item.num_of_copies_per_report}</td>
                            <td className="align-right">{formatCurrencyIndian(item.rate_per_copy)}</td>
                            <td className="align-right">{formatCurrencyIndian((item.num_of_reports * item.num_of_copies_per_report) * (item.rate_per_copy))}</td>
                            <td className="hide-on-print flex-row justify-center">
                                <UpdateReportsPrinting i={item} getReportsPrinting={getReportsPrinting} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteReportsPrinting i={item} getReportsPrinting={getReportsPrinting} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(reportsPrinting.reduce(
                                (total, item) => total + ((item.num_of_reports * item.num_of_copies_per_report) * (item.rate_per_copy)), 0
                            ))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};


const RoadSurveyEquipment = ({ id, roadSurveyEquipment, getRoadSurveyEquipment, toggle }) => {
    const { siteRole } = usePermission();

    // Calculate the total amount based on the toggle value
    const calculateTotal = () => {
        return roadSurveyEquipment.reduce((total, item) => {
            const amount = toggle
                ? item.num_of_times_survey_concluded * item.rate_per_km_times
                : item.KM * item.num_of_times_survey_concluded * item.rate_per_km_times;
            return total + amount;
        }, 0);
    };

    return (
        <div className="table-css-background box-outline">
            <div className='heads-div'>
                <h2>9. Road Survey Equipment</h2>
                <div className="hide-on-print">
                    <AddRoadSurveyEquipment id={id} getRoadSurveyEquipment={getRoadSurveyEquipment} />
                </div>
            </div>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Description</th>
                        {!toggle && <th>KM.</th>} {/* Only show 'KM' column if toggle is false */}
                        <th>Nos. of times survey to be concluded</th>
                        <th>Rate per No. of times* survey to be conducted (Rs.)</th>
                        <th>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {roadSurveyEquipment.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.description}</td>
                            {!toggle && <td>{item.KM}</td>} {/* Only show 'KM' cell if toggle is false */}
                            <td>{item.num_of_times_survey_concluded}</td>
                            <td className="align-right">{formatCurrencyIndian(item.rate_per_km_times)}</td>
                            <td className="align-right">
                                {toggle
                                    ? formatCurrencyIndian(item.num_of_times_survey_concluded * item.rate_per_km_times)
                                    : formatCurrencyIndian(item.KM * item.num_of_times_survey_concluded * item.rate_per_km_times)
                                }
                            </td>
                            <td className="hide-on-print flex-row justify-center">
                                <UpdateRoadSurveyEquipment i={item} getRoadSurveyEquipment={getRoadSurveyEquipment} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteRoadSurveyEquipment i={item} getRoadSurveyEquipment={getRoadSurveyEquipment} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={toggle ? 4 : 5} className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(calculateTotal())}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
            <p className="note">
                * Proportional reduction w.r.t. total length and actual done at site
            </p>
        </div>
    );
};

const Contingencies = ({ props, id, contingencies, getContingencies }) => {

    const { siteRole } = usePermission();


    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <h2>10. Contingencies</h2>
                <div className="hide-on-print">
                    <AddContingencies id={id} getContingencies={getContingencies} />
                </div>
            </div>
            <p>
                A fixed amount of Indian Rupees ONE MILLION shall be included in the Financial Proposal. The provisions of Contingency shall be operated with the specific approval from the Authority.
            </p>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contingencies.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.description}</td>
                            <td>{item.unit}</td>
                            <td className="align-right">{formatCurrencyIndian(item.ammount)}</td>
                            <td className="hide-on-print flex-row justify-center">
                                <UpdateContingenciest i={item} getContingencies={getContingencies} />
                                {siteRole === "BD" || siteRole === "Account" ? "" :
                                    <DeleteUpdateContingenciest i={item} getContingencies={getContingencies} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3" className="total-label">Total:</td>
                        <td className="align-right">
                            {formatCurrencyIndian(contingencies.reduce((total, item) => total + parseFloat(item.ammount || 0), 0))}
                        </td>
                        <td className="hide-on-print"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};









const PrintBOQformat = React.forwardRef((props, ref) => {
    let { id } = useParams();
    console.log("props")
    console.log(props)
    const [breakdown, setBreakdown] = useState([]);
    const getBreakdownCost = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqemployeebyproject/${id}/`
            );
            setBreakdown(res.data);
        } catch (err) {
            console.error("Error fetching breakdown cost", err);
        }
    };

    useEffect(() => {
        getBreakdownCost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const [transportation, setTransportation] = useState([]);

    const getTransportation = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqtransportbyproject/${id}`);
            setTransportation(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getTransportation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [employee, setEmployee] = useState([]);
    const getEmployee = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqemployeebyproject/${id}`);
            setEmployee(res.data);
        } catch (err) { }
    };

    useEffect(() => {
        getEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const [dutyTravel, setDutyTravel] = useState([]);

    const getDutyTravel = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqtravelbyproject/${id}/`
            );
            setDutyTravel(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getDutyTravel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [officeRent, setOfficeRent] = useState([]);

    const getOfficeRent = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficerentbyproject/${id}/`
            );
            setOfficeRent(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeRent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [officeSupplies, setOfficeSupplies] = useState([]);

    const getOfficeSupplies = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficesuppliesbyproject/${id}/`);
            setOfficeSupplies(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeSupplies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [officeFurniture, setOfficeFurniture] = useState([]);
    const [officeFurnitureheading, setOfficeFurnitureheading] = useState([]);

    const getOfficeFurniture = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqofficefurnitureandequipmentbyproject/${id}/`);
            setOfficeFurniture(res.data);
        } catch (err) { }
    };

    const getOfficeFurnitureheading = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqheadingtablebyproject/${id}/`);
            setOfficeFurnitureheading(res.data);
        } catch (err) { }
    };
    useEffect(() => {

        // getdocument();
        getOfficeFurniture();
        getOfficeFurnitureheading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [reportsPrinting, setReportsPrinting] = useState([]);

    const getReportsPrinting = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqreportsanddocprintbyproject/${id}/`);
            setReportsPrinting(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getReportsPrinting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const [roadSurveyEquipment, setRoadSurveyEquipment] = useState([]);
    const [toggle, settoggle] = useState(false);

    const getRoadSurveyEquipment = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqroadsurveyequipmentbyproject/${id}`
            );
            setRoadSurveyEquipment(res.data);
            settoggle(res.data.some(road => Number(road.KM) === 0))

        } catch (err) { }
    };

    useEffect(() => {
        getRoadSurveyEquipment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle]);



    const [contingencies, setContingencies] = useState([]);

    const getContingencies = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqcontingenciesbyproject/${id}`
            );
            setContingencies(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getContingencies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const KeyPROFESSIONALTotal = breakdown.filter((e) => e.professional_type === "Key Professional").reduce(
        (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
        0
    )


    const SubProfessionalTotal = breakdown.filter((e) => e.professional_type === "Sub Professional").reduce(
        (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
        0
    )

    const SupportStaffTotal = breakdown.filter((e) => e.professional_type === "Support Staff").reduce(
        (total, item) => total + (item.rate * (item.MM_construction_period + item.MM_OM_period)),
        0
    )

    const TransportationTotal = transportation.reduce(
        (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
        0
    )

    const DutyTotal = dutyTravel.reduce(
        (total, item) => total + (item.num_of_min_trip * item.rate_per_trip), 0
    )

    const OfficeRentTotal = officeRent.reduce(
        (total, item) => total + (item.num_of_months * item.rate_per_month), 0
    )

    const OfficeSupplies = officeSupplies.reduce(
        (total, item) => total + (item.months * item.monthly_rate), 0)

    const OfficeFurnitureTotal = officeFurniture.reduce(
        (total, item) => total + (item.num_of_months * item.rate_per_month), 0)


    const Reportsprinting = reportsPrinting.reduce(
        (total, item) => total + ((item.num_of_reports * item.num_of_copies_per_report) * (item.rate_per_copy)), 0
    )

    const RoadSurveyEquipmentTotal = roadSurveyEquipment.reduce((total, item) => {
        const amount = toggle
            ? item.num_of_times_survey_concluded * item.rate_per_km_times
            : item.KM * item.num_of_times_survey_concluded * item.rate_per_km_times;
        return total + amount;
    }, 0);

    const ContingenciesTotal = contingencies.reduce((total, item) => total + parseFloat(item.ammount || 0), 0)



    // Sample data from the image
    const costData = [
        { description: 'Remuneration for Local Professional Staff', amount: KeyPROFESSIONALTotal + SubProfessionalTotal },
        { description: 'Supporting Staff', amount: SupportStaffTotal },
        { description: 'Transportation', amount: TransportationTotal },
        { description: 'Duty Travel to Site', amount: DutyTotal },
        { description: 'Office Rent', amount: OfficeRentTotal },
        { description: 'Office Supplies, Utilities, and Communication', amount: OfficeSupplies },
        { description: 'Office Furniture and Equipment', amount: OfficeFurnitureTotal },
        { description: 'Reports and Document Printing', amount: Reportsprinting },
        { description: 'Road Survey Equipment', amount: RoadSurveyEquipmentTotal },
        { description: 'Contingencies', amount: ContingenciesTotal },
    ];

    const subTotal = costData.reduce((total, item) => total + item.amount, 0);

    // Extract the tax value from the last item in the list
    const tax = subTotal * 0.18;

    // Calculate the total cost including tax
    const totalCost = subTotal + tax;



    return (
        <div className='printSlip-container' ref={ref}>

            <SummaryTable costData={costData} subTotal={subTotal} tax={tax} totalCost={totalCost} />


            <BreakdownOfCosts id={id} breakdown={breakdown} getBreakdownCost={getBreakdownCost} props={props} />
            <SubprofessionalStaff id={id} breakdown={breakdown} getBreakdownCost={getBreakdownCost} props={props} />
            <SupportStaff id={id} breakdown={breakdown} getBreakdownCost={getBreakdownCost} props={props} />
            <Transportation props={props} id={id} transportation={transportation} getTransportation={getTransportation} />

            <DutyTravel props={props} id={id} dutyTravel={dutyTravel} getDutyTravel={getDutyTravel} />
            <OfficeRent props={props} id={id} officeRent={officeRent} getOfficeRent={getOfficeRent} />
            <OfficeSuppliess props={props} id={id} officeSupplies={officeSupplies} getOfficeSupplies={getOfficeSupplies} />
            <OfficeFurniture props={props} id={id} officeFurniture={officeFurniture} officeFurnitureheading={officeFurnitureheading} getOfficeFurniture={getOfficeFurniture} getOfficeFurnitureheading={getOfficeFurnitureheading} />
            <ReportsPrinting props={props} id={id} reportsPrinting={reportsPrinting} getReportsPrinting={getReportsPrinting} />
            <RoadSurveyEquipment props={props} id={id} roadSurveyEquipment={roadSurveyEquipment} toggle={toggle} getRoadSurveyEquipment={getRoadSurveyEquipment} />
            <Contingencies props={props} id={id} contingencies={contingencies} getContingencies={getContingencies} />
        </div>
    )
});

const Invoiceformate = (i) => {
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    const handleBeforePrint = () => {
        setIsPrinting(true);
    };

    const handleAfterPrint = () => {
        setIsPrinting(false);
    };
    console.log("isPrinting")
    console.log(isPrinting)

    return (
        <div className='flex-column flex-center  slip-container'>
            <div className='printSlip-width flex-row justify-evenly button-margin'>
                {/* <button className="model-button  font-weight500    model-button-cancel font-size-heading" onClick={(e) => { backroute(firsttoggle, secondtoggle, accfirsttoggle) }}>Back</button> */}
                <ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                    trigger={() => (
                        <button className="model-button model-button-black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                            </svg> Print
                        </button>
                    )}
                />
            </div>
            <PrintBOQformat ref={ref} isPrinting={isPrinting} />
        </div>
    );
}



export default Invoiceformate
