// ************ ==== >>>> Page <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword.jsx";
import Resetpassword from "../pages/website/Resetpassword.jsx";
import Dashboardnavbar from "../layout/Dashboardnavbar.jsx";

// ************ ==== >>>> Components <<<< ==== ************//
import Userdetailsmain from "../components/UserDetails/Userdetailsmain.jsx";
import UserDetails from "../components/UserDetails/UserDetails.jsx";

// ************ ==== >>>> HO Supervisor Dashboard <<<< ==== ************//
import HOSupervisorManagementDashboard from "../pages/hodashboard/HOsDashboard.jsx";

// ************ ==== >>>> Site Manager Dashboard <<<< ==== ************//
import SiteManagementDashboard from "../pages/sitedashboard/SiteDashboard.jsx";

// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AdminDashboardd from "../pages/admindashboard/AdminDashboard";

import {
  CompletePage,
  PersonaldetailsProfile,
} from "../components/PersonalDetailsEdit/PersonaldetailsProfile.jsx";

import SiteManagement from "../components/SiteManagement/SiteManagement.jsx";

import {
  ProjectAllDetails,
  SiteProjectBillDetails,
} from "../components/SiteManagement/SiteProjectManagement.jsx";

// ************ ==== >>>> Site Dashboard <<<< ==== ************//

import LetterTracking from "../pages/sitedashboard/Main/LetterTracking.jsx";
// import ExpenseTracking from "../pages/sitedashboard/Main/ExpenseTracking.jsx";
import Wfm from "../components/Wfm/Wfm.jsx";
import { SiteProjectTable } from "../components/SiteManagement/SiteTables.jsx";
import Invoiceformate from "../components/SiteManagement/AdminSiteManagement/Invoiceformate.jsx";
import CertficateAccess from "../components/SiteManagement/CertificateAccess.jsx";
import VehicleLogBook from "../components/SiteManagement/AdminSiteManagement/VehicleLog.jsx";

// import InvoiceTable, { ProfessionalStaff } from "../components/SiteManagement/AdminSiteManagement/ConsultantInvoicetable.jsx";
import {
  ConsultancyInvoice,
  PrintPayment,
} from "../components/SiteManagement/AdminSiteManagement/ConsultancyInvoice.jsx";

import Boqinvoices from "../pages/sitedashboard/Main/Boqinvoices.jsx";
import SIteUserDetails from "../components/SiteManagement/AdminSiteManagement/UserDetails.jsx";
import BulkAttendanceUpload from "../pages/sitedashboard/Main/Attendance.jsx";
import ManagePayroll from "../components/PayrollManagement/ManagePayroll.jsx";
import Expenses from "../components/ImprestManagement/ExpenseTracking.jsx";
import {
  ImprestRequest,
  HORequestsManagementTab,
  AdminRequestsManagementTab,
  AccountsImprestExpenseRequestMangement,
} from "../components/ImprestManagement/ImprestExpenseRequestManagement.jsx";
import { VehicleManagement } from "../components/SiteManagement/VehicleManagement/VehicleManagementComponenets.jsx";
import { InventoryManagementComponent } from "../components/SiteManagement/InventoryManagement/InventoryManagementComponent.jsx";
import BdDashboard from "../pages/bddashboard/BdDashboard.jsx";
import AccountDashboard from "../pages/accountdashboard/AccountDashboard.jsx";
import HrDashboard from "../pages/hrdashboard/HrDashboard.jsx";
import BdProject from "../pages/bddashboard/BdProject.jsx";
import { components } from "react-select";
import AdminDashboardDetails from "../pages/admindashboard/AdminDashboardDetails.jsx";

import ImprestExpenseManagement from "../components/ImprestManagement/ImprestExpenseManagement.jsx";
import ExpenseSlipByExpense from "../components/ImprestManagement/ExpenseSheet/ExpenseSlipByExpense.jsx";
import ExpenseSlipByRequest from "../components/ImprestManagement/ExpenseSheet/ExpenseSlipByRequest.jsx";
import AuthorityManagementDashboard from "../pages/Authority/AuthorityDashboardd.jsx";
import Dataentrydashboard from "../pages/dataentryoperator/Dataentrydashboard.jsx";
import CertificationDashboard from "../pages/certificationsportal/CertificationDashboard.jsx";

export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },
      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },
      {
        path: "Dashboardnavbar",
        component: <Dashboardnavbar />,
      },
      {
        path: "processComplete/",
        component: <CompletePage />,
      },
      {
        path: "Invoiceformate/:id/",
        component: <Invoiceformate />,
      },
      {
        path: "Vechiclelogbook/",
        component: <VehicleLogBook />,
      },
      {
        path: "Vechiclelogbook/:id/",
        component: <VehicleLogBook />,
      },
      // {
      //   path: "ConsultantInvoiceTable",
      //   component: <ConsultantInvoiceTable />,
      // },
      // {
      //   path: "ConsultancyInvoice",
      //   component: <ConsultancyInvoice />,
      // },
      {
        path: "ConsultancyInvoice/:p_id/:invoice_id/",
        component: <ConsultancyInvoice />,
      },
      {
        path: "attendanceupload",
        component: <BulkAttendanceUpload />,
      },
    ],
  },
  {
    role: "",
    isProtected: false,
    defaultRoute: "jd/jdmain",
    nestedRoutes: [
      {
        routes: [
          {
            path: "userdetails",
            component: <Userdetailsmain />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
        ],
      },
    ],
  },

  {
    role: "SiteManagement",
    isProtected: true,
    defaultRoute: "site/projectdetais",
    nestedRoutes: [
      {
        routes: [
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "siteManagement",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },
          {
            path: "projectdetais",
            // component: <ProjectDetails />
            component: <ProjectAllDetails />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "expenseTracking",
            component: <Expenses />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          // {
          //   path: "inventorymanagement",
          //   component: <InventoryManagementComponent />
          // },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
        ],
        path: "site",
        component: <SiteManagementDashboard />,
      },
    ],
  },

  {
    role: "Admin",
    isProtected: true,
    // defaultRoute: "admin/adminDashboard",
    defaultRoute: "admin/dashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "PrintInvoice/:p_id/:invoice_id/",
            component: <PrintPayment />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "admin",
        component: <AdminDashboardd />,
      },
    ],
  },

  {
    role: "SuperAdmin",
    isProtected: true,
    // defaultRoute: "super/superadminDashboard",
    defaultRoute: "super/siteprojects",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "superadmin",
        component: <AdminDashboardd />,
      },
    ],
  },

  {
    role: "HOsManagement",
    isProtected: true,
    defaultRoute: "hos/userdetail",
    nestedRoutes: [
      {
        routes: [
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "siteManagement",
            component: <SiteManagement />,
          },

          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },

          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "hos",
        component: <HOSupervisorManagementDashboard />,
      },
    ],
  },

  {
    role: "Hr",
    isProtected: true,
    defaultRoute: "hr/",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "hr",
        component: <HrDashboard />,
      },
    ],
  },
  {
    role: "BD",
    isProtected: true,
    defaultRoute: "bd/bdproject",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "bd",
        component: <BdDashboard />,
      },
    ],
  },
  {
    role: "Account",
    isProtected: true,
    // defaultRoute: "account/",
    defaultRoute: "account/",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "accountRequestManagement",
            component: <AccountsImprestExpenseRequestMangement />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "account",
        component: <AccountDashboard />,
      },
    ],
  },

  {
    role: "AuthorityEngineer",
    isProtected: true,
    defaultRoute: "authority/siteprojects",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "authority",
        component: <AuthorityManagementDashboard />,
      },
    ],
  },

  {
    role: "CertificationAccess",
    isProtected: true,
    defaultRoute: "certificaionaccess/",
    nestedRoutes: [
      {
        routes: [
          {
            path: "certificationaccess",
            component: <CertficateAccess />,
          },
        ],
        path: "certificaionaccess",
        component: <CertificationDashboard />,
      },
    ],
  },
  {
    role: "DataEntryOperator",
    isProtected: true,
    defaultRoute: "operator/siteprojects",
    nestedRoutes: [
      {
        routes: [
          {
            path: "dashboard",
            component: <AdminDashboardDetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "circular",
            component: <SiteManagement />,
          },
          {
            path: "wfm",
            component: <Wfm />,
          },
          {
            path: "letterTracking",
            component: <LetterTracking />,
          },
          {
            path: "vehiclemanagement",
            component: <VehicleManagement />,
          },
          {
            path: "payrollmanagement",
            component: <ManagePayroll />,
          },

          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "inventorymanagement",
            component: <InventoryManagementComponent />,
          },
          {
            path: "ProjectAllDetails",
            component: <ProjectAllDetails />,
          },

          {
            path: "SiteProjectBillDetails",
            component: <SiteProjectBillDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonaldetailsProfile />,
          },
          {
            path: "siteprojects",
            component: <SiteProjectTable />,
          },
          {
            path: "Invoiceformate/:id/",
            component: <Invoiceformate />,
          },
          {
            path: "boqInvoice",
            component: <Boqinvoices />,
          },
          {
            path: "ConsultancyInvoice",
            component: <ConsultancyInvoice />,
          },
          {
            path: "ConsultancyInvoice/:p_id/:invoice_id/",
            component: <ConsultancyInvoice />,
          },
          {
            path: "Vechiclelogbook/:id/",
            component: <VehicleLogBook />,
          },
          {
            path: "Siteemployeedetail/:emp_code/",
            component: <SIteUserDetails />,
          },

          {
            path: "teamRequestManagement",
            component: <HORequestsManagementTab />,
          },
          {
            path: "adminRequestManagement",
            component: <AdminRequestsManagementTab />,
          },
          {
            path: "imprestandexpenserequest",
            component: <ImprestRequest />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "operator",
        component: <Dataentrydashboard />,
      },
    ],
  },
];
