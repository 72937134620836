import React, { useState, useEffect, useRef } from "react";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong } from "../Date";
import {
  AddCircular,
  ApproveCircular,
  DeleteCircular,
  RejectCircular
} from "./ProjectDetailsCrudButtons";


import { handleErrorToast } from "../CustomFunctions";


import { toTitleCase } from '../FormdataComponent'
import usePermission from "../../config/permissions";

const SiteManagement = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic


  const [toggleCompany, setToggleCompany] = useState("null");
  const [sectorData, setSectorData] = useState([]);


  const { PERMISSION_AUTHORITY, siteRole } = usePermission();

  const getSiteCirculars = async () => {
    setBuffering(true); // Start Buffering
    let url;
    try {
      // Set URL based on role
      url = sessionStorage.getItem('sitemanagement_role') === "Admin"
        ? `${BASE_URL}/siteproject/circularuploadbyadminaprroval/${toggleCompany}/`
        : `${BASE_URL}/siteproject/circularupload/`;

      // Make API request
      const sub = await axios.get(url);

      // Update state with the fetched data
      setSectorData(sub.data);
    } catch (err) {
      // Handle different error scenarios here if needed
      handleErrorToast(err); // Toast Logic
    } finally {
      setBuffering(false); // End Buffering
    }
  };



  useEffect(() => {
    getSiteCirculars();
  }, [toggleCompany]);






  return (
    <>
      <Dashboardnavbar name="Circular Table" url="Site Managment" />

      <div className="content-tabs">
        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">
              Circular
            </div>
            <div className="field-cont">


              {sessionStorage.getItem('sitemanagement_role') === "Admin" ? (
                <div className="table-searchh dropdown-container field-cont-div">
                  <select
                    onChange={(e) => setToggleCompany(e.target.value)}
                    value={toggleCompany}
                    className="dropdown font-size-subheading  "
                  >
                    <option value="null">All</option>
                    <option value="pending">Pending For Approval</option>
                    <option value="approved">Approved Circular</option>
                    <option value="rejected">Rejected Circular</option>




                  </select>
                </div>
              ) : null}


              <AddCircular
                getSiteCirculars={getSiteCirculars}
                sectorList={sectorData}
              />
            </div>
          </div>
          {/* <SectorsDetailForm /> */}
          <div className="table-box height-73vh">
            <table className="table-css">
              <thead className="table-heading">
                <tr className="custom-table-head-tr ">
                  <th className="align-left">ID</th>
                  <th className="align-center">Title</th>
                  <th className="align-center">Publish Date</th>
                  <th className="align-center">Description</th>

                  <th className="align-center">Attachment</th>
                  {/* <th className="align-center">Created By</th> */}
                  {/* <th className="align-center">Created At</th> */}
                  {sessionStorage.getItem('sitemanagement_role') === "Admin" ? <> <th className="align-center width-5vw">Action</th> <th className="align-right width-5vw">Delete</th> </> : ""}
                </tr>
              </thead>
              {buffer ? (
                <div className="spinner"></div>
              ) : (
                <tbody>
                  {sectorData
                    // ?.filter((e) =>
                    //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                    // )
                    // .sort((a, b) =>
                    //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                    // )
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            {i.title}
                          </td>

                          <td className="align-center form-text-trasformation-uppercase">
                            {i.publish_date ? formattedDateLong(i.publish_date) : "-"}
                          </td>

                          <td className="align-center ">{i.description}</td>
                          <td className="align-center ">
                            <a href={i.file} target="blank">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                class="bi bi-file-pdf"
                                viewBox="0 0 16 16"
                                color="#FF747C"
                              >
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                              </svg>
                            </a>
                          </td>


                          {sessionStorage.getItem('sitemanagement_role') === "Admin" ?
                            <>
                              <td style={{ display: 'flex' }}>

                                {i.admin_approval_status === "approved" ? "" : <ApproveCircular i={i} getCircular={getSiteCirculars} />}

                                {i.admin_approval_status === "rejected" ? "" : <RejectCircular i={i} getCircular={getSiteCirculars} />}
                              </td><td className="align-right">
                                <DeleteCircular i={i} getCircular={getSiteCirculars} />
                              </td> </> : ""}


                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              )}
            </table>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>



      </div>
    </>
  );
};





export default SiteManagement;
